
.accordiankey {
    display: flex;
    justify-content: space-between; 
    gap: 7em;
    align-items: center;
  }
  
.program-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5em;

    .card-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* border: 1px solid #000000; */
        border-radius: 12px;
        background-color: #FBF7F2;
        box-shadow: 0px 4px 4px 0px #00000040;

        border: 1px solid #3636361A !important;

        /* background-color: white; */

        .section-1 {
            display: flex;
            flex-direction: column;
            /* gap: 0.4em; */
            .type{
                font-size: 13px;
                font-weight: 600;
                color: #363636;
                margin-top: 10px;
            }
            .card-header-block-1 {
                /* background-color: #000000; */
                background-color: #FBF7F2;
                /* box-shadow: 0px 4px 4px 0px #00000040; */
                /* border-radius: 12px; */
                padding: 20px 10px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                position: relative;
                min-height: 100px;
        border: 1px solid #3636361A !important;

                border: 1px solid #3636361A;

                max-height: 140px;

                .card-rank{
                    position: absolute;
                    top: 2px;
                    right: 8px;

                    .rank-text{
                        font-size: 20px;
                        font-weight: 700;
                        color: #FFFFFF;
                    }
                }

                .card-header{
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    margin-top: -10.5px;

                    /* padding-top: 12px; */

                    .university-icon {

                        img{
                            border-radius: 12%;
                            height: 50px;
                            width: 110px;
                            box-shadow: 0px 2px 2px 0px #36363640;
                        }
                    }
    
    
                    .university-detail {
                        display: flex;
                        flex-direction: column;
                        /* padding-top: 12px; */
    
                        .university-name {
                            font-size: 18px;
                            font-weight: 600;
                            /* color: #FFFFFF; */
                            color: #E4151B;

                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
    
                        .university-address{
                            font-size: 14px;
                            font-weight: 400;
                            /* color: #FFFFFF; */
                            color: #363636;

                        }
                    }
                }

            }

            .card-header-block-2{
                /* background-color: #FFFFFF; */
                background-color: #FBF7F2; 

                display: flex;
                flex-direction: column;
                padding: 0 10px;
                .type-label {
                    font-size: 13px;
                    font-weight: 600;
                    color: #3C3C3C;
                }
                
                .type-value {
                    font-size: 13px;
                    font-weight: 400;
                    color: #3C3C3C;
                }
                .program-name{
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: 10px;
                    color: #3C3C3C;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .program-detail-text{
                    font-size: 12px;
                    margin-top: 15px;
                    /* font-weight: 400; */
                    color: #363636;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .section-2{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            background-color: #FBF7F2;
            padding: 10px 10px 10px 10px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .scholarship-label {
                font-size: 14px;
                font-weight: 600;
                color: #3C3C3C;
            }
            
            .scholarship-amount {
                font-size: 12px;
                font-weight: 400;
                color: #3C3C3C;
            }
            .program-detail-section-1{
                display: flex;
                justify-content: space-between;
                text-align: start;
                flex-direction: column;
                gap: 1.3em;

                .intake-header{
                    font-size: 12px;
                    font-weight: 700;
                    /* color: #000000; */
                    color:#363636;
                    
                }
                .know-more{
                    color: #E4151B;
                    font-size: 13px;
                    font-weight: 600;
                    cursor: pointer;
                    margin-top: 15px;
                    margin-bottom: 20px;
                }
                

                .detail{
                    font-size: 12px;
                    font-weight: 400;
                    color: #363636;
                }
                .detail_UI{
                    font-size: 12px;
                    font-weight: 700;
                    color: #363636
                }
            }

            .program-detail-section-2{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                text-align: end;
                gap: 0.3em;

                .header{
                    font-size: 12px;
                    font-weight: 700;
                    color: #3C3C3C;
                }

                .detail{
                    font-size: 12px;
                    font-weight: 400;
                    color: #3C3C3C;
                }
            }
        }
    }
}
.NameBudge {
    display: flex;
    width: fit-content;
    align-items: center;
    background-color: #E4151B;
    height: 48px;
    border-radius: 12px;
    padding: 5px;
    border: 0;
    margin-bottom: 10px;
    gap: 10px;
    font-size: 20px;
    padding: 10px;
    color: #FFF;
    font-weight: 700;
}
/* .NOdata{
    font-size: 30px;
    font-weight: 700;
    margin-top: 150px;
    margin-left: 350px;
} */
.NOdata{
    display: flex;
    flex-direction: column;
  margin-top: 80px;

    /* margin-right: 18%; */
    justify-content: center;
    align-items: center;
  }
  .noimg{
      width: 100px;
      height: 100px;
  }
  .no{
      font-size: 30px;
      font-weight: 700;
      color: #E4151B;
      /* margin-top: 150px; */
      /* margin-left: 350px; */
  }
  .moment{
      font-size: 30px;
      font-weight: 700;
      color: #363636;
      /* margin-top: 150px; */
      /* margin-left: 350px; */
  }
  .line{
      border: 2px solid #213c9233;
      width: 90%;
      margin-top: 39px;
      margin-bottom: 40px;
  }


@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .program-cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .program-cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 767px) {
    .program-cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.comman-filters{
    span{
        font-size: 14px;
        font-weight: 600;
    }
}