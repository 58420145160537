.notifications-container {
  border-radius: 12px;
  /* padding: 16px; */
  width: 400px;
  background-color: #fff !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #ffffff;
  /* border: 2px solid #E4151B; */
  border: 1px solid #E4151B;


  .notifications-header-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .notifications-title {
    font-size: 24px;
    color: #E4151B;
    font-weight: 700;
  }

  .notifications-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 390px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .notification-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #ffffff;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    border-radius: 12px;
    padding: 0px 10px;
  }

  .notification-card_content {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .notification-card_coverImage {
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }

  .notification-card_info {
    .notification-message {
      font-size: 16px;
      font-weight: 400;
      color: rgba(60, 60, 60, 1);
      margin-bottom: 5px;
    }
    .notification-time {
      font-size: 12px;
      font-weight: 400;
      color: rgba(60, 60, 60, 1);
    }
  }
}

.notifications-tabs {
  display: flex;
  margin-bottom: 10px;
  gap: 1em;
}

.tab1 {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  border-radius: 25px;
  background-color: #E4151B;
  padding: 10px 40px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  border: none;
}

.tab {
  font-weight: 600;
  width: 30%;
  font-size: 18px;
  background-color: #fff;
  border-radius: 25px;
  color: #E4151B;
  padding: 5px 20px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  /* border: 2px solid #E4151B; */
  border: 1px solid #E4151B;

}

.active {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #E4151B;
  color: #fff;
}

.notification-status {
  span {
    font-size: 14px;
    font-weight: 600;
    color: #E4151B;
  }
}
