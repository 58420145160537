.commonSection {
  background: #fbf7f2;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px;
  padding: 10px 20px 0px 20px !important;
  height: max-content;
  margin-top: 25px;
  /* width: 70%; */

  .container {
    display: grid;
    /* margin: 10px; */
    grid-template-columns: repeat(1, 1fr);
  }

  .displayMange {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .firstDisplay {
    font-size: 16px;
    font-weight: 300;
    color: #E4151B;
  }

  .sorting-drop-down-block{
    display: flex;
    align-items: center;
    gap: 10px;

    .sorting-text{
      font-size: 14px;
      font-weight: 500;
    }

    .drop-down-toggle{
      background-color: #FF7C8480;
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      font-size: 14px;
      font-weight: 600;
      width: 155px;
    }

    
    .drop-down-toggle::after {
      display: none !important;

    }

    .drp-down-items{
      font-size: 14px;
      font-weight: 600;
    }
  }

  .containerForConnection {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1em;
  }

  @media (max-width: 768px) {
    .containerForConnection {
      grid-template-columns: repeat(3, 1fr);
    }

    .displayMange {
      /* display: block; */
      margin-bottom: 10px;
    }
  }

  @media (max-width: 480px) {
    .containerForConnection {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.chatContainer {
  position: fixed;
  bottom: 15px;
  width: 100%;
  right: 0;
}

.sortByText {
  font-size: 16px;
  font-weight: 400;
}

.recentlyAdded {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.profilePicBottom {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px 0px 0px 5px; 
  box-shadow: 0px 6.02px 6.02px 0px rgba(0, 0, 0, 0.25);
}
.forChatScreen{
  display: flex;
    /* justify-content: center; */
    align-items: flex-end;
    justify-content: end;

}
.view-more{
  border: 1px solid #E4151B;
  background: #E4151B;
  color: #ffffff;
  border-radius: 12px;
  padding: 7px 15px;
  font-size: 18px;
}
@media (max-width: 768px){
  .commonSection{
    width: 100% !important;
  }
}