.program-info-container{
    /* box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    background: #FAF5EFCC;
    border: 1px solid #3636361A;
    border-radius: 15px;
    margin-top: 20px; */

    
}

.option-default{
    display: none;
}
.backprogram-number{
    font-size: 20px;
    font-weight: 600;
    color: #363636;
    margin-top: 10px;

}
.clearIcon{
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-left: 20px;
    margin-top: 10px;
}

.extrik{
    color: #E4151B;
}
.errorText{
    font-weight: 400;
    font-size: 12px;
    color: #E4151B;
}

.cancel-btn{
    border: none;
    background: transparent;
    color: #000;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;

  }
  .cancel-btn:hover{
    color: #E4151B;
  }