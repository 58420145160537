.accordiankey {
    display: flex;
    justify-content: space-between; 
    gap: 7em;/* Space between span and img */
    align-items: center; /* Center align items vertically if needed */
  }
.card-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid #000000; */
    border: 1px solid #3636361A;
    background-color: #FBF7F2;
    box-shadow: 0px 4px 4px 0px #00000040;


    border-radius: 12px;
    margin-bottom: 10px;

    .section-1 {
        display: flex;
        flex-direction: column;
        /* gap: 0.4em; */

        .card-header-block-1 {
            /* background-color: #000000; */
            /* box-shadow: 0px 4px 4px 0px #00000040; */
            /* padding: 20px 10px; */
            /* border-radius: 12px; */
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            position: relative;
        border: 1px solid #3636361A !important;
        min-height: 75px;

            color: #ffffff;
            /* background-color: #000000; */
            background-color: #FBF7F2;

            /* height: 140px; */
            /* max-height: 140px; */

            .card-rank{
                position: absolute;
                top: 2px;
                right: 8px;

                .rank-text{
                    font-size: 20px;
                    font-weight: 700;
                }
            }
            object-fit: cover;

          .card-header{
            display: flex ;
            align-items: center;
            gap: 0.5em;
            /* padding-top: 12px; */
            /* margin-top: -10.5px; */
            

                                
            

            .university-icon {
                height: 200px;
                /* border-radius: 12px; */
                width: 100%;
                align-self: baseline;
                background-color: #ffffff;
                overflow: hidden;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;

                img{
                    /* border-radius: 100%; */
                height: 100%;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                overflow: hidden;
                width: 100%;
                /* box-shadow: 0px 2px 2px 0px #36363640; */

                }
            }
                .university-detail {
                    display: flex;
                    flex-direction: column;
                    /* padding-top: 12px; */

                    .university-name {
                        font-size: 15px;
                        font-weight: 600;
                        color: #E4151B;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }

                    /* .university-address{
                        font-size: 12px;
                        font-weight: 400;
                        color: #363636;

                    } */
                }
            }

        }

        .card-header-block-2{
            background-color: #FBF7F2; 
            display: flex;
            flex-direction: column;
            padding: 0 10px;
            margin-top: 15px;

            .program-name{
                font-size: 14px;
                font-weight: 600;
                color: #3C3C3C;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 10px;
                text-overflow: ellipsis;
            }
            .university-address{
                font-size: 12px;
                margin-top: 10px;
                font-weight: 400;
                color: #363636;

            }
            .university-name {
                font-size: 18px;
                font-weight: 600;
                color: #363636;

                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
            }

            .program-detail-text{
                font-size: 13px;
                font-weight: 400;
                margin-top: 20px;
                color: #3C3C3C;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .section-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background-color: #FBF7F2;

        padding: 30px 10px 10px 10px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .program-detail-section-1{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            text-align: start;
            gap: 1em;

            .intake-header{
                font-size: 12px;
                font-weight: 700;
                /* color: #000000; */
                color:#363636;

            }
            .institute-label{
                font-size: 13px;
                font-weight: 600;
                color: #363636;
            }
            .institute-amount{
                font-size: 13px;
                font-weight: 400 !important;
                color: #363636;
            }

            .detail{
                font-size: 12px;
                font-weight: 400;
                white-space: nowrap;
                color: #000000;
            }
            .know-more{
                color: #E4151B;
                font-size: 13px;
                font-weight: 600;
                cursor: pointer;
                margin-bottom: 20px;
            }
            .detail_UI{
                font-size: 12px;
                font-weight: 700;
                color: #363636
            }
        }

        .program-detail-section-2{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            text-align: end;
            gap: 0.3em;

            .header{
                font-size: 12px;
                font-weight: 700;
                color: #3C3C3C;
            }

            .detail{
                font-size: 12px;
                font-weight: 400;
                color: #3C3C3C;
            }
        }
    }
}