.forMoreModel{
    position: relative;
    width: 100%;
    z-index: 99;
    .forModelContainer{
      border-radius: 12px;
      box-shadow: -4px 0px 4px 0px #00000040;
      background: #FFFFFF;
      position: absolute;
      right: 0;
      width: 70%;
      z-index: 9;
      padding: 0 20px;
      .forMoreHeader{
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        .croseIcon{
          /* height: 30px;
          width: 30px; */
          text-align: end;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
  
        }
      }
    }
    .forModelContainer1{
      border-radius: 12px;
      box-shadow: -4px 0px 4px 0px #00000040;
      background: #FFFFFF;
      position: absolute;
      right: 0;
      width: 70%;
      z-index: 9;
      padding: 0 20px;
      bottom: -120px;
      .forMoreHeader{
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        .croseIcon{
          height: 20px;
          width: 20px;
          text-align: end;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
  
        }
      }
    }
  }
  .radio-btn{
    cursor: pointer;
  }
  .modal-text{
font-size: 16px;
font-weight: 400;
color: #3C3C3C;

  }

  label{
font-size: 16px !important;
font-weight: 600 !important;
color: #3C3C3C;
display: -webkit-box !important;
-webkit-line-clamp: 1 !important;
-webkit-box-orient: vertical !important;
overflow: hidden !important;
text-overflow: ellipsis !important;
  }
