.btn-position{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.modal-custom {
  width: 80% !important; /* Set your desired fixed width */
  max-width: 80%;
}
.modal-body-data{
    max-height: 450px;
    overflow-y: auto;
    /* height: 450px; */
    top: 0;
}
.cross-icon-position{
    text-align: end;
    margin-bottom: 10px;
    img{
        height: 25px;
        width: 25px;
        cursor: pointer;
    }
}
.post-image {
    margin: 20px 0;
    .image-card {
      display: grid;
      grid-template-columns: repeat(3, 1fr); 
      gap: 10px;
    }
    
    .image {
      width: 100%;
      /* height: auto; */
      height: auto;
      /* border: 1px saddlebrown; */
      border: 2px solid #ccc;
    aspect-ratio: 16 / 9;
      /* border-radius: 5px; */
      /* padding: 3px; */
      /* border: 1px springgreen; */
      /* border: 2px solid black; */
      /* box-shadow: 0px 4px 4px 0px #00000040; */
    }
    
  }
  