.footer {
  background-color: #E8252A;
  padding: 20px 0; 
  text-align: center;
  color: white;
  /* font-family: Poppins; */
  font-family: 'Poppins';
  width: 100%;
  position: fixed;
  bottom: 0;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      /* margin: 0; */
      font-size: 16px;
    }
  }
}
