.main-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  .row_hieght {
    height: 100%;
    .col_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2em;
      /* background: #E4151B; */
      background: #F9F6EF;
      padding: 0 5% 0 5%;
      /* .left-img {
        width: 100%;
      } */

      .left-block-header{
        display: flex;
        flex-direction: column;
        gap: 1em;

        .heading{
          font-size: 36px;
          font-weight: 600;
          color: #e8252a;
        }

        .sub-heading{
          font-size: 18px;
          font-weight: 500;
          color: #565656;
        }

        .description{
          font-size: 15px;
          font-weight: 400;
          color: #565656;
        }
      }

      .left-block-cards{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1em;
        padding: 0 12%;

        .cards{
          padding: 10px;
          border: 1px solid;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1em;

          .card-detail-text{
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .right_col{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F9F6EF !important;
    }
  }

  .white-back{
    background: #FFFFFF !important;
  }

  .sign_up-box {
    /* max-width: 400px; */
    display: flex;
    flex-direction: column;
    gap: 3em;
    /* margin-top: 50px; */
    /* padding: 0 18%;
    */
    width: 100%; 
    /* height: 100%;
    justify-content: space-between;
    padding: 30px 0px; */
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(242, 242, 242, 0.95) 100%);
    box-shadow: 0px 10px 20px 0px rgba(131, 131, 131, 0.21960784);
    border-radius: 10px;
    padding: 20px 30px;
    margin: 20px;

    .header {
      align-self: center;
      font-size: 32px;
      font-weight: 700;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }

    .form-section {
      display: flex;
      flex-direction: column;
      gap: 1em;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(242, 242, 242, 0.95) 100%);
      box-shadow: 0px 10px 20px 0px rgba(131, 131, 131, 0.21960784);
      border-radius: 10px;
      padding: 20px 30px; */

      .label {
        font-size: 14px;
        font-weight: 700 !important;
        margin: 0;
      }

      .form-field {
        display: flex;
        column-gap: 0.5em;
      }

      .form-content {
        display: flex !important;
        flex-direction: column;
        gap: 1.5em;
      }
      .dropDownIconContainer{
        position: relative;
      }
      .DropDownIconGender{  
        position: absolute;
        bottom: 10px;
        right: 2px;
        cursor: pointer;
      }
      .DropDownCountryCode{  
          align-items: center;
          background-color: white;
          border-bottom: 2px solid #ccc;
          .dropDownSection{
            display:flex;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;       /* Prevent text from wrapping */
            overflow: hidden;   
            position: relative;
            align-items: center; 
          }
          /* .DropDownIcon{
            position: relative;
            img{
              position: absolute;
              bottom: 3px;
              left: -20px;
            } */
          
          input{
            border: none;
          }
          select{
            border: none;
          }
        }
        .imgIConSize{
          height: 30px;
          width: 30px;
        }
        .icon{
          width: 12px;
          height: 12px;
        }
        .iconcode{
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }

    }

    .form-section .submit-btn {
      width: 100%;
      border: 1px solid rgba(0, 90, 190, 1);
      color: #fff;
      border-radius: 5px;
      padding: 7px;
      font-size: 14px;
      font-weight: 600;
      background: rgba(0, 90, 190, 1);
    }

    .signin-section {
      align-self: center;
      font-size: 15px;
      font-weight: 400;
      color: black;

      a {
        text-decoration: none !important;
      }

      .signin-text {
        font-size: 15px !important;
        font-weight: 700 !important;
        color: rgba(0, 90, 190, 1) !important;
      }
    }

    .google-signin-section {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .google-signin-section-text {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 12px;

        hr {
          width: 32%;
        }
      }

      .google-signin-section-btn {
        .google-btn {
          width: 100%;
          border: 1px solid rgba(0, 90, 190, 1);
          color: #fff;
          border-radius: 5px;
          padding: 7px;
          font-size: 14px;
          font-weight: 600;
          background: rgba(0, 90, 190, 1);

          .icon_G {
            background: conic-gradient(
                from -45deg,
                #ea4335 110deg,
                #4285f4 90deg 180deg,
                #34a853 180deg 270deg,
                #fbbc05 270deg
              )
              73% 55%/150% 150% no-repeat;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .terms-and-policy {
      font-size: 14px;
      text-align: center;
      padding: 0 30px;

      /* a{
                color: black !important;
            } */
    }

    .input-with-icon {
      position: relative;

      .input-with-icon input {
        padding-right: 30px;
      }

      .icon-container {
        position: absolute;
        font-size: 17px;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        cursor: pointer;

        .eyeicon {
          font-size: 15px;
        }
      }
    }
    .otp_section{
      text-align: center;
   
      .otp_input{
display: flex;
justify-content: center;
      }
      .timer{
font-size: 16px;
font-weight: 700;
color: #363636;
margin-top: 30px;

      }
      .change_auth{
      font-size: 16px;
      font-weight: 400;
      color: #363636;
      margin-top: 20px;
      cursor: pointer;
      text-decoration: underline;
      }
      
    }
  }
}

.submit-btn:disabled {
  background-color: rgba(39, 39, 42, 0.9);
  color: #fff;
}


.form-group{
  margin-bottom: 15px;
}


.button_common {
  background-color: olivedrab;
  color: white;
  padding: 15px 30px;
  border: none;
  font-size: 22px;
  border-radius: 15px;
  margin: 1rem;
  width: 90%;
}
.selectCountryCodeInput{
  padding:10px
}
.dropdownMenu {
  z-index: 9999;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  position: absolute;
  background-color: white;
  width: 100%;
  max-height: 200px; /* Limit height for scroll */
  overflow-y: auto;
}
.dropdownItem {
  padding: 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}
.error {
  color: #e8252a;
  text-align: left;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0px;
}

.password-validation {
  margin-top: 5px;
  .validation-heading, span{
  font-weight: 600;
  font-size: 12px;
}

}

.authorization-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .authorization-content {

    .authorization-option {
        display: flex;
        align-items: center;
        gap: 0.5em;
        margin-bottom: 10px;

        .radioInput{
          margin: 0;
          padding: 0;
        }

        input[type="radio"] {
          height: 22px;
          width: 22px;
          margin-right: 5px;
          accent-color: #213c92;
      }
      }
      
      .authorization-option label {
          display: grid;
          gap: 5px;
          grid-template-columns: auto 1fr;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
      }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  .verify-message{
    text-align: center;
    font-weight: 500;
    margin-top: 30px;
  }


}


.primary_button{
  width: 100%;
}
.secondary_button{
  width: 100%;
  padding: 5px 15px;
}
.heading{
  font-size: 20px;
  font-weight: 700;
  color: #363636;
  margin-top: 30px;
}

.radioInput:checked {
  background-color: #213c92 !important;
  border-color: #213c92 !important;
}
.otp_Input{
  height: 100px !important;
}

.back-btn{
  position: absolute;
  top: 70px;
  cursor: pointer;

  .back-arrow-icon{
    height: 20px;
  }
}

@media screen and (max-width:767px) {
  .col_img{
    display: none !important;
  }
  
}
@media screen and (min-width:768px) and (max-width:992px) {
  .col_img{
    width: 51% !important;
  }
  .right_col{
    width: 49% !important;
  }
  
}
/* Wrapper for the select dropdown */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Custom select element styling */
.select-wrapper select {
  width: 100%; /* Full width */
  padding: 10px; /* Padding for better click area */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  background-color: white; /* Background color */
  appearance: auto; /* Use the default arrow icon */
}

/* Optional: add some hover and focus effects */
.select-wrapper select:hover,
.select-wrapper select:focus {
  border-color: #007bff; /* Change border color on hover/focus */
  outline: none; /* Remove outline */
}
.dropdownIcon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;  /* Left triangle border */
  border-right: 5px solid transparent; /* Right triangle border */
  border-top: 5px solid #333;       
  margin-left: 8px;                    /* Adjust spacing as needed */
  display: inline-block;                /* Allow inline layout */
}
.custom-select {
  position: relative;         /* Positioning context for the pseudo-element */
  padding-right: 30px;      /* Space for the arrow */
  appearance: none;          /* Remove default arrow */
  background-color: white;   /* Background color */
  border: 1px solid #ced4da; /* Border styling */
}

/* Create the solid arrow */
.custom-select::after {
  content: '';                             /* Empty content for the arrow */
  position: absolute;                      /* Position absolutely */
  top: 50%;                               /* Center vertically */
  right: 10px;                            /* Position arrow */
  width: 0;                               /* Width of the arrow */
  height: 0;                              /* Height of the arrow */
  border-left: 5px solid transparent;    /* Left side of the arrow */
  border-right: 5px solid transparent;   /* Right side of the arrow */
  border-top: 5px solid #333;            /* Solid color for the arrow */
  transform: translateY(-50%);           /* Center arrow vertically */
}

/* Optional: Change border color on focus */
.custom-select:focus {
  border-color: #007bff; 
  outline: none;                          /* Remove outline */
}

.country-code-dropdown{
  border-bottom: 2px solid #ccc;
  min-height: 46px;
  flex-basis: 40% !important;
}

.contact-number{
  flex-basis: 60% !important;
}

.logo-align{
  background: #F9F6EF;
  padding: 20px 10% 0;
}