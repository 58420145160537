
  .ModelContainer{
    padding: 20px;
    .headerContainer {
    /* border-bottom: 0 !important; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #36363633;
    margin-bottom: 20px;
    .corssIcon {
        height: 20px;
        cursor: pointer;
    }
  }

}
.div-heigth{
  max-height: 500px;
  overflow-y: auto;
}

.required{
    color: red;
}

.header{
    font-size: 20px;
    font-weight: 600;
    color: #E4151B;
}