.cross-icon-position{
    text-align: right;
    cursor: pointer;
    img{
        /* width: 30px;
        height: 30px; */
    }
}
.btn-position{
    text-align: center;
    margin-top: 20px;
}
.modal-body-data{
    text-align: center;
    p{
        font-size: 20px;
        color: #363636;
        font-weight: 600;
    }
}
.cancel-btn{
    font-size: 16px;
    color: #363636;
    font-weight: 600;
    cursor: pointer;
}
.primary-btn{
    border: 1px solid #E4151B;
    background-color: #E4151B;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 15px;
    border-radius: 8px;
}