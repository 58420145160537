.container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5em;
}
.accordiankey {
    display: flex;
    justify-content: space-between; 
    gap: 7em;/* Space between span and img */
    align-items: center; /* Center align items vertically if needed */
  }
.searchInput {
    border-radius: 12px;
    margin: 10px 0px 10px 3px;
    height: 40px;
    width: 100%;
    padding: 0px 3px;
    font-size: 16px;
}
 /* :focus-visible {
    outline: #F7A6A4 auto 0.5px !important;
} */

.ranked {
    font-size: 20px;
    margin: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: white;
}
.NameBudge {
    display: flex;
    width: fit-content;
    align-items: center;
    background-color: #E4151B;
    height: 48px;
    border-radius: 12px;
    padding: 5px;
    margin-right: 5px;
    border: 0;
    margin-bottom: 10px;
    gap: 10px;
    font-size: 20px;
    padding: 10px;
    color: #FFF;
    font-weight: 700;
}

/* .NOdata{
    font-size: 30px;
    font-weight: 700;
    margin-top: 150px;
    margin-left: 350px;
} */
.NOdata{
    display: flex;
    flex-direction: column;
  margin-top: 80px;

    /* margin-right: 18%; */
    justify-content: center;
    align-items: center;
  }
  .noimg{
      width: 100px;
      height: 100px;
  }
  .no{
      font-size: 30px;
      font-weight: 700;
      color: #E4151B;
      /* margin-top: 150px; */
      /* margin-left: 350px; */
  }
  .moment{
      font-size: 30px;
      font-weight: 700;
      color: #363636;
      /* margin-top: 150px; */
      /* margin-left: 350px; */
  }
  .line{
      border: 2px solid #213c9233;
      width: 90%;
      margin-top: 39px;
      margin-bottom: 40px;
  }
.crossIcon {
    cursor: pointer;
}

.searchInput {
    width: 148px;
    margin: 12px;
}



@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}

@media screen and (max-width: 767px) {
    .container {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.comman-filters{
    span{
        font-size: 14px;
        font-weight: 600;
    }
}