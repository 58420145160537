.common-section {
  margin-top: 20px;

  .common-section-css {
    padding: 10px;
    /* border-radius: 12px; */
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    background: #fbf7f2;
    .heading{
      border: 2px solid #36363633;
    }
    .heading-text {
      font-size: 20px;
      font-weight: 700;
      color: #E4151B;
    }

    .heading-align {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .image-size {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      cursor: pointer;
    }

    .coin-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }

    .edit-icon {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      cursor: pointer;
    }

    .over-view-icon {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }

    .languageIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .educationIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }

    .workIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }

    .coin-count {
      font-size: 14px;
      font-weight: 600;
      color: #ffcc4d;
      margin-left: 5px;
    }

    .blank-content-text {
      font-size: 16px;
      font-weight: 700;
      color: #888888;
    }

    .about-section {
      .about-section-text {
        font-size: 16px;
        font-weight: 500;
        color: #363636;
        word-wrap: break-word;
      }
    }

    .star-mark {
      color: #e82529;
      font-size: 16px;
      font-weight: 600;
    }

    .icon-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    .icon-alignment1{
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 2px solid #213C921A;
      margin-bottom: 12px;
    }

    .black_text {
      font-size: 16px;
      /* font-weight: 600; */
      font-weight: 700;

      color: #363636;
    }

    .tagline-part {
      display: flex;

      span {
        white-space: normal;
      }
    }

    .grey-text {
      font-size: 16px;
      font-weight: 400;
      color: #363636;
    }

    .added-content {
      display: flex;
      margin-bottom: 10px;
      gap: 0.5em;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .added-content-text {
      padding: 5px 15px;
      width: fit-content;
      border: 2px solid #213C9233;
      margin-right: 20px;
      background: #ffffff;
      /* box-shadow: 0px 4px 4px 0px #00000040; */
      color: #363636;
      font-size: 16px;
      font-weight: 700;
      border-radius: 12px;
    }

    .corss-icon {
      height: 20px;
      position: absolute;
      top: -5px;
      right: 15px;
      cursor: pointer;
    }
/* 
    .tooltip {
      visibility: hidden;
      font-size: 13px;
      width: 5px;
      color: black;
      text-align: center;
      position: absolute;
      z-index: 1;
      right: 10%;
      top: -20%;
      transform: translateY(-95%);
      opacity: 0;
      transition: opacity 0.3s;
      background: none;
      border: none;
      padding: 0;
    } */



    /* .tooltip-container:hover .tooltip {
      visibility: visible;
      opacity: 1;
    } */

    .div_position {
      position: relative;

    }

    .imageLineHeight {
      width: 100%;
    }

    .flex-container {
      display: flex;
      align-items: center;
    }
  }
}
.modal-container {
  .heading-text-modal{
  font-size: 25px;
  font-weight: 700;
  padding-left: 20px;
  /* color: #213c92; */
      color: #E4151B;

}
.modal-body{
  max-height: 450px;
  overflow-y: auto;
  padding: 20px;
  .forLabel{
    font-weight: 700 !important;
  }
  .errorText{
    font-weight: 400;
    font-size: 12px;
    color: #e82529;
  }
}
}
.modal-footer{
  display: flex !important;
  justify-content: center !important;
}
.update-button{
  cursor: pointer;
}
.custom-form-select {
  /* width: 250px;
  height: 35px;
  padding: 5px; */
  border-radius: 5px; 
  font-size: 14px;
  border: 2px solid #ccc;
  /* margin-left: 10px; */
}

.custom-form-select:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #172A66;
}
.forRemoveBorder:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important;     
}
.forRemoveBorder {
  outline: none !important;
  border: none !important; 
  border-radius: none !important;
  border-bottom: 2px solid #dee2e6 !important; 
  box-shadow: none !important;
}

.errorText{
  font-weight: 400;
  font-size: 12px;
  color: #e82529;
}
.cross-icon{
  cursor: pointer;
}
.divider-line{
    border: 1px solid #ccc;
    height: 45px;
}

.disable-cursor {
  cursor: none;
  pointer-events: none;
}

.country-code-select{
  flex: 1;
}

.mobile-number-input{
  flex: 2;
}
