.heading {
  font-size: 24px;
  font-weight: 600;
  color: #e4151b;
}
.icon-position {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin-right: 10px;
  img {
    cursor: pointer;
  }
}
.delete-text {
  color: #e4151b;
  cursor: pointer;
  border: 1px solid #e4151b;
  background: #fff;
  border-radius: 5px;
}
.delete-text:hover {
  color: #fff;
  cursor: pointer;
  border: 1px solid #e4151b;
  background: #e4151b;
  border-radius: 5px;
}
.icon-hover:hover {
  svg {
    path {
      stroke: red;
    }
  }
}

.icon-hover:active {
  svg {
    path {
      stroke: red;
    }
  }
}
.header-height {
  min-height: 120px;
  max-height: 120px;
}

.img-header {
  display: flex;
  /* align-items: center; */
  gap: 5px;
  padding: 20px 10px;
  min-height: 120px;
    max-height: 120px;
}
.header-img {
  border-radius: 12%;
  height: 50px;
  width: 110px;
  box-shadow: 0px 2px 2px 0px #36363640;
}
.program-card {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #3636361a;
  background-color: #fbf7f2;
  border-radius: 12px;
  margin-bottom: 10px;
  position: relative;
  min-height: 525px;
  max-height: 525px;
}

.inst-name {
  font-size: 18px;
  font-weight: 600;
  color: #e4151b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.inst-country {
  font-size: 14px;
  font-weight: 400;
  color: #363636;
  margin-bottom: 0;
}
.divider-line {
  border: 1px solid #3636361a;
  width: 100%;
}
.duration-name {
  font-size: 14px;
  font-weight: 600;
  color: #e4151b;
  margin-top: 5px;
  margin-bottom: 0;
}
.content-padding {
  padding: 10px;
}
.program-name {
  font-size: 14px;
  font-weight: 600;
  color: #3c3c3c;
  display: -webkit-box;
  padding: 0 !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.program-name:hover {
  color: #e4151b;
}
.content-padding-1 {
  padding: 0 10px;
}
.decription {
  font-size: 12px;
  font-weight: 400;
  color: #363636;
}
.calender-icon {
  margin-right: 7px;
  height: 20px;
  width: 20px;
}
.duration-time {
  font-size: 14px;
  font-weight: 600;
}
.intake-text {
  font-size: 13px;
  font-weight: 600;
  color: #e4151b;
  margin-bottom: 0;
}
.intake-name {
  font-size: 12px;
  font-weight: 400;
  color: #363636;
  white-space: nowrap;
}
.fee-heading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.amount-text {
  color: #363636;
  font-size: 13px;
  font-weight: 400;
}
.currency-name {
  color: rgb(228, 21, 27);
  font-size: 13px;
  font-weight: 400;
}
.parent-button {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  position: absolute;
  right: 50%;
  left: 50%;
  bottom: 0;
}

.know-btn {
  white-space: nowrap;
  padding: 10px 20px;
  height: fit-content;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background: transparent;
  color: #e4151b;
  border: 1px solid #e4151b;
}

.create-btn {
  padding: 10px 20px;
  background-color: #e4151b;
  color: #fff;
  border: none;
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #E4151B;
  font-size: 13px;
  font-weight: 500;
}

.delete-icon{
    position: absolute;
    right: 0;
    top: 7px;
    cursor: pointer;
}
.no-data{
    text-align: center;
    margin-top: 100px;
}
.red-span{
    font-size: 30px;
    font-weight: 700;
    color: #E4151B;
}
.backSpan{
    font-size: 30px;
    font-weight: 700;
    color: #363636;
}
.data-text{
    font-size: 14px;
    font-weight: 500;
    color: #E4151B;  
}

.confirm-text{
    font-size: 14px;
    font-weight: 600;
    color: #363636;
    padding: 10px;
    white-space: nowrap;
    margin-bottom: 0;
}

.yes{
    font-size: 16px;
    font-weight: 700;
    color: #E4151B;  
    background: transparent;
    padding: 0;
    border: none; 
    margin-left: 20px;

}
.cancel{
    font-size: 16px;
    font-weight: 700;
    color: #363636; 
    background: transparent;
    padding: 0;
    border: none; 
}


@media screen and (min-width:992px) and (max-width:1080px) {
    .program-card{
        min-height: 600px;
        max-height: 600px;
    }
    
}

@media screen and (max-width:768px) {
    .program-card{
        min-height: 100%;
        max-height: 100%;
    }
    .parent-button{
        position: unset !important;
    }
    
}


.other-fee{
  color: rgb(228, 21, 27);
  font-size: 13px;
  font-weight: 400;
  cursor: context-menu;
}

.info-icon{
  height: 15px;
    width: 15px;
}

.position-other{
  padding-left: 10px;
  width: fit-content !important;
}

.schoreShip{
  background-color: #FF8136;
    border-radius: 20px;
    font-family: "Poppins";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
}

.applicationWav{
  background-color: #06C295;
  border-radius: 20px;
  font-family: "Poppins";
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
}
.fee-wav{
  color: #FFFFFF;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 2px;
    margin-bottom: 0;
}

.highlight{
  color: red !important;
}