.card {
  display: flex;
  justify-content: space-between;
}

.card_content {
  display: flex;
  justify-content: space-between;
}

.profileImage {
  width: 80px;
  height: 80px;
  box-shadow: 0px 6.02px 6.02px 0px #00000040;
  border-radius: 50%;
  /* cursor: pointer; */
}

.card_info {
  margin: 15px 0px 0px 20px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.name:hover{
 color: #E4151B;
}

.description {
  font-size: 16px;
  font-weight: 400;
  color: #363636;
}

.brokenLine {
  width: 100%;
  /* border: 2px solid rgba(23, 42, 102, 1); */
  border: 1px solid #36363680;
  margin: 10px 0px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.popup {
  position: absolute;
  border-radius: 12px 0px 12px 12px;
  border: 1px solid #36363620;
  right: 10px;
  background: white;
  padding: 5px;
  min-width: 180px;

}
.messageBtn{
  margin-left: 10px;
}
.popupOption{
  cursor: pointer !important;
}
.menu-icon{
  position: relative;
}
.moreBtn{
  cursor: pointer !important;
}
.messageBtn{
  cursor: pointer !important;
}
.containerForTab{
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px;
  padding: 10px 20px 20px 20px !important;
  height: max-content;
  .textSortBy{
      font-weight: 400;
      font-size: 16px;
  }   
  .textRecent{
      font-weight: 600;
      font-size: 16px;
      padding-left: 5px;
  }
  .imgIcon{
      padding-left: 15px;
      cursor: pointer;
  }
}

.sorting-drop-down-block{
  display: flex;
  align-items: center;
  gap: 10px;

  .sorting-text{
    font-size: 14px;
    font-weight: 500;
  }

  .drop-down-toggle{
    background-color: #FF7C8480;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    width: 155px;
  }

  .drop-down-toggle::after {
    display: none !important;

  }

  .drop-down-toggle:hover{
    background-color: #FF7C8480;
    border: none;
    box-shadow: none;
  }

  .drop-down-toggle:active{
    background-color: #FF7C8480;
    border: none;
    box-shadow: none;
  }

  .drp-down-items{
    font-size: 14px;
    font-weight: 600;
  }
}