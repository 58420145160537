.custom-accordion-header::after {
    display: none !important;
  }
  .dropdownIcon {
    width: 20px; /* Adjust size as needed */
    height: 20px;
}
.accordiankey {
    display: flex;
    justify-content: space-between; 
    /* gap: 7em; */
    align-items: center;
  }
  .newh,
    .newimg {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

.compare-program-section{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    padding: 16px;

    .cp-checkbox{
        display: flex;
    }

    .cp-selected-text{
        text-align: center;
        white-space: nowrap;
    }

    .cp-buttons{
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 1em;
    }
}

.program-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5em;

    .card-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* border: 1px solid #000000; */
        box-shadow: 0px 4px 4px 0px #00000040;

        border: 1px solid #3636361A;
        background-color: #FBF7F2;
        border-radius: 12px;
      
        .section-1 {
            display: flex;
            flex-direction: column;
            /* gap: 0.4em; */

            .card-header-block-1 {
                /* background-color: #000000; */
                background-color: #FBF7F2;
                /* box-shadow: 0px 4px 4px 0px #00000040; */
                /* border-radius: 12px; */
                padding: 20px 10px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                position: relative;
                min-height: 120px;
                max-height: 120px;
                display: flex;
                border: 1px solid #3636361A;
                flex-direction: column;
                justify-content: space-between;

                .card-rank{
                    position: absolute;
                    top: 2px;
                    right: 8px;

                    .rank-text{
                        font-size: 20px;
                        font-weight: 700;
                        color: #FFFFFF;
                    }
                }

                .card-header{
                    display: flex;
                    /* align-items: center; */
                    gap: 0.5em;
                    /* margin-top: -10.5px; */
                    padding-top: 12px;

                    .university-icon {
                        border-radius: 12%;
                        height: 50px;
                        width: 110px;
                        box-shadow: 0px 2px 2px 0px #36363640;
                    }
    
                    .university-detail {
                        display: flex;
                        flex-direction: column;
                        /* padding-top: 12px; */
    
                        .university-name {
                            font-size: 18px;
                            font-weight: 600;
                            /* color: #FFFFFF; */
                            color: #E4151B;

                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
    
                        .university-address{
                            font-size: 14px;
                            font-weight: 400;
                            /* color: #FFFFFF; */
                            color: #363636;

                        }
                    }
                }

                .compare-checkbox{
                    position: absolute;
                    /* top: 2px; */
                    right: 10px;
                    bottom: 0;

                    input{
                        border: 1px solid;
                    }
                }

            }

            .card-header-block-2{
                /* background-color: #FFFFFF; */
                background-color: #FBF7F2; 

                display: flex;
                flex-direction: column;
                padding: 0 10px;
                margin-top: 10px;
                gap: 0.6em;

                .detail_UI{
                    font-size: 14px;
                    font-weight: 600;
                    width: 70%;
                    background-color: #FBF7F2;
                    color: #E4151B;
                    margin-top: 5px;
                }
                /* .other-fee{
                    color: #E4151B;
                    font-size: 14px;
                    font-weight: 600;
                } */

                .program-name{
                    font-size: 14px;
                    /* width: 60%; */
                    font-weight: 600;
                    color: #3C3C3C;
                    display: -webkit-box;
                    padding: 0 !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
                .program-detail-text{
                    padding: 0 !important;
                }
                .program-detail-text p{
                    font-size: 12px;
                    /* font-weight: 600; */
                    width: 100%;
                    /* white-space: nowrap; */
                    background-color: #FBF7F2;
                    display: -webkit-box;
                    padding: 0px !important;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    /* margin-top: 10px; */
                    color: #3C3C3C;
                }
            }
        }

        .section-2{
            /* display: grid; */
            grid-template-columns: repeat(2, 1fr);
            /* background-color: #FFFFFF; */
            background-color: #FBF7F2;

            padding: 0px 10px 10px 10px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            .program-detail-section-1{
                /* display: flex; */
                /* flex-direction: column; */
                /* justify-content: space-between; */
                /* align-items: flex-start; */
                /* text-align: start; */
                /* gap: 0.3em; */

                
                /* .detail_UI{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 12px;
                    font-weight: 700;
                    color: #363636
                } */
                .vertical-control{
                    display: flex;
                    flex-direction: column;
                }
                .other-fee{
                    color: #E4151B;
                    font-size: 14px;
                    position: relative;
                    cursor: pointer;
                    /* font-weight: 600; */
                    margin-top: 5px;
                }
                .other-fee::after {
                    content: attr(data-tooltip);
                    position: absolute;
                    bottom: 100%; /* Position above the element */
                    left: 100%;
                    transform: translateX(10px) translateY(10%);
                    background-color: #FBF7F2;
                    color: #000;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    white-space: nowrap;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
                    z-index: 10;
                }
                
                .other-fee:hover::after {
                    opacity: 1;
                    visibility: visible;
                }
                .new-fee{
                    margin-top: 5px;
                }
                .parent-button {
                    display: grid;
                    grid-template-columns: auto auto; /* Two automatic columns */
                        justify-content: center;
                        margin-bottom: 20px;
                        margin-top: 60px;
                  }
                .fee-detail{
                    /* margin-top: 10px; */
                    /* margin-bottom: 25px; */
                    white-space: nowrap;
                    font-size: 13px !important;

                    .header{
                        font-size: 14px;
                        font-weight: 600;
                    }
                    
                }
                .fee-detail1{
                    /* margin-top: 10px; */
                    /* margin-bottom: 25px; */
                    white-space: nowrap;
                    padding: 0 !important;
                    font-size: 13px !important;

                    .header{
                        font-size: 14px;
                        font-weight: 600;
                    }
                    
                }

                .subInst .subInst1 .waiverPercentage,
                .subInst .subInst2 .waiverPercentage {
                    border-radius: 5px;
                    padding: 2px 10px;
                    color: #E4151B;
                    font-weight: 600;
                    width: fit-content;
                    border: 1px solid #E4151B;
                    background-color: #fff;
                }
                
                .subInst .subInst1 .waiverPercentage {
                    font-size: 13px;
                }
                
                .subInst .subInst2 .waiverPercentage {
                    font-size: 0.75rem;
                }
                
                .InstSubTitle span {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    color: #E4151B;
                    text-align: left;
                }

                .detail_UI1{
                       font-size: 12px;
                       font-weight: 700;
                       margin-top: 18px;
                       margin-left: 10px;
                       color: #363636
                   }
                   .calendar-icon{
                    margin-right: 7px;
                   }
                   .create-btn {
                    background-color: #ff0000; /* Red background */
                    color: #fff; /* White text */
                    margin-left: 8px; /* Space between buttons */
                  }
                   .create-btn
                   {
                    padding: 10px 20px;
                    /* width: 100%; */
  background-color: #E4151B;
  color: #fff;
  border: none;
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  font-size: 13px;
  font-weight: 500;
                   }
                   .know-btn
                   {
                    white-space: nowrap;
                    padding: 10px 20px;
                    height: fit-content;
                    font-size: 13px;
                    /* width: 100%; */
                    font-weight: 500;
                    border-radius: 5px;
                    background: transparent;
                    color: #E4151B;
                    /* border: 1px solid #213c92; */
                    border: 1px solid #E4151B
                   }
            }

            .program-detail-section-2{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                text-align: end;
                gap: 0.3em;

                .header{
                    font-size: 12px;
                    font-weight: 700;
                    color: #3C3C3C;
                }

                .detail{
                    font-size: 12px;
                    font-weight: 400;
                    color: #3C3C3C;
                }
            }
        }
    }
}


.calender-data{
    font-size: 14px;
    font-weight: bold;
    /* padding: 0 !important; */
    /* text-align: right; */
    /* margin-right: 17px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width:22% !important; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Intake{
    width: 22% !important;
}
.detail{
    /* display: flex; */
    /* justify-content: space-between; */
    /* flex-direction: row; */
    /* align-items: flex-start; */
    /* gap: 33px; */
}

.intake-header{
    font-size: 13px;
    font-weight: 600;
    color:#E4151B;
}
.intake-detail{
    font-size: 12px;
    font-weight: 400;
    color: #363636;
    white-space: nowrap;
}

.NOdata{
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  /* margin-right: 18%; */
  justify-content: center;
  align-items: center;
}
.noimg{
    width: 100px;
    height: 100px;
}
.no{
    font-size: 30px;
    font-weight: 700;
    color: #E4151B;
    /* margin-top: 150px; */
    /* margin-left: 350px; */
}
.moment{
    font-size: 30px;
    font-weight: 700;
    color: #363636;
    /* margin-top: 150px; */
    /* margin-left: 350px; */
}
.line{
    border: 2px solid #213c9233;
    width: 90%;
    margin-top: 39px;
    margin-bottom: 40px;
}

@media screen and (min-width: 250px) and (max-width: 1111px) {
    .cp-selected-text{
        text-align: center;
        white-space: wrap !important;
    }
    
}
/*
@media screen and (min-width: 750px) and (max-width: 1050px) {
    .calender-data{
        width: 30% !important;
    }
    .Intake{
        width: 30% !important;
    }
} */

@media screen and (min-width: 1025px) and (max-width: 1400px){
    .program-cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    /* .parent-button{
        margin: 30px 45px 20px !important;
    } */
}


@media screen and (min-width: 768px) and (max-width: 1050px) {
    .program-cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .col-8-to-7 {
        flex: 0 0 auto;
        width: 58.333333%;
    }
    /* .parent-button{
        margin: 30px 60px 20px !important;
    } */
}

@media screen and (max-width: 767px) {
    .program-cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.comman-filters{
    span{
        font-size: 14px;
        font-weight: 600;
    }
}

.fav-icon-position{
    position: absolute;
    right: 5px;
    top: 7px;
    img{
        height: 25px;
        width: 25px;
        cursor: pointer;
    }
}
.fav-icon-position:hover{
    position: absolute;
    right: 5px;
    top: 7px;
    img{
        height: 25px;
        width: 25px;
        cursor: pointer;
        /* filter: invert(120%) sepia(100%) saturate(2000) hue-rotate(290deg) brightness(66%) contrast(1000%); */
    }
}

.red-icon{
        height: 25px;
        width: 25px;
        cursor: pointer;
        /* filter: invert(120%) sepia(100%) saturate(2000) hue-rotate(290deg) brightness(66%) contrast(1000%); */
}

.clear-btn{
    background-color: #FFFFFF;
}