.container {
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin: 0px;
    background-color: white;
    padding: 10px;
  }
  .ModelContainer{
    .title {
        font-size: 20px !important;
        font-weight: 600;
        color: #E4151B;
        /* text-align: center; */
        margin-bottom: 10px;
    }
    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px 10px 10px;
    border-bottom: 0 !important;
    .corssIcon {
       padding: 10px;
        cursor: pointer;
    }
  }
  .btnContainer{
    display: flex;
    padding: 25px;
    justify-content: center;
    gap: 2em;
    .btnUpload{
        border-radius: 12px;
        border: 1px solid #E4151B ;
        color: #F2F2F2;
        background-color: #E4151B;
      }
      .btnUpload:hover{
        background: #E4151B;
        color: #F2F2F2;
    }
    button{
        padding: 7px 15px;
    }
}
  .uploadBoxContainer{
    border: 1px solid black;
    padding: 20px;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    border-radius: 12px;
  }
  }
  .customTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .customTable th,
  .customTable td {
    border: 1px solid #213C92;
    padding: 12px;
    text-align: center ;
  }
  .customTable th:first-child,
  .customTable td:first-child {
    padding: 12px;
    text-align: left !important;
  }
  
  .customTable th {
    border-top: none;
    font-weight: 600;
    font-size: 15px;
  }
  
  .customTable thead th:last-child {
    border-right: none;
  }
  .customTable thead th:first-child {
    border-left: none;
  }
  
  .customTable tbody tr:first-child td {
    border-top: none;
  }
  
  .customTable tbody tr:last-child td {
    border-bottom: none;
  }
  
  .customTable tbody td:first-child {
    border-left: none;
  }
  
  .customTable tbody td:last-child {
    border-right: none;
  }
  .customTable tbody {
    font-size: 15px;
  }
  
  .thAndIconContainer {
    text-align: center;
    vertical-align: middle;
  }
  
  .columnText {
    margin-right: 8px;
    white-space: noWrap;
  }
  
  .actionBtn {
    color: #007bff;
    cursor: pointer;
    margin-right: 10px;
  }
  .centerIcon{
    vertical-align: middle;
    text-align: center;
  }
  .uploadBtn{
    background-color:#213C92;
    border-radius: 12px;
    padding: 10px;
    /* margin-top: 10px !important; */
    color: white;
  }