.commentsSection {
    border-top: 2px solid darkgrey;
    padding: 10px 0;
    margin-top: 15px;
    height: 100%; /* Make sure this takes up the full container height */
    position: relative;
  }
  
  .comment {
    padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: column;
  }
  
  .commentHeader {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #36363620;
    padding: 5px;
    width: 100%;
  }
  .commentHeader1 {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #36363620;
    padding: 5px;
    width: 100%;
  }
  .commentsContainer {
    flex-grow: 1; /* This will take up remaining space */
    overflow-y: auto; /* Enables scrolling */
    /* padding: 10px; */
    /* border: 1px solid #ccc; */
    height: 400px; /* Set a fixed height for the comment section */
  }
  .commentContainer {
    /* display: flex;
    align-items: flex-start; */
    position: relative;
    /* margin-left: 20px; */
  }
  
 /* Vertical line connecting comments */
.verticalLine {
  position: absolute;
  width: 2px;
  background-color: #E4151B;
  top: -25px;
  /* bottom: -2px; */
  left: -10px;
  height: 74px;
}

/* Horizontal line connecting vertical line to the child comment */
.horizontalLine {
     /* content: ""; */
     position: absolute;
     width: 10px;
     height: 2px;
     background-color: #E4151B;
     top: 47px;
     left: -10px;
}
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  
  .commentContent {
    display: flex;
    flex-direction: column;
  }
  
  .commentAuthor {
    font-weight: 500;
    color: #363636;
  }
  
  .commentText {
    color: #363636;
    margin-top: 3px;
    font-size: 14px;
  }
  
  .commentActions {
    display: flex;
    justify-content: end;
    margin-top: 5px;
  }
  
  .actionButton {
    margin-right: 15px;
    cursor: pointer;
    font-size: 13px;
  }
  
  .replies {
    margin-left: 30px; 
    border-left: 1px solid #eaeaea;
    padding-left: 10px;
  }
  
  .commentForm {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  
  .inputContainer {
    display: flex;
        align-items: center;
        background-color: #213C92;
        padding: 10px;
        border-radius: 8px;
        position: relative;
        background: #FFFFFFCC;
        border: 1px solid #36363633;
  }
  
  .commentInput {
    flex-grow: 1;
    height: 33px;
    background-color: transparent;
    border: none;
    resize: none;
    padding: 4px;
    color: #000;
    font-size: 14px;
    background: white;
    border-radius: 10px;
    margin-right: 30px;
  }
  
  .commentInput:focus {
    outline: none;
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }

  
  /* .submitButton {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 10px;
    align-self: flex-end;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  } */
  .sendButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .sendIcon {
    width: 24px;
    height: 24px;
    color: #007bff;
  }
   
  .dotsMenu {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dotsIcon {
    width: 16px;
  }
  
  /* Popup menu for dots */
  .popupMenu {
    /* position: absolute; */
    background-color: red;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    right: 0;
    top: 30px;
    z-index: 10;
  }
  
  .popupMenu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .popupMenu li {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .popupMenu li:hover {
    background-color: #f0f0f0;
  }

  .moreBtn{
    border-radius: 12px 0px 12px 12px;
    border: 1px solid #363636;
    background-color: rgba(250, 250, 250, 1);
    display: flex;
    flex-direction: column;
    position: absolute;
    /* bottom: -66px; */
    top: 13px;
    right: 22px;
    /* box-shadow: 0px 2.34px 2.34px 0px rgba(0, 0, 0, 0.25); */
    z-index: 10;
    padding: 0px 5px 0px 5px;
  }
  .moreBtnItem{
    color:#363636;
    font-size: 16px;
    text-align: right;
    text-wrap:nowrap;
    display: flex;
    font-weight: 500;
    margin: 5px;
    padding-right: 10px;
    /* padding: 7px 3px 6px 30px; */
    cursor: pointer;
  }
  .moreBtnParent{
    position: relative;
  }
  .imgPadding{
    padding: 0px 5px 0px 0px;

  }

  .red-header {
    background: #E4151B;
    border-radius: 12px 12px 0 0;
    .text-font {
      font-size: 20px;
      font-weight: 600;
      color: #F2F2F2;
    }
    .ignor-btn{
      margin: 10px;
      border: none;
      background: transparent !important;
      border-radius: 12px;
      font-size: 16px;
      /* padding: 5px 15px; */
      color: #fff;
      font-weight: 500;
      /* box-shadow: 0px 4px 4px 0px #00000040; */
    }
    .delete-btn{
      margin: 10px;
      border: none;
      background: transparent !important;
      border-radius: 12px;
      font-size: 16px;
      /* padding: 5px 15px; */
      color: #ffffff;
      font-weight: 500;
      /* box-shadow: 0px 4px 4px 0px #00000040; */
  
   }
  }
  .delete-btn:hover{
    text-decoration: underline;
  }
  .ignor-btn:hover{
    text-decoration: underline;
  }
  
  .red-content {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
  }
  .emoji_picker_wrapper {
    position: absolute;
    z-index: 999; 
    right: 0;
    bottom: 45px;
  }
  .close_icon_emojis {
    position: absolute;
      top: 4px;
      right: 6px;
      width: 24px;
      height: 24px;
      z-index: 99;
      cursor: pointer;
      background-color: #213c92;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
  }