.notification-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user_navigation {
    padding: 0px 20px;
    display: flex;
    gap: 2rem;
    margin-top: 10px;

    button {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      background: none;
      color: #363636;;
      cursor: pointer;
      border: none;
      padding: 5px 10px;
    }

    button:hover {
      font-size: 16px;
      border-radius: 12px 12px 0px 0px;
    }

    .active_button {
      border-bottom: 2px solid  #E4151B;
      /* color: #E4151B; ; */
      font-size: 16px;
      border-radius: 12px 12px 0px 0px;
    }
}

.notification-section2-content{
    border: 1px solid #3636361A;
    background: #FAF5EFCC;
    border-radius: 15px;
    overflow: hidden;
    max-height: 590px;
    overflow-y: auto;
}


.notification-Item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 20px;
  border-bottom: 1px solid #36363633;
  position: relative;
}

.notification-Item:hover{
  background: #F2E7DE80;
  cursor: pointer;
}


.notification-icon-part{
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #36363680;
  background: #FFFFFF;
}

.notification-icon{
  width: 20px;
  height: 20px;
}

.notification-image-part {
  display: flex;
  gap: 10px;
}

.notification-image{
  height: 60px;
  /* box-shadow: 0px 6.02px 6.02px 0px #00000040; */
  width: 60px;
  border-radius: 50%;
}

.notification-text{
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3C3C3C;
}

.notification-text:hover{
  color :#E4151B;
}

.notification-date{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  font-weight: 600;
}

.notification-Item.unread {
  font-weight: bold;
  background: #F2E7DE;
  overflow: hidden;
}


.no-data{
  text-align: center;
  margin-top: 100px;

  img{
    width: 80px;
    height: 80px;
  }
}
.red-span{
  font-size: 18px;
  font-weight: 700;
  color: #E4151B;
}
.backSpan{
  font-size: 18px;
  font-weight: 700;
  color: #363636;
}




.ModelContainer{
  .title {
      font-size: 20px !important;
      font-weight: 600;
      color: #E4151B;
      /* text-align: center; */
      margin-bottom: 10px;
  }
  .headerContainer {
      padding: 10px 5px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  border-bottom: 0 !important;
  .corssIcon {
      /* height: 48px; */
      padding: 10px;
      cursor: pointer;
  }
}

.detailscontainer{
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  border: 1px solid #36363680;
  background: #F2E7DE80;
}

}