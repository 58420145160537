.header {
    border-bottom: 0 !important;
    justify-content: space-between !important;
    position: relative;
    padding: 5px 15px !important;

    .title {
        font-size: 28px !important;
        font-weight: 800;
        color: #000000;
    }
    .heading-text-modal{    
        font-size: 25px;
        font-weight: 700;
        /* padding-left: 20px; */
        color: #E4151B;
      }
    .iconSizeForAboutModel{
        width: 50px !important;
        height: 50px !important;
    }
    .corss-icon {
        height: 20px !important;
        width: 20px !important;
        /* position: absolute; */
        /* top: 6px; */
        /* right: -2px; */
        cursor: pointer;
    }
    .tooltip {
        visibility: hidden; 
        font-size: 13px;
        width: 5px; 
        color:black;
        text-align: center; 
        /* border-radius: 5px;  */
        /* padding: 5px; */
        position: absolute;
        z-index: 1;
        right: 15%;
        top: 40%;
        /* left:10%; */
        transform: translateY(-95%);
        opacity: 0;
        transition: opacity 0.3s;
        background: none; /* Remove background */
        border: none; /* Remove border */
        padding: 0; /* Remove padding */
      }
    
    
      
      .tooltip-container:hover .tooltip {
        visibility: visible; 
        opacity: 1; 
      }

}