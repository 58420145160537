.modal-content {
  margin-top: 10px;
  width: fit-content;
  /* overflow: hidden; */
  max-width: 100%; 
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal; 
}

.media {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.modal_body_data{
  /* margin-bottom: 20px; */
overflow-y: auto;
height: 600px;
}
.mediaInfo {
  margin-top: 10px;
}

.mediaInfo p {
  margin: 0;
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Break long words */
}

.close_icon {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* background-color: #213c92; */
  /* border-radius: 50%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.modal_header{
  display: flex;
  justify-content: space-between;
}
/* .close_icon_position{
  justify-content: space-between;
} */