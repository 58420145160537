.cross-icon-position{
    text-align: right;
    cursor: pointer;
    img{
        /* width: 30px;
        height: 30px; */
    }
}
.btn-position{
    text-align: center;
    margin-top: 20px;
}
.heading{
font-size: 18px;
font-weight: 600;
color: #3C3C3C;
}
label{
font-weight: 600;
font-size: 16px;
font-weight: 600;
color: #3C3C3C;
}
