
.groups_dashboard_sections{
    display: grid;
    gap: 20px; 
}
.compose-post-container {
    display: grid;
    grid-template-columns: 2fr 1fr; /* 8:4 ratio (2:1) */
    gap: 20px; 
}

.compose-posts {
}



/* Responsive Styles */
@media (max-width: 768px) {
    .compose-post-container {
        grid-template-columns: 1fr; 
    }

    .compose-posts {
        gap: 10px; 
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .compose-post-container {
        grid-template-columns: 2fr 1fr; 
    }
}

@media (min-width: 993px) {
    .compose-post-container {
        grid-template-columns: 2fr 1fr; 
    }
}

.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76vh;
    font-size: 1.5rem;
    background: #FAF5EFCC;
    border: 1px solid #36363633;
    border-radius: 8px;
  }
  
