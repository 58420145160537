.user_account {
}

.user_account_info {
  background: #ffffff;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  border-radius: 12px;
  padding: 0 !important;
  height: max-content;

  .user_details {
    margin-top: 60px !important;
    margin-bottom: 0px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;

    .user_personalDetails {
      font-size: 24px;
      font-weight: 600;
      text-align: left;

      p {
        margin-top: 0;
        margin-bottom: 1rem;
        word-break: break-word;
      }
      .name{
        font-size: 30px;
        font-weight: 700;
      }
      .email{
        font-size: 16px;
        font-weight: 400;
        line-height: 21.79px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #36363680;
      }
    }

    .user_highlights {
      font-size: 18px;
      font-weight: 600;
      text-align: right;
      color: #363636;
      padding-right: 20px;

      p:first-child{
        text-align: right;
      } 
    }
  }

  .background_image {
    position: relative;

    .background_image_pic img {
      overflow: hidden;
      border-radius: 12px 12px 0px 0px;
      width: 100%;
      height: 250px;
    }

    .user_profile_pic {
      position: absolute;
      left: 10px;
      top: 42%;

      img{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 1px solid #213C921A;
      background-color: white;
      }
    }

    .edit_profile_icon {
      position: absolute;
      left: 167px;
      bottom: -13%;
      cursor: pointer;
    }
  }

  .user_connections {
      font-size: 14px;
          font-weight: 400;
          text-align: left;
          /* padding: 12px; */
          margin: 20px;
          margin-top: 0px;
          border-bottom: 1px solid #213C921A;
          padding-bottom: 20px;
          /* background: #213C921A; */
          margin: pointer;
          /* text-decoration: underline; */
          color: #363636;
  }

  .section-line hr {
    border: 3px solid #213c92;
    margin-left: 12px;
  }

  .user_navigation {
    padding: 0px 20px;
    button {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      background: none;
      color: black;
      cursor: pointer;
      border: none;
      padding: 5px 10px;
    }

    button:hover {
      font-size: 20px;
      border-radius: 12px 12px 0px 0px;
    }
    
.active_button {
  border-bottom: 2px solid #E4151B;
  /* color: #E4151B; */
  font-size: 20px;
  border-radius: 12px 12px 0px 0px;
}
  }
}

/* for temperary */
.posts_section {
  max-width: 820px;
  margin: 0px auto;
}

.user_account_marketing {
  .marketing_section {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
}

 .user_navigation-section{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: end;
 }

.send-req-btn{
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    border-radius: 12px;
    background-color: #213c92;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    margin-bottom: 10px;
}