.counsellorContainer {
  box-shadow: 4px 4px 25px #b5b5b54d;
  margin-bottom: 30px;
  border-radius: 15px;
  background: #fff;
  padding-bottom: 5px;
}


.icon {
  width: 11.59px;
  height: 11.59px;
  cursor: pointer; 
}

.iconContainer {
  display: flex;
  gap: 15px;
  background: #E4151B;
  padding: 10px;
  border-radius: 12px;
}

.chatContainer {
  padding: 10px 0;
  flex-grow: 1;
  position: relative;
  overflow-y: hidden;
  background-color: #ffffff;
}

.chatUpdateTimeUser {
  display: block;
  text-align: right;
  font-size: 0.8em;
  color: #aaa;
  margin-top: 5px;
  margin-left: auto;
  margin-right: 10px;
}

.chatUpdateTimeOpposite {
  display: block;
  text-align: left;
  font-size: 0.8em;
  color: #aaa;
  margin-top: 5px;
  margin-left: 10px;
}


.inputArea {
      display: flex;
      padding: 10px;
      border: 1px solid #ddd;
      align-items: center;
      border-radius: 10px;
      margin: 0 10px;

}

.inputField {
  width: 100%;
  border: none;
}
.inputField:focus-visible{
  outline: transparent !important;
}
.sendButton {
  margin-left: 10px;
  border: none;
  border-radius: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendButton:disabled{
  background: transparent !important;
  border:none !important;
  padding: 0px !important;

}

.iconsend {
  width: 25px;
  height: 25px;
}

.chatMessage {
  padding: 8px;
  font-size: 16px;
  background-color: #FFE4DE;
  color: #363636;
  width: fit-content;
  max-width: 100%;
  border-radius: 5px; 
   margin-top: 25px;
   /* line-break: anywhere; */
    margin-left: 10px;
}




.chatMessageOpposite {
  padding: 8px;
  font-size: 16px;
  background-color: #ffffff;

  color: #000000;

  max-width: 100%;
  
}






.chatUpdateTime1 {
  margin: 0px 5px 0px 10px;
  font-size: 12px;
  font-weight: 200;
  color: rgba(60, 60, 60, 1);
}
.chatUpdateTime2 {
  margin: 0px 5px 0px 10px;
  font-size: 12px;
  font-weight: 200;
  color: rgba(60, 60, 60, 1);
}



.ai-caht-div{
   margin: 25px 5px 0;
    background: #F9F6F1;
    padding: 10px;
    border-radius: 5px;
    color: #363636;
    /* line-break: anywhere; */
    margin-right: 10px;
}
.ai-icon{
  margin-left: 5px;
  margin-top: 25px;
}

.user-icon{
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 25px;
  img{
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

}
.tab-btn{
  background: #F9F6F1;
  color: #e4151b;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #E4151B;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 10px;
  margin-left: 5px;
}

.hidden {
  visibility: hidden;
  height: 190px;
}

.chat-scroll{
  max-height: 400px;
  overflow-x: auto;
  min-height: 400px;
}

.loader-height{
  height: 30px;
}
.timer-text{
    font-size: 0.8em;
    color: #aaa;
    padding-left: 43px;
}
.chat-cion-position{
  display: flex;
      justify-content: end;
      margin-top: 5px;
}

.header-icon{
  background: #CA242A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    img{
      height: 40px;
      width: 40px;
    }
}

.chat-header{
  background: #E4151B;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-heading{
  font-family: Poppins !important;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 5px;
}
.chat-sub-heading{
  font-family: Poppins !important;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  margin-left: 5px;
}

.red-circle{
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4151b;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 5px;
  right: 30px;
}
.bot-icon{
  animation: bounce 2s infinite ease-in-out;
  height: 30px;
  width: 30px;
}


@keyframes bounce {
0%, 100% {
    transform: translateY(5px); /* Starting and ending position */
}
50% {
    transform: translateY(-5px); /* Move the icon up */
}
}

.refresh-icon{
  cursor: pointer;
}

.hint-ai{
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    p{
      margin-bottom: 0;
    }
}
.crossicon{
  position: absolute;
  right: -13px;
  cursor: pointer;
  top: -10px;
  img{
    height: 12px;
    width: 12px;
  }
}

.chat-heading-icons{
  display: flex;
  align-items: center;
  gap: 5px;
}

.cross-icon{
  height: 24px;
  width: 24px;
  cursor: pointer;
}