.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.group-item {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #E4151B4D;

}
.red-border-groups{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid #A2191C;
  margin-top: 10px;
}
.selected-groups{
  color: #E4151B;
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 0px;
}
.block-div{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #E4151B;
}

.group-item:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px;
  gap: 10px;
}
.group-item:only-child{
  border-bottom: none;
}
.group-image {
  width: 65px;
  height: 65px;
  box-shadow: 0px 6.02px 6.02px 0px #00000040;
  border-radius: 50%;

  img{
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}
.group-details {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex-grow: 1;
  line-break: anywhere;
}
.group-name {
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #363636;
  margin: 0px;
}
.group-update {
  font-size: 16px;
  color: #363636;
  font-weight: 300;
}
.selected-group-update {
  font-size: 16px;
  color: #E4151B;
  font-weight: 300;
}
/* .group-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
} */

.group-actions-block{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 5px; */
}
.icon {
  width: 24px;
  height: 24px;
}
.group-member {
  font-weight: 400;
  font-size: 16px;
}
.withdrawButton {
  border: none;
  font-size: 14px;
  text-decoration: underline;
  padding-top: 10px;
  cursor: pointer;
  color: #363636;
}
.primary-button{
  font-size: 15px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: #E4151B;
  color: white;
  border: none;
}
.nameAndCrown {
  display: flex;
  align-items: center;
  /* column-gap: 1em; */
  cursor: pointer;
}
.nameAndCrownWithOutCursor {
  display: flex;
  align-items: center;
}
.imgCrown{
  height: 20px;
}
.moreBtnParent {
  position: relative;
}
.moreBtn,
.userMoreBtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -80px;
  right: 22px;
  border-radius: 12px;
  border: 1px solid #ccc;
  background-color: rgba(250, 250, 250, 1);
  /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
  z-index: 10;
  padding: 5px;
}

.moreBtnItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #363636;
  cursor: pointer;
  padding: 5px 10px;
}

.imgPadding {
  margin-right: 5px;
}

.block-text{
  background-color: red;
  color: white;
  padding: 3px;
  margin-left: 3px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  /* position: absolute;
  right: 5px; */
  font-size: 12px;
  top: 62px;
}
.cancel-btn{
  color: #363636;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.archive-icon{
  height: 16px;
  width: 16px;
}