.rating-section-align{
    display: flex;
    justify-content: center;
}
.rating-section{
    position: relative;
    border: 1px solid #172A66;
    width: 100%;
    max-width: 500px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    background: #fff;
    .rating{
        height: 20px;
        width: 100px;
        position: relative;
        cursor: pointer;
        p{
            margin-left: -20px;
            font-size: 16px;
        }
       
    }
    .selectedRating1{
        height: 20px;
        width: 100px;
        background: #E4151B;
        border-radius:  12px 0px 0 12px;
        position: relative;
        cursor: pointer;
        p{
            margin-left: -20px;
            font-size: 16px;
        }
    }
    .selectedRating5{
        height: 20px;
        width: 100px;
        background: #E4151B;
        border-radius: 0 12px 12px 0;
        position: relative;
        cursor: pointer;
        p{
            margin-left: -20px;
            font-size: 16px;
        }
    }
    .selectedRating{
        height: 20px;
        width: 100px;
        background: #E4151B;
        position: relative;
        cursor: pointer;
        p{
            margin-left: -20px;
            font-size: 16px;
        }
    }
    .number-align{
        position: absolute;
        top: 20px;
        color: #E4151B;
        font-size: 20px;
    }
}
.selector{
    border: 1px solid #E4151B;
    border-radius: 12px;
    padding: 3px;
    position: absolute;
    left: -2px;
    top: -7px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;

    div{
        height: 25px;
        width: 10px;
        background: #E4151B;
        border-radius: 12px;
    }
}
.selector1{
    border: 1px solid #E4151B;
    border-radius: 12px;
    padding: 3px;
    position: absolute;
    left: 99px;
    top: -7px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;

    div{
        height: 25px;
        width: 10px;
        background: #E4151B;
        border-radius: 12px;
    }
}
.selector2{
    border: 1px solid #E4151B;
    border-radius: 12px;
    padding: 3px;
    position: absolute;
    left: 199px;
    top: -7px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;

    div{
        height: 25px;
        width: 10px;
        background: #E4151B;
        border-radius: 12px;
    }
}
.selector3{
    border: 1px solid #E4151B;
    border-radius: 12px;
    padding: 3px;
    position: absolute;
    left: 298px;
    top: -7px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;

    div{
        height: 25px;
        width: 10px;
        background: #E4151B;
        border-radius: 12px;
    }
}
.selector4{
    border: 1px solid #E4151B;
    border-radius: 12px;
    padding: 3px;
    position: absolute;
    left: 398px;
    top: -7px;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;

    div{
        height: 25px;
        width: 10px;
        background: #E4151B;
        border-radius: 12px;
    }
    
}
.heding-section{
  display: flex;
  justify-content: center;
  .sub-heading{
    color: #3C3C3C;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .sub-heading-rate{
    color: #3C3C3C;
    margin-top: 75px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .sub-heading-account{
    color: #3C3C3C;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }
}
.emogi-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.form-footer{
    .button-section{
        margin-top: 10px;
        .next-btn{
            padding: 7px 60px !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            box-shadow: none !important;
        }
    }
}