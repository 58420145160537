

.main_create_group_container {
}


.form-section {
  /* background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px; */
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  /* padding: 10px; */
  margin-bottom: 20px;

  .heading {
    font-size: 24px;
    font-weight: 600;
    color: #E4151B;
  }
}

.group-name-section {
  /* i want to remvode flex from here see in last how i do it */
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  /* padding: 0px 30px; */

  /* input[type="text"] {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid #213c92;
    padding: 0px 10px 10px 10px;
  }
  input[type="text"]:focus {
    border-bottom: 3px solid #213c92;
  } */

  .group-type {
    display: flex;
    gap: 2em;
    justify-content:center;
    align-items: center;

    .radio-option {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;

      input[type="radio" i] {
        font-size: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        accent-color: #E4151B;
      }
    }
  }
}

.invite-section {
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  .search_input_content {
    position: relative;
    width: 100%;

    .search-icon {
      position: absolute;
      top: 13px;
      right: 20px;
      height: 20px;
    }

    /* .search-input {
      padding: 10px;
      border-bottom: 2px solid #213c92;
      width: 250px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 600;
      width: 100%;
    } */
  }
}

.list-content {


  .invite-list , .moderator-list{
    height: 205px;
    overflow-y: auto;
    border-radius: 12px;
    padding: 10px;
    margin: 0;
    background: #FBF7F2;

    .name-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0px;

      .name-info {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 16px;
        font-weight: 600;
      }

      .name-image {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        box-shadow: 0px 6.02px 6.02px 0px #00000040;
      }

      .checkbox-container {
        display: flex;
        align-items: center;

        .checkbox-input{
          border-radius: 50% !important;
          width: 20px;
          height: 20px;
        }
      }

     
    }
  }
}

.image-upload-section {
  display: flex;
  flex-direction: column;
  gap: 0.7em;

  .image-upload {
    height: 246px;
    width: 100%;
    background: #FBF7F2;
    border: none;
    border-radius: 12px;
    position: relative;

    .real-image {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    .over-lay-upload {
      display: block !important;
      width: 100%;
      height: 100%;
      position: absolute;

      .hover-upload {
        position: absolute;
        cursor: pointer;
        top: 40%;
        left: 40%;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }

      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
      .damy-image {
        width: 55px;
        height: 55px;
      }
    }
  }
}

.description-section {
  textarea {
    background: #FBF7F2;
    height: 246px;
    border: none;
    border-radius: 12px;
    padding: 10px;
    font-size: 20px;
    font-weight: 400;
  }
}



.create-group-button,
.create-group-cancel-button {
  width: 100%;
  /* height: 50px; */
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  border-radius: 12px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  border: none;
  padding: 7px 15px;
  
}

.create-group-button {
  background-color: #E4151B;
}

.create-group-cancel-button {
  background-color: #3636364D !important;
}

.create-group-button:disabled {
  background-color: #3636364D !important;
  padding: 7px 15px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #fff !important;
  border-radius: 12px !important;
  border: none !important;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
}

.search-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


/* added modern scrollbar */

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  margin: 6px 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.error {
  color: red;
  text-align: left;
  /* margin: auto; */
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0px;
}