.heading {
    font-size: 24px;
    font-weight: 600;
    color: #E4151B;
  }

  .Edit_msm_Container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .header {
    color: #E4151B;
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0 !important;
    }
    img{
      /* filter: invert(1); */
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    }
  }
  .tab-alignment {
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    width: 100%;
  
    .tab-text {
      color: #363636;
      font-family: 'Poppins';
      cursor: pointer;
      white-space: nowrap;
      margin-right: 20px;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid transparent;
    }
    .selected-text{
      color: #363636;
      font-family: 'Poppins';
      cursor: pointer;
      white-space: nowrap;
      margin-right: 20px;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid #E4151B;
    }
    /* .tab-text:hover{
      color: #E4151B;
      font-family: 'Poppins';
      cursor: pointer;
      white-space: nowrap;
      margin-right: 20px;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid #E4151B;
    } */
  }
  .col-background {
    /* background: #E4151B; */
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sub-heading-result {
    font-size: 20px;
    font-weight: 600;
    color: #E4151B;
    margin-bottom: 0;
  }
  .sub-heading {
  font-size: 18px;
  font-weight: 500;
  color: #363636;
  margin-bottom: 0;
  }
  .institue-image {
    width: 166px;
    height: 166px;
  }
  .col-white-background {
    background: #fff;
  }
  .save-btn{
    padding: 7px 15px;
    border-radius: 10px;
    color: #fff;
    background: #E4151B;
    border: 1px solid #E4151B;
    /* margin-top: 10px; */
    margin-left: 20px;
    font-size: 18px;
  }
  .submit-btn{
    padding: 7px 15px;
    border-radius: 10px;
    color: #fff;
    background: #E4151B;
    border: 1px solid #E4151B;
    /* margin-top: 10px; */
    margin-left: 20px;
    font-size: 18px;
  }
  
  .submit-btn:disabled{
    font-size: 18px !important;
  }

  .create-app-btn{
    padding: 0px 15px;
    border-radius: 16px;
    color: #fff;
    background: #363636;
    /* background: #213c92; */
    border: 1px solid #363636;
    /* margin-top: 10px; */
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
    pointer-events: none;
  }

  .status_btn_Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .endContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* margin-top: 20px; */
    .endLabel{
      padding-left: 10px;
      text-align: center;
    }
  }

  .text-end-content{
    display: flex;
    align-items: center;
  }

  .warn-message{
    margin-right: 10px;
    color: #E82529;
   font-size: 12px;
   font-weight: 600;
  }

  .validation-modal-block {
    .validation-modal-header {
        padding: 0px 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .cross-icon {
            cursor: pointer;
        }
    }

    .validation-modal-body {
        padding: 15px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .validation-modal-body-icon{
            border: 3px solid #000000;
            border-radius: 50%;
            padding: 15px;
        }

        .validation-modal-alert-text {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.23999999463558197px;
            color: #E82529;
        }

        /* .validation-modal-text{
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.23999999463558197px;
            color: #3C3C3C;
            padding: 0px 40px;
            text-align: center;
        } */

        .validation-modal-hint{
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.23999999463558197px;
            color: #3C3C3C;
            margin-top: 5px;
            margin-bottom: 30px;
            padding: 0px 40px;
            text-align: center;
        }
    }
}

.program-container{
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px 15px 0 0;
  margin-top: 20px;
}
.program-details{
  box-shadow: 0px -4px 6px 0px #3636361A;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #3636361A;
  padding: 20px;
}

.normal-program{
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px;
}