.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  box-shadow: 0 2px 10px #00000040;
  z-index: 1000;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_icon {
  cursor: pointer;
  width: 20px;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-text{
  font-size: 25px;
  font-weight: 700;
  color: #E4151B;
}
.close_icon_emojis {
  position: absolute;
    top: 4px;
    right: 6px;
    width: 24px;
    height: 24px;
    z-index: 99;
    cursor: pointer;
    background-color: #E4151B;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons_container {
  display: flex;
  align-items: center;
  gap: 50px;
  position: relative;
  /* margin-bottom: 10px; */
  /* margin-top: 3%; */
}

.icons_button_container{
  margin-bottom: 10px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.icon, .text_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.text_with_icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.modal_content {
  box-shadow: 0 2px 10px #00000040;
  background: white;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  width: 700px;
  max-width: 100%;
  height: 500px;
  margin-top: 100px;
  overflow: hidden; 
}

.insert_button {
  /* position: absolute; */
  /* bottom: 3%; */
  /* left: 83%; */
  padding: 7px 15px;
  background-color: #E4151B;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.insert_button:disabled {
  font-size: 16px !important;
}

.post_button{
  /* position: absolute; */
  /* bottom: 3%; */
  /* left: 80%; */
  padding: 5px 40px;
  background-color: #E4151B;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  font-size: 16px;
  font-weight: 500;
}

.post_button, .close_button {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 40px;
  background-color:#E4151B;
  ;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.modal_header {
  display: flex;
  gap: 20px;
  /* margin-bottom: 20px; */
}

.user_image {
  width: 54px;
  height: 54px;
  box-shadow: 0px 6.02px 6.02px 0px #00000040;
  border-radius: 50%;
}

.user_info {
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.3em;
}

.user_name {
  font-weight: bold;
  font-size: 24px;
}

.user_name_type{
  font-weight: bold;
  font-size: 16px;
}

.dropdown_icon {
  margin-left: 10px;
  cursor: pointer;
  transition: 1s;
}
.dropdown-arrow{
  margin-left: 10px;
  cursor: pointer;
  transform: rotate(180deg);
  transition: 1s;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;

}

.dropdown_menu .padding-div {
  padding: 20px;
  .input-position{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  p{
    font-size: 20px;
    color: #E4151B;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  input{
     cursor: pointer;
     margin-right: 5px;
     height: 20px;
     width: 20px;
     color: #000;
     font-size: 16px;
     font-weight: 600;
  }
  label{
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
}

/* .dropdown_menu div:hover {
  background: #f0f0f0;
} */

.post_textarea {
  width: 100%;
  height: 250px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px #00000040;
}
.react-quill-container .ql-editor.ql-blank::before {
  font-weight: 600; /* Or whatever weight you want */
}
.textarea_container {
  /* position: relative;
  width: 100%;
  height: 300px; 
  overflow: auto;  */
  padding: 0px 15px;
}
.deletev_icon{
  position: absolute;
  /* top: 5px; */
  /* right: -7px; */
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #d30707;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.emoji_picker_wrapper {
  position: absolute;
  top: 50px; /* Adjust this value as needed */
  z-index: 1000; /* Ensure it's above other elements */
}
.ql-editor {
  height: 100%; 
  display: flex;
  flex-direction: column;
  overflow: auto; 
}
.ql-editor img {
  display: block;
  margin-top: 100px; 
}

.image_container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(33.33% - 10px);
  flex-wrap: wrap;
  max-height: 100%;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  video{
    width: 100%;
    height: 100%;
    aspect-ratio: 3;
  }
}
.video_container {
  position: relative;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  max-height: 100%;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}


.image_preview {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* max-height: 280px; 
  overflow-y: auto;  */
}
.delete_icon {
  position: absolute;
  top: -5px;
  right: -7px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #E4151B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_preview img {
  max-width: 100%;
  border: 1px solid #ccc;
aspect-ratio: 16/9;
  /* object-fit: cover; */
  overflow-y: auto;
  /* border-radius: 5px; */
}

.error_message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.cp_button{
  display: flex;
  /* justify-content: end; */
  gap: 10px;
  .cancel-btn{
    padding: 5px 40px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    background: transparent;
    color: #E4151B;
    /* border: 1px solid #213c92; */
    border: 1px solid #E4151B
  }
}
.content-box{
  height: 350px;
  overflow-y: auto;
}
.select-content-box{
  height: 440px;
  overflow-y: auto;
}
.tag-align{
  display: flex;
  gap: 10px;
  align-items: center;
  p{
    margin: 0;
   font-size: 20px;
   font-weight: 400;
   color: #000000;
   padding-bottom: 200px;
  }
}

.image-circle{
  background: #d30707;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 44px;
  height: 44px;
  align-items: center;
}
.header-align{
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #36363633
}
.block-4 {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  /* align-items: center; */
  width: 100%;
}
.placeholder-text {
  color: #363636;
  /* font-style: italic; */
  /* font-family: Poppins; */
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #363636;

}
.placeholder-newtext{
font-size: 16px;
font-weight: 600;
text-align: left;

}

.tag-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.tag-input-area {
  /* min-height: 100px; */
  margin-left: 18px;
  margin-right:18px;
  /* border: 2px solid #172A66; */
  border: 1px solid #36363633;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
}

.tag-pill {
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 20.5px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  border: 2px solid #E4151B;
  font-size: 14px;
  color: #E4151B;
  font-weight: 600;

}

.suggested-skills-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  /* max-width: 500px; */
  margin-right: 18px;
  margin-left: 18px;
  /* border: 3px solid #172A66; */
  border: 2px solid #36363633;
  border-radius: 6px;
  padding: 10px 6px;
  height: auto;
  max-height: 200px;
  overflow: auto;
}

.suggested-skill {
  /* background-color: #888888; */
  background-color: #FFFFFF;

  padding: 5px 10px;
  border-radius: 20.5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #36363633;
  font-weight: 700;
  color: #363636;
}
.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 1em;
}

.selected-tag {
  background-color: #213c92;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.remove-tag {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}
/* .tags-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 1em;
} */
.alert-box-heading{
  display: flex;
  align-items: center;
  gap: 1em;
}

.alert-icon{
  height: 20px;
}

.alert-text{
  margin-left: 20px;
}
