.highlight{
    /* background: #E4151B; */
    color: #E4151B !important;
    /* text-align: center; */
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
.invactive:hover{
  /* background: #E4151B; */
  color: #E4151B !important;
  /* text-align: center; */
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

}

.invactive{
  /* background: #FFF; */
  /* text-align: center; */
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #363636;

}
.sidebar-section{
    /* width: 250px; */
    margin-right: 10px;
    background: #FFE4DE;
    height: 100vh;
    border: 1px solid #F7A6A4;
    box-shadow: 2px 2px 9px 0px #00000040;
    border-radius: 12px;
}