
.heading-text{
    margin-bottom: 0;
    font-family: 16px;
}
.header-position{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f44336;
    padding: 15px;
    color: #fff;
}
.cross-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.banner-img{
    width: 100%;
    margin-bottom: 20px;
}
.title-style{
    color: #363636;
    font-weight: bold;
}
.header-img{
    height: 100%;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    min-height: stretch;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
}

.spaceCustom{
    color: #363636;
    font-weight: bold;  
    margin-bottom: 0;
    font-size: 14px;
}
.font-size-div{
    font-size: 14px;
    color: #363636;
}
.footer-div{
    color: #f44336;
    font-size: 14px;
}
.blod-text{
    font-size: 14px;
    color: #363636;
    font-weight: bold;
    margin-left: 5px;
}
.cancel-btn{
    background: #e8252a !important;
    border: 1px solid #e8252a !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 6px 15px;
    box-shadow: none !important;
    font-size: .9rem;
}
.yes-btn{
    background: #34BF49 !important;
    border: 1px solid #34BF49 !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 6px 15px;
    box-shadow: none !important;
    font-size: .9rem;
    margin-right: 20px;
}

.over-flow-form{
    max-height: 500px;
    overflow-y: auto;
    padding: 0 15px;
}