.modal-body-block {
    display: flex;
    flex-direction: column;
    gap: 2em;
    .competencyTitle {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
      
      .rating-container {
        display: flex;
        justify-content: space-between;
      }
      
      .ratingNumber {
        min-width: 40px;
        width: fit-content;
        height: fit-content;
        line-height: 35px;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        /* color: rgba(33, 60, 146, 1); */
        color: #E4151B;
        font-size: 36px;
        font-weight: 600;
        border: 2px solid #E4151B;
        border-right: none;
        transition: background-color 0.3s ease;
      }
      .ratingNumber:first-child{
        border-radius: 12px 0px 0px 12px;
      }
      .ratingNumber:last-child{
        border: 2px solid #E4151B;
        border-radius: 0px 12px 12px 0px;
      }
      .ratingNumber:hover, .ratingNumber.selected {
        background-color: #E4151B;
        color: white;
      }
      .activeColors {
        background-color: #E4151B;
        border-right: 1px solid #fff;
        color: #fff;
      }
      .ratingLabel {
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
        column-gap: 10em;
      }
      
      .remarksLabel {
        font-size: 25px !important;
        font-weight: 600 !important;
        margin-bottom: 10px;
      }
      
      .remarksInput {
        width: 100%;
        min-height: 100px;
        border-radius: 12px;
        padding: 10px;
        font-size: 1rem;
        resize: none;
        border: 2px solid #E4151B;
      }
      
      .save-btn {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 1rem;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
      
      .save-btn:hover {
        background-color: #0056b3;
      }
      

    .block-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        .search-bar {
            display: flex;
            align-items: center;
            border: 1px solid #E4151B;
            /* border: 4px solid;
            border-image-slice: 1;
            border-width: 5px;
            border-image-source: linear-gradient(90deg, #172A66 0%, #6376B2 100%); */
            border-radius: 6px;
            overflow: hidden;
            max-width: 500px;
            width: 100%;
            position: relative;
            background-color: #F2F2F2;
        }

        .search-bar input {
            border: none;
            border-radius: 6px;
            width: 100%;
            padding: 0 5px;
        }

        /* .search-bar:focus {
            box-shadow: none;
            
        } */

        .search-bar button {
            border: none;
            border-radius: 6px;
            padding: 0px 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 15px;
            font-weight: 700;
        }
    }

    .block-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        flex-wrap: wrap;
        .remark-textarea-block{
            display: flex;
            flex-wrap: wrap;
            gap: 0.4em;
            max-width: 500px;
            border-radius: 6px;
            padding: 10px 6px;
        }
        .added-data {
            padding: 2px 6px;
            border: 2px solid #213C9233;
            /* background-color: #213C92; */
            background: #ffffff;
            /* border-radius: 6px; */
            position: relative;
            border-radius: 12px;

            .added-data-text {
                font-size: 16px;
                font-weight: 700;
                /* color: #ffffff; */
                color: #363636;
            }

            .corss-icon {
                /* height: 30px; */
                height: 20px;

                position: absolute;
                top: -10px;
                right: -15px;
                cursor: pointer;
            }
        }
    }

    .block-3 {
        align-self: center;

        .save-btn {
            border-radius: 6px;
            padding: 8px 35px;
            font-size: 18px;
            font-weight: 700;
        }
    }

    .block-4 {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: center;

        .suggested-text {
            margin-left: 15px;
        }

        .suggested-skills-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 0.4em;
            max-width: 500px;
            border: 1px solid #36363620;
            border-radius: 6px;
            padding: 10px 6px;
            height: auto;
            max-height: 200px;
            overflow: auto;
        }

        .suggested-skill {
            background-color: #888888;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
        }
    }
  }
  .competencyText{
    font-weight: 700;
    font-size: 24px;
    color: rgba(60, 60, 60, 1);
  }
  .ratingsText{
    font-weight: 700;
    font-size: 100px;
    /* color: rgba(33, 60, 146, 1); */
    color: #E4151B
  }
  .remarksText{
    font-weight: 700;
    font-size: 18px;
    color: rgba(60, 60, 60, 1);
    margin-bottom: 30px;
  }
  .suggestions-dropdown {
    position: absolute;
    top: 100%; 
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  .suggestions-dropdown {
    display: flex;
    max-height: 200px;
    border-radius: 4px;
    overflow-y: auto;
    max-width: 500px;
    width: 100%;
    position: relative;
    background-color: White;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .suggestion-item {
    padding: 2px 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
 
  
  .tooltip {
    visibility: hidden; 
    font-size: 13px;
    width: 5px; 
    color:black;
    text-align: center; 
    /* border-radius: 5px;  */
    /* padding: 5px; */
    position: absolute;
    z-index: 1;
    right: -20%;
    top: 0%;
    transform: translateY(-95%);
    opacity: 0;
    transition: opacity 0.3s;
    background: none; /* Remove background */
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
  }


  
  .tooltip-container:hover .tooltip {
    visibility: visible; 
    opacity: 1; 
  }
  
  /* .tooltip-container {
  position: relative;
  display: inline-block;
} */
