
.about-main-container {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #3636361A;
  border-radius: 12px;
  padding: 10px 15px;
  background: #FAF5EFCC;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    gap: 0.8em;
  }
  
  .about-title {
    color: #363636;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
  
  .about-content img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .about-description {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  
  