.zig-zag-section {
  display: flex;
  justify-content: center;
  align-items: center;
  .zig-zag {
    transform: rotate(45deg);
    .zig-zag-box {
      height: 20px;
      width: 20px;
      background: #D9D9D9;
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 5px;
    }
    .selected-zig-zag-box{
        box-shadow: 0px 4px 4px 0px #00000040;
        height: 20px;
        width: 20px;
        background: #E4151B;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 5px;

    }
  }
}

.content-section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .heading-text{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #363636;
        margin-bottom: 20px;
          
    }
    .btn-position{
        text-align: center;
        margin-top: 30px;
        button{
            padding: 7px 60px !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            box-shadow: none !important;
           
        }
        .find-btn{
            color: #E4151B;
            border: 1px solid #E4151B;
            padding: 7px 20px !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            box-shadow: none !important;
            border-radius: 7px;
            
        }
    }
    .text-area-input{
        text-align: center;
        textarea{
            border: 2px solid #E4151B;
            border-radius: 12px;
            max-width: 400px;
            width: 100%;
            min-height: 150px;
            font-weight: 600;
            color: #000000;
        }
    }

    .aime-form-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2em;

        .form-header{
            .heading{
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                color: #363636;
            }
        }

        .form-body{
            .group-btn{
                border-radius: 5px !important;
                border: 1px solid #E4151B !important;
                font-size: 18px !important;
                font-weight: 500 !important;
                color: #E4151B !important;
                padding: 7px 50px !important;
                background-color: #FFFFFF !important;
                transition: background-color 0.3s ease, color 0.3s ease;
            }

            .group-btn:hover{
                color: #FFFFFF !important;
                background-color: #E4151B !important;
            }

            .group-btn.active{
                color: #FFFFFF !important;
                background-color: #E4151B !important;
            }
        }

        .form-footer{
            .button-section{
                .next-btn{
                    padding: 7px 60px !important;
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    box-shadow: none !important;
                }
                .skipBtn{
                    font-weight: 700;
                    font-size: 18px !important;
                    text-align: center;
                }
            }
        }
    }
}
.sub-text{
font-size: 20px;
font-weight: 400;
text-align: center;
}
.input-section{
    display: flex;
    justify-content: center;
    align-items: center;
    input{
        border-radius: 12px;
        border: 2px solid #363636;
        font-size: 18px;
        text-align: center;
    }
    select{
        border: 0 !important;
        color: #E4151B;
        font-size: 18px;
        background: transparent;
        :focus-visible {
            outline: #363636 auto 0px !important;
        }
    }
}
.info-icon-alignment{
    position: relative;
    img{
        position: absolute;
        width: 30px;
        height: 30px;
        cursor: pointer;
        right: 0;
    }
}