.application-list-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0 10px;

    .section-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            font-size: 24px;
            font-weight: 600;
            color: #E4151B;
        }

        .new-application-btn {
            background-color: #E4151B;
            /* border: 2px dashed #E4151B; */
            color: #FFFFFF;
            padding: 10px 20px;
            border-radius: 8px;
            font-size: 16px;
            transition: background-color 0.3s ease;
        }

        .new-application-btn:hover{
            background-color: #E4151B;
            color: #FFFFFF;
        }

    }

    .section-2{
        /* align-self: flex-end; */
        margin-right: 20px;

        .radio-button-block{
            display: flex;
            align-items: center;
            gap: 1em;

            .radio-btn{
                margin: 0;
            }
        }
    }

    .section-3{
        overflow: visible;

        .customTable {
            width: 100%;
            border-collapse: separate; 
            border-spacing: 0;
            font-size: 16px;
            text-align: left;
            border-radius: 12px;
            overflow: hidden; 
            border: 1px solid #3636361A;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #FAF5EFCC;
    
    
        }
        
        .customTable thead th {
            background: #F2E7DE;
            color: #363636;
            font-size: 14px;
            font-weight: 600;
            border-right: 1px solid #36363633;
            border-left: 1px solid #36363633;
    
        }
        
        .customTable thead th:first-child {
            border-top-left-radius: 12px; 
        }
        
        .customTable thead th:last-child {
            border-top-right-radius: 12px;
        }
        
        .customTable tbody tr:last-child td:first-child {
            border-bottom-left-radius: 12px; 
        }
        
        .customTable tbody tr:last-child td:last-child {
            border-bottom-right-radius: 12px; 
        }
        
        .customTable tbody tr {
            border-bottom: 1px solid #dddddd;
        }
        
        .customTable tbody tr:nth-of-type(even) {
            background: #FAF5EFCC;
    
        }
        
        .customTable tbody tr:last-of-type {
            border-bottom: 2px solid #36363633;
        }
        
        .customTable tbody td {
            border-left: 1px solid #36363633;
            text-align: center;
            font-size: 16px;
            vertical-align: middle;
            background: #FAF5EFCC;
            font-weight: 400;
    
        }
        
        .customTable tbody tr:hover {
            background: #FAF5EFCC;
    
        }
        
        .customTable tbody td a:hover {
            text-decoration: underline;
        }
        
        
        .customTable tbody td:last-of-type {
            border-right: none;
        }
        .customTable th {
            text-align: center; 
            vertical-align: middle; 
        }
        
        .thAndIconContainer {
            display: flex;
            justify-content: center; 
            align-items: center; 
        }
        
        .columnText {
            margin-right: 8px; 
            white-space: noWrap;
        }
        .icon{
            cursor: pointer;
            height: 30px;
        }
        .actionBtn{
            cursor: pointer;
            text-decoration: underline;
            color: #E4151B;
        
        }
        .positionRelative{
            position: absolute;
            right: 0;
        }
        .popContainer{
            position: absolute;
            padding: 10px;
            right: 31px;
            bottom: 0px;
            z-index: 99;
            border: 1px solid #36363620;
            border-radius: 12px;
            background: #FFFFFF;
            display: grid;
            gap: 1em;
        }
        .customTable tbody tr:last-child td:last-child .popContainer {
            bottom: 15px ;
            border-radius: 12px 12px 0px 12px;
        }
        .btnText{
            text-wrap: nowrap;
            font-weight: 500;
            font-size: 16px;
            padding-left: 10px;
        }
        .IConAndText{
            display: flex;
            cursor: pointer;
        }
    }
}
.searchModalBody{
padding: 30px;
.searchModalHeader{
    display: flex;
    justify-content: space-between;
    .searchModalTitle{
        font-weight: 600;
        font-size: 24px;
        color: #E4151B;
    }       
        .searchModalCroseIcon{
            cursor: pointer;
        }
        }
.searchModalContainer{
    .searchModalCurrentIntake{
        background-color: #e9e9e9;
        border-radius: 12px;
        padding: 20px;
        margin-top: 20px;
        .searchModalCurrentTitle{
            font-weight: 600;
            font-size: 20px;
            color: grey;
        }
        .searchModalCurrentValue{
            font-weight: 600;
            font-size: 18px;
        }
    }
    .searchModalSelectInput{
            margin-top: 20px;
    }
    .searchModalFooter{
        display: flex;
        justify-content: center;
        .searchModalButtonSection{
            margin: 0;
            .buttonHeightWidth{
                /* height: 50px;
                width: 80px; */
                font-size: 18px;
            }

        }
    }
}
}
.new-application{
    display: flex;
    justify-content: space-between;
    align-items: center;
}