/* .main-container {
    height: 100vh; */

    .login-section {
        /* display: grid; */
        /* grid-template-columns: 2fr 1fr; */
        height: 100vh;
        background: #F9F6EF;

        .section-1 {
            background-color: #E82529;
            overflow: hidden;

            .login-image {
                width: 100%;
                max-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .row_hieght{
            height: auto;
            align-items: center;
            background: #F9F6EF;
            padding-left: 10%;
            padding-right:  10%;
            .col_img {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2em;
                /* background: #E4151B; */
                background: #F9F6EF;
                padding: 0 5% 0 0%;
                /* .left-img {
                  width: 100%;
                } */
          
                .left-block-header{
                  display: flex;
                  flex-direction: column;
                  gap: 1em;
          
                  .heading{
                    font-size: 36px;
                    font-weight: 600;
                    color: #e8252a;
                  }
          
                  .sub-heading{
                    font-size: 18px;
                    font-weight: 500;
                    color: #565656;
                  }
          
                  .description{
                    font-size: 15px;
                    font-weight: 400;
                    color: #565656;
                  }
                }
          
                .left-block-cards{
                  display: grid;
                  grid-template-columns: repeat(2, minmax(0, 1fr));
                  gap: 1em;
                  padding: 0 12%;
          
                  .cards{
                    padding: 10px;
                    border: 1px solid;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1em;
          
                    .card-detail-text{
                      text-align: center;
                      font-size: 14px;
                    }
                  }
                }
              }
        }
    

        .section-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            /* padding: 30px; */
            /* height: 100%; */
            vertical-align: middle;
            background: #FFFFFF;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(242, 242, 242, 0.95) 100%);
            box-shadow: 0px 10px 20px 0px rgba(131, 131, 131, 0.21960784);
            border-radius: 15px;
            padding: 20px 30px;
            /* margin: 20px; */
            margin-top: 100px;


            .login-card {
                max-width: 400px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 3em;

                .section-2_heading {
                    align-self: center;
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    margin-top: 10px;

                    h1{
                        font-size: 32px;
                        font-weight: 700;
                        color: #000000;
                        line-height: 1 !important;
                        margin: 0 !important;
                    }
                }

                .section-2_form {
                    .login-form-block {
                        display: flex;
                        flex-direction: column;
                        gap: 1.5em;      
                              /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(242, 242, 242, 0.95) 100%);
                        box-shadow: 0px 10px 20px 0px rgba(131, 131, 131, 0.21960784);
                        border-radius: 10px;
                        padding: 20px 30px; */

                        .block-1 {
                            display: flex;
                            flex-direction: column;
                            gap: 1.5em;

                            .label {
                                font-size: 14px;
                                font-weight: 700 !important;
                                color: #3C3C3C;
                                margin: 0;
                            }

                            .form-group{
                                display: flex;
                                flex-direction: column;
                            }

                            .input-with-icon {
                                position: relative;
                                display: flex;

                                .input-with-icon input {
                                    padding-right: 30px;
                                }

                            }

                            .forgot-password-text{
                                font-size: 14px !important;
                                font-weight: 600 !important;
                                margin: 5px 0;
                                text-align: end;

                                a{
                                    color: #3C3C3C !important;
                                    text-decoration-thickness: from-font !important;
                                }
                                a:hover{
                                    color: #E82529 !important;
                                    text-decoration-thickness: from-font !important;
                                }
                            }

                            .login-form-btn {
                                display: flex;
                                flex-direction: column;
                                gap: 1.5em;

                                .login-btn {
                                    width: 100%;
                                    border: 1px solid rgba(0, 90, 190, 1);
                                    color: #fff;
                                    border-radius: 5px;
                                    padding: 7px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    background: rgba(0, 90, 190, 1);
                                }

                                .login-btn:disabled {
                                    background-color: rgba(39, 39, 42, .9);
                                    color: #fff;
                                }

                                .login-guest-btn {
                                    width: 100%;
                                    border: 1px solid rgba(0, 90, 190, 1);
                                    color: rgba(0, 90, 190, 1);
                                    border-radius: 5px;
                                    padding: 7px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    background: #fff;
                                }

                                .login-guest-btn:disabled {
                                    background-color: rgba(39, 39, 42, .9);
                                    color: #fff;
                                }
                            }
                        }

                        .block-2 {
                            display: flex;
                            flex-direction: column;
                            gap: 0.5em;

                            .other-sign-in-option-text{
                                font-size: 16px;
                                font-weight: 600;
                                color: #3C3C3C;
                            }

                            .login-google-btn {
                                width: 100%;
                                border: 1px solid #DB4437;
                                color: #ffffff;
                                border-radius: 10px;
                                padding: 7px 15px;
                                font-size: 14px;
                                font-weight: 600;
                                background: #DB4437;
                                display: flex;
                                /* justify-content: space-between; */
                                align-items: center;
                                gap: 6em;
                                .img{
                                    height: 20px;
                                    width: 20px;
                                }
                            }

                            .login-facebook-btn {
                                width: 100%;
                                border: 1px solid #1877F2;
                                color: #ffffff;
                                border-radius: 10px;
                                padding: 7px 15px;
                                font-size: 14px;
                                font-weight: 600;
                                background: #1877F2;
                                display: flex;
                                /* justify-content: space-between; */
                                align-items: center;
                                gap: 6em;
                                .img{
                                    height: 20px;
                                    width: 20px;
                                }
                            }

                            .login-apple-btn {
                                width: 100%;
                                border: 1px solid #474747;
                                color: #ffffff;
                                border-radius: 10px;
                                padding: 7px 15px;
                                font-size: 14px;
                                font-weight: 600;
                                background: #474747;
                                display: flex;
                                /* justify-content: space-between; */
                                align-items: center;
                                gap: 6em;
                                .img{
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                        }

                        .block-3{
                            font-size: 20px;
                            font-weight: 600;
                            align-self: center;
                            
                            a{
                                color: #3C3C3C !important;
                                text-decoration-thickness: from-font !important;
                            }
                            a:hover{
                                color: #E82529 !important;
                                text-decoration-thickness: from-font !important;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .icon-container {
        position: absolute;
        font-size: 17px;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        cursor: pointer;

        .eyeicon {
            font-size: 15px;
        }
    }
/* } */

.form-group{
    font-weight: 700 !important;
}

.white-back{
    background-color: #FFFFFF;
}


@media screen and (min-width: 992px) and (max-width: 1200px){
.row_hieght{
    padding-left: 3% !important;
    padding-right: 3% !important;
}
}


@media screen and (max-width: 991px){
   .row_hieght{
    padding-left: 3% !important;
    padding-right: 3%  !important;
    height: max-content !important;
   }
}


@media screen and (max-width:767px){
    .login-section{
        .section-2{
            padding: 30px 10px;
        }
    }
    .row_hieght{
        height: max-content !important;
    }
}

.logo-align{
    background: #F9F6EF;
    padding: 20px 10% 0;
}
.col-color{
  background-color:  #F9F6EF;
}