.card {

    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  
  .card_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profileImage {
    height: 70px;
     width: 70px;
     border-radius: 50%; 
     box-shadow: 0px 6.02px 6.02px 0px #00000040;
  }
  .card_info {
    .name {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 5px;
      cursor: pointer;
  
      img{
        position: relative;
        left: 10px;
        bottom: 4px;
      }
    }
    .nameLink {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
    .name:hover{
      text-decoration: underline #213c92;
    }
  
    .connectionUser-name{
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
    }
    .connectionUser-name:hover{
     color: #E82529;
    }
    .description {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px; /* Adjust the width as needed */
        cursor: pointer;
      }
}
.imag-text{
    display: flex;
    align-items: center;
    gap: 10px;
}

.dismis{
    border: none;
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    margin-right: 10px;
}
.primary-button-1{
  margin-right: 10px;
}