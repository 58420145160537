.msm-header{
  display: flex;
  flex-direction: column;
  gap: 2px;

  .top-header-container {
    background-color: #F2E7DE;
    padding: 7px;
    box-shadow: 0px 2px 2px 0px #0000001A;
  
    .top-header-block{
      display: grid;
      grid-template-columns: 3fr 9fr;
      gap: 1em;
      width: 100%;
      align-items: center;
  
      .search-bar {
        display: flex;
        align-items: center;
        max-width: 300px;
        width: 100%;
        position: relative;
        background-color: transparent;
      }
    
      .search-bar input {
        border: none;
        border-bottom: 2px solid #E4151B;
        padding: 7px;
        width: 100%;
        background-color: transparent;
        font-size: 14px;
        outline: none;
        transition: border-color 0.3s ease;
      }
    
      .search-bar input:focus {
        border-bottom: 2px solid #E4151B;
        outline: none;
      }
    
      .search-bar button {
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    
      .search-bar img {
        height: 20px;
        width: 20px;
      }
    }
  }
  
  .header-container {
    padding: 8px 20px;
    box-shadow: 0px 2px 2px 0px #0000001A;
    background: #FFFFFF;
  
    .header-block {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
  
      .header-left {
        justify-self: start;
        padding-left: 7%;

        .logo {
          cursor: pointer;
          height: 40px;
        }
      }
  
      .header-center {
        justify-self: center;

        .nav-icons{
          display: flex;
          justify-content: center;
          gap: 2.5em;

          .nav-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 0.1em;
            cursor: pointer;
            position: relative;
    
            .nav-item-logo {
              height: 18px;
              width: 20px;
              position: relative;
            }
           
            .nav-item-detail {
              font-size: 12px;
              font-weight: 600;
              color: #000000;
              white-space: noWrap;
            }

            .active{
              color: #E4151B;
            }
          }

          .hamburger {
            position: relative;

            .dropdown-menu {
              position: absolute;
              top: 50px;
              right: 0;
              background: #FFFFFF;
              border: 1px solid #E4151B;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
              padding: 10px;
              z-index: 10;
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }
            
            .dropdown-item {
              display: flex;
              align-items: center;
              gap: 1em;
              cursor: pointer;
              padding: 8px;
            }
            
            .dropdown-item:hover {
              background-color: #FAF5EFCC;
              border-radius: 4px;
            }
          }
    
          .nav-item-position-relative {
            position: relative;
          }
    
          .ToolBtn {
            border-radius: 12px 0px 12px 12px;
            border: 2px solid rgba(23, 42, 102, 1);
            background-color: rgba(250, 250, 250, 1);
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: -155px;
            right: -18px;
            box-shadow: 0px 2.34px 2.34px 0px rgba(0, 0, 0, 0.25);
            z-index: 10;
            padding: 0px 5px 0px 5px;
          }
    
          .ToolBtnItem {
            color: rgba(0, 0, 0, 1);
            font-size: 15px;
            text-align: right;
            text-wrap: nowrap;
            font-weight: 600;
            padding: 7px 3px 6px 30px;
            cursor: pointer;
          }

          .message-count{
            position: absolute;
            top: -8px; 
            right: 6px; 
            width: 20px;
            height: 20px;
            border-radius: 50%; 
            background-color: #E4151B; 
            color: white;
            display: flex; 
            justify-content: center; 
            align-items: center;
            font-size: 12px;
            font-weight: bold; 
        }
    
          .brokenLine {
            width: 100%;
            border: 1px solid rgba(23, 42, 102, 1);
            border-radius: 12px;
          }
        }
      }
  
      .header-right {
        display: flex;
        align-items: center;
        justify-self: end;
        gap: 10px;

        .nav-item {
          cursor: pointer;
  
          .nav-item-logo {
            height: 18px;
            width: 20px;
          }
        }
  
        .user-details-divider {
          height: 50px;
          width: 2px;
          background-color: #F2E7DE;
          margin: 0 1em;
          display: block;
        }
  
        .Profile-drop-down {
          display: flex;
          align-items: center;
          gap: 1em;
        }
  
        .profile-placeholder {
          border-radius: 50%;
          cursor: pointer;
          height: 40px;
          box-shadow: 0px 6.02px 6.02px 0px #00000040;
          width: 40px;
        }
  
        .drop-down-icon {
          cursor: pointer;
        }
  
        .open-dropdown {
          padding: 0 20px 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.4em
        }
  
        .dropdown-username {
          font-size: 15px;
          font-weight: 500;
          color: black;
          margin: 0 !important;
        }
  
        .dropdown-image-logo {
          width: 65px;
          height: 65px;
          border-radius: 50%;
          cursor: pointer;
        }
  
        .dropdown-item-text {
          font-size: 18px;
          color: #363636;
          cursor: pointer;
          font-weight: 600;
        }
        .dropdown-item-text:hover {
          font-size: 18px;
          color: #E4151B;
          cursor: pointer;
          font-weight: 600;
        }
  
        .dropDownProfile {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 8rem;
          right: 1rem;
          max-width: 200px;
          padding: 15px;
          border-radius: 8px;
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid gray;
          z-index: 1;
        }
  
        .dropDownProfile::before {
          content: '';
          position: absolute;
          top: -0.5rem;
          right: 1rem;
          width: 15px;
          height: 15px;
          transform: rotate(45deg);
          background-color: rgba(255, 255, 255, 1);
          border-left: 1px solid gray;
          border-top: 1px solid gray;
        }
  
        .drop-down-items {
          display: flex;
          flex-direction: column;
          gap: 0.8em;
          padding: 0;
          margin: 0;
          list-style-type: none;
        }
      }
  
    }
  }
}

.notify-count{
    position: absolute;
    top: -8px; 
    right: -8px; 
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background-color: #E4151B; 
    color: white;
    display: flex; 
    justify-content: center; 
    align-items: center;
    font-size: 12px;
    font-weight: bold; 
}

             


.nav-item-logo-active {
  height: 18px;
  width: 20px;
  position: relative;
  transform: rotate(30deg);

}

.searchInputValue{
  position: absolute;
  background: #FFF;
  border: 1px solid #213C92;;
  padding: 10px 20px;
  border-radius:12px;
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  width: -webkit-fill-available;
  z-index: 99;
}

.dropDown-aligment{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;


}
.dropdown-text{
  font-size: 20px;
  font-weight: 600;
  color: #3C3C3C;
}
.div-width{
  width: 100%;
  height: 35px;
}


.additionalclass{
  position: relative;

}

.dropdown-notify{
  position: absolute;
  top: 50px;
  right: 0px;
}
.massage-position{
width: fit-content;
}
.remove-line{
  text-decoration: none !important;
  text-align: center;
}
.msm-logo{
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.blinktext{
  animation: blink 1s linear infinite;
  color: #E4151B;
  font-size: 12px;
  position: absolute;
  right: -35px;
  top: -10px;
}
@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}


.tooltip:hover::after {
  content: 'Launching Soon';
  position: absolute;
  left: 28px;
  transform: translateY(-100%);
  font-size: 12px;
  color: #3C3C3C;
  /* margin-top: -30px; */
  font-weight: 300;
  white-space: noWrap;
  top: 5px;
}

.Searchtooltip[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  transform: translateY(-100%);
  font-size: 12px;
  color: #3C3C3C;
  font-weight: 300;
  white-space: nowrap;
  /* margin-left: 300px; 
  margin-top: -25px; */
  right: -80px;
}