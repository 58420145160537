.validation-modal-block {
    .validation-modal-header {
        padding: 0px 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .cross-icon {
            cursor: pointer;
        }
    }

    .validation-modal-body {
        padding: 15px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .validation-modal-body-icon{
            border: 3px solid #000000;
            border-radius: 50%;
            padding: 15px;
        }

        .validation-modal-alert-text {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.23999999463558197px;
            color: #E82529;
        }

        .validation-modal-hint{
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.23999999463558197px;
            color: #3C3C3C;
            margin-top: 5px;
            margin-bottom: 30px;
            padding: 0px 40px;
            text-align: center;
        }
    }
}