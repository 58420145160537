/* Base styles */
.notification {
  width: 100%;
  border-radius: 8.72px;
  border: 1px solid #ffffff;
  font-family: 'Poppins';
  font-size: 14.54px;
  font-weight: 700;
  box-shadow: 0px 2.91px 2.91px 0px #00000040;
  line-height: 19.8px;
  letter-spacing: -0.174px;
  text-align: left;
}

.notificationHeader {
  background-color: #2c4e92;
  color: white;
  border-radius: 8.72px 8.72px 0 0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationHeader span {
  font-weight: bold;
}

.groupIcon {
  width: 8.72px;
  height: 8.72px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}

.viewMore {
  color: white;
  text-decoration: none;
}

.notificationList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

.notificationList li {
  display: flex; 
  align-items: center; 
  padding: 8px;
  border-bottom: 1px solid #ffffff;
}

.notificationList li:last-child {
  border-bottom: none; 
}

.notificationText {
  font-family: 'Poppins';
  font-size: 11.63px;
  font-weight: 400;
  line-height: 15.84px;
  letter-spacing: -0.174px;
  text-align: left;
  flex-grow: 1; 
}

/* Responsive styles */
@media (max-width: 768px) {
  .notification {
    font-size: 12px;
    line-height: 16px;
  }

  .notificationHeader {
    padding: 6px;
  }

  .groupIcon {
    width: 7px;
    height: 7px;
    margin-right: 8px;
  }

  .notificationText {
    font-size: 10px;
    line-height: 14px;
  }
}

@media (max-width: 480px) {
  .notification {
    font-size: 10px;
    line-height: 14px;
  }

  .notificationHeader {
    padding: 4px;
    font-size: 12px;
  }

  .groupIcon {
    width: 5px;
    height: 5px;
    margin-right: 6px;
  }

  .notificationText {
    font-size: 8px;
    line-height: 12px;
  }
}
