
.group_tabs_container {
    /* background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px; */
    /* padding: 0 !important;
    margin-top: 25px; */
  }
  
  .info_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px ;
    margin-bottom: 10px;
  
    .info_title {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      display: flex;
      gap: 0.8em;
      align-items: center;
    }
  
    .info_description {
      font-size: 16px;
      font-weight: 300;
      text-align: left;
      color: #363636;
    }
  
    .edit_icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  
    .invite_button {
      font-size: 16px;
      font-weight: 500;
      padding: 7px 15px;
      background-color: #E4151B;
      color: #fff;
      border: none;
      border-radius: 12px;
      cursor: pointer;
    }
  }
  
  .nav_buttons {
    padding: 0px 20px;

    position: relative;

      .moderator-count{
        position: absolute;
        top: -8px;
        font-size: 16px;
        width: 25px;
        height: 25px;
        text-align: center;
        border-radius: 50%;
        color: #E4151B;
        border: 2px solid #E4151B;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    button {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      background: none;
      color: #363636;
      cursor: pointer;
      border: none;
      padding: 5px 10px;
      border-bottom: 1px solid transparent;
    }
  
    /* button:hover {
      background-color: #fff;
      color: #363636;
      font-size: 18px;
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid #E4151B;
    } */
  
    .active_button {
      background-color: #fff;
      color: #363636;
      font-size: 18px;
      border-bottom: 1px solid #E4151B;
      /* border-radius: 12px 12px 0px 0px; */
    }
  }
  