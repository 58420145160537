.upload-document-modal-block {
    .upload-document-modal-header {
        padding: 10px 10px;
        background-color: #E82529;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px 8px 0 0;

        .heading {
            font-size: 24px;
            font-weight: 700;
            line-height: 43.58px;
            letter-spacing: -0.23999999463558197px;
            color: #FFFFFF;
        }

        .cross-icon {
            cursor: pointer;
        }
    }

    .upload-document-modal-body {
        padding: 20px 30px;

        .upload-document-section {
            gap: 1em;

            .document-name-field {
                /* border: 2px solid #E82529; */
                /* border-radius: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #363636;
                letter-spacing: -0.23999999463558197px; */
            }

            .file-input{
                display: none;
            }

            .select-file-btn {
              box-shadow: 0px 4px 4px 0px #00000040;
              background-color: #FFFFFF;
              padding: 7px 15px;
              border: 1px solid #E82529;
              border-radius: 10px;
              font-size: 16px;
              font-weight: 500;
              /* letter-spacing: -0.23999999463558197px; 
               line-height: 1;*/
            }

            .upload-file-btn {
                box-shadow: 0px 4px 4px 0px #00000040;
                padding: 7px 15px;
                border: 1px solid #E82529;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 500;

            }

            .upload-file-btn:disabled {
              padding: 7px 15px !important;
              border: 1px solid #888888;
              border-radius: 10px !important;
              font-size: 16px !important;
              font-weight: 500 !important;
              /* letter-spacing: -0.23999999463558197px; */
              /* line-height: 1;*/
            }
        }

        .upload-document-table {
          .customTable {
            width: 100%;
            border-collapse: separate; 
            border-spacing: 0;
            font-size: 16px;
            text-align: left;
            border-radius: 12px;
            overflow: hidden; 
            border: 1px solid #3636361A;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #FAF5EFCC;
    
    
        }
        
        .customTable thead th {
            background: #F2E7DE;
            color: #363636;
            font-size: 14px;
            font-weight: 600;
            border-right: 1px solid #36363633;
            border-left: 1px solid #36363633;
    
        }
        
        .customTable thead th:first-child {
            border-top-left-radius: 12px; 
        }
        
        .customTable thead th:last-child {
            border-top-right-radius: 12px;
        }
        
        .customTable tbody tr:last-child td:first-child {
            border-bottom-left-radius: 12px; 
        }
        
        .customTable tbody tr:last-child td:last-child {
            border-bottom-right-radius: 12px; 
        }
        
        .customTable tbody tr {
            border-bottom: 1px solid #dddddd;
        }
        
        .customTable tbody tr:nth-of-type(even) {
            background: #FAF5EFCC;
    
        }
        
        .customTable tbody tr:last-of-type {
            border-bottom: 2px solid #36363633;
        }
        
        .customTable tbody td {
            border-left: 1px solid #36363633;
            text-align: center;
            font-size: 16px;
            vertical-align: middle;
            background: #FAF5EFCC;
            font-weight: 400;
    
        }
        
        .customTable tbody tr:hover {
            background: #FAF5EFCC;
    
        }
        
        .customTable tbody td a:hover {
            text-decoration: underline;
        }
        
        
        .customTable tbody td:last-of-type {
            border-right: none;
        }
        .customTable th {
            text-align: center; 
            vertical-align: middle; 
        }
        
        .thAndIconContainer {
            display: flex;
            justify-content: center; 
            align-items: center; 
        }
        
        .columnText {
            margin-right: 8px;
            white-space: noWrap; 
        }
            .action-column{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
            }
        }

        .submit-document-button-block {
            text-align: center;
            margin-top: 10px;

            .submit-btn {
                box-shadow: 0px 4px 4px 0px #00000040;
                border: 1px solid #E82529;
                border-radius: 10px;
                padding: 7px 15px;
                font-size: 16px;
                font-weight: 500;
                
            }
        }
    }
}

.container {
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin: 0px;
    background-color: white;
    padding: 10px;

  .ModelContainer{
    .title {
        font-size: 20px !important;
        font-weight: 600;
        color: #363636;
        /* text-align: center; */
        margin-bottom: 10px;
    }
    .headerContainer {
    border-bottom: 0 !important;
    text-align: right;
    .corssIcon {
        height: 48px;
        cursor: pointer;
    }
  }
  .btnContainer{
    display: flex;
    padding: 25px;
    justify-content: center;
    gap: 2em;
    .btnUpload{
        border-radius: 12px;
        border: 1px solid #E82529;
        color: #F2F2F2;
        background-color: #E82529;
      }
      .btnUpload:hover{
        background: #F2F2F2;
        color: #E82529;
    }
    button{
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 10px 30px;
    }
}
  .uploadBoxContainer{
    border: 1px solid black;
    padding: 20px;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    border-radius: 12px;
  }
  }
  .customTable {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0;
    font-size: 16px;
    text-align: left;
    border-radius: 12px;
    overflow: hidden; 
    border: 1px solid #3636361A;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FAF5EFCC;


}

.customTable thead th {
    background: #F2E7DE;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    border-right: 1px solid #36363633;
    border-left: 1px solid #36363633;

}

.customTable thead th:first-child {
    border-top-left-radius: 12px; 
}

.customTable thead th:last-child {
    border-top-right-radius: 12px;
}

.customTable tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px; 
}

.customTable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px; 
}

.customTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.customTable tbody tr:nth-of-type(even) {
    background: #FAF5EFCC;

}

.customTable tbody tr:last-of-type {
    border-bottom: 2px solid #36363633;
}

.customTable tbody td {
    border-left: 1px solid #36363633;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    background: #FAF5EFCC;
    font-weight: 400;

}

.customTable tbody tr:hover {
    background: #FAF5EFCC;

}

.customTable tbody td a:hover {
    text-decoration: underline;
}


.customTable tbody td:last-of-type {
    border-right: none;
}
.customTable th {
    text-align: center; 
    vertical-align: middle; 
}

.thAndIconContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.columnText {
    margin-right: 8px; 
    white-space: noWrap;
}
  .actionBtn {
    color: #E82529;
    cursor: pointer;
    margin-right: 10px;
  }
  .centerIcon{
    vertical-align: middle;
    text-align: center;
  }
  .uploadBtn{
    background-color:#E82529;
    border-radius: 12px;
    padding: 10px;
    /* margin-top: 10px !important; */
    color: white;
  }
}