.address {
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px;
}
.heading {
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 1em;
  /* margin-top: 10px; */
  p {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #363636;
    margin-left: 20px;
  }
}
Form.Label{
font-size: 16px;
font-weight: 600;
color: #363636 !important;
}

.checkbox-container{
  display: flex;
  align-items: center;
  gap: 0.3em;
  margin-bottom: 6px;

  .checkbox-form{
    line-height: 0;

    input[type="checkbox" i] {
      font-size: 12px;
      width: 20px;
      height: 20px;
      margin-top: 0;
      border: 2px solid #E4151B;
      /* background-color: #E4151B; */
      color: #ffffff;
    }
  }
}

.option-default{
  display: none;
}

.extrik{
  color: #E4151B;
}
.errorText{
  font-weight: 400;
  font-size: 12px;
  color: #E4151B;
}