.programDetails_main_container {
  background-color: #fff;
}

/* left sectoion css */
.programDetails_left_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* section 1 */
.programDetails_section1 {
  padding: 10px 0px;
  .profileCard {
    display: flex;
    align-items: flex-start;
    gap: 1em;
    border-radius: 12px;
  }

  .card_coverImage {
    width: 80px;
    height: 80px;
    border-radius: 12px;
  }

  .card_info .name {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
  .card_info .description {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
  }
}

/* section 2 */
.programDetails_section2 {
  .block_content {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .block_topic {
    font-size: 22px;
    font-weight: 700;
  }

  .block_more_info {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    color: #E4151B;
    cursor: pointer;
  }
}

/* section 3 */
/* only backgorund withour content */
.programDetails_section3 {
  .main-row {
    height: 400px;
    background-color: #FBF7F2;;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customCol8_content {
    background-color: #fff;
    height: 384px;
  }

  .customCol2 {
    padding-left: 0;
  }

  .innerDiv {
    background-color: #fff;
    height: 120px;
    margin-bottom: 10px;
  }
}

/* section 4 and section 5 */
.programDetails_section4,
.programDetails_section5 {
  .program_description_content {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .program_title {
      font-size: 24px;
      font-weight: 600;
    }
    .program_description {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

/* section 6 */
.programDetails_section6 {
  .programDetails_similarPrograms_content {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
  }
  .programDetails_similarPrograms_content {
    .program_title {
      font-size: 24px;
      font-weight: 600;
    }
    .program_slider{
      padding: 20px 0px 0px 0px;
    }
  }
}

/* right section css */

.programDetails_right_section {
  .programDetails_panel-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-bottom: 20px;

    .compare-button button {
      font-weight: 600;
      font-size: 20px;
      color: #363636;
      border-radius: 12px;
      background-color: #FBF7F2;
      padding: 6px;
      border: 1px solid #E4151B;
    }
    .custom-underline {
      color: #E4151B;
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: #E4151B;
    }
    .compare-section {
      display: flex;
      margin: 12px;
      align-items: center;
      gap: 1em;

      .compare-number {
        font-size: 20px;
        font-weight: 600;
        color: #E4151B;
      }

      .favorite-icon{
        cursor: pointer;
      }
    }

    .panel-text-first {
      font-size: 20px;
      font-weight: 700;
      text-align: right;
    }

    .panel-text-second {
      font-size: 24px;
      font-weight: 400;
      text-align: right;
    }

    .apply-button {
      border-radius: 10px;
      background: #E4151B;
      box-shadow: 0px 4px 4px 0px #00000040;
      font-size:18px;
      font-weight: 600;
      color: #ffffff;
      border: 0;
      padding: 7px 40px;
    }
  }

  .main-content-heading {
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0px;
  }

  .information_section-container {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .info-key {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .info-value {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }

  .program_intake_section-container {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .program-intake-content {
    display: flex;
    justify-content: space-between;
  }

  .program-intake-key {
    font-size: 16px;
    font-weight: 700;
  }

  .program-intake-value {
    font-size: 16px;
    font-weight: 600;
  }

  .education_section-container {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .edu-key {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .edu-value {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }

  .language_section-container {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .language-content {
    display: flex;
    justify-content: space-between;
  }

  .language-key {
    font-size: 16px;
    font-weight: 700;
  }

  .language-value {
    font-size: 16px;
    font-weight: 600;
  }

  .ranking_section-container {
    background-color: #FBF7F2;
    padding: 10px;
    border-radius: 12px;
  }

  .ranking-title {
    font-size: 16px;
    font-weight: 700;
  }

  .ranking-description {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .ranking-item {
    text-align: left;
  }
}

.programDetails_container-part2 {
  margin-top: 20px !important;
  padding: 0px ;
  .section {
    height: 400px;
    background-color:#FBF7F2;;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
