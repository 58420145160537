.progress-container {
  max-width: none;
  border-radius: 12px;
  background-color: #FFFFFF;
  /* margin-bottom: 0; */
  /* margin-top: 0; */
  border: 1px solid #6376B2;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #172A66 0%, #6376B2 100%);
  padding: 8px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 2.91px 2.91px 0px #00000040;
}

.progress-header span,
.progress-header a {
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.progress-header a {
  font-weight: 600;
  font-size: 12px;
}

.progress-bar {
  margin-bottom: 8px;
  padding: 0 8px;
  position: relative; 
}

.progress-label {
  display: inline-block;
  font-weight: bold;
}

.progress-percent-container {
  display: flex;
  justify-content: center; }

.progress-percent {
  font-size: 8.72px;
  font-weight: 300;
  text-align: left;
  /* Adjust as needed */
  color: #3C3C3C; /* Adjust text color as needed */
}

.progress {
  background-color: #172A66;
  border-radius: 20px;
  height: 11.63px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  background-color: #E82529;
  border-radius: 5px;
  height: 100%; /* Full height of the progress bar */
  text-align: right;
  color: white;
  font-size: 10px;
  padding-right: 5px;
  box-sizing: border-box;
}

.labelName {
  font-size: 16px;
  font-weight: 700;
  color: #3C3C3C;
}

@media (max-width: 768px) {
  .progress-header {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .progress-header a {
    font-size: 10px;
  }

  .progress {
    height: 8px;
  }

  .progress-fill {
    font-size: 8px;
    padding-right: 3px;
  }
}
