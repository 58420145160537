.common-section {
  margin-top: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3 , 1fr); 
  column-gap: 1em;
  row-gap: 2em; 
}

.card {
  padding: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  width: 100%;
  height: 100px;
}

.name {
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin: 10px 10px 0px 10px;
}

.description {
  font-size: 10px;
  color: white;
  margin: 10px 10px 45px 10px;
}

.progress {
  width: 100%;
  height: 20px;
  background-color: rgba(23, 42, 102, 1);
  overflow: hidden;
  border: 2px solid rgba(0, 56, 93, 1);
  border-radius: 12px;
}

.progress-bar {
  height: 100%;
  background-color: rgba(232, 37, 41, 1);
  border-radius: 12px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr); 
  }
}

@media screen and (max-width: 767px) {
  .container {
    grid-template-columns: repeat(2, 1fr); 

  }

  .searchInput {
      width: fit-content;
  }
}