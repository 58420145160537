
.sidebar-container{
    width: 250px;
    background: #fff;
    margin-right: 10px;
    height: 100vh;

    .highlight{
        background: #213c92;
        color: #FFF !important;
        text-align: center;
        padding: 10px 0px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
    }
    .invactive:hover{
      background: #213c92;
      color: #FFF !important;
      text-align: center;
      padding: 10px 0px;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
    
    }
    
    .invactive{
      background: #FFF;
      /* color: #213c92 !important; */
      text-align: center;
      padding: 10px 0px;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
    
    }

}