.recentlyViewedContainer {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2.91px 2.91px 0px #00000040;


  margin-top: 20px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #172A66 0%, #6376B2 100%);
  padding: 8px;
  border-radius: 12px 12px 0 0;
  color: white;
  box-shadow: 0px 2.91px 2.91px 0px #00000040;
}

.header span {
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.header a {
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.items {
  display: flex;
  flex-direction: column;
  line-height: 10px;
}


.item {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-shrink: 0;
  align-items: center;
}

.details {
  flex: 1;
}

.title {
  display: block;
font-size: 12px;
font-weight: 600;
text-align: left;

  margin-bottom: 5px;
  color: #3C3C3C;
}

.description {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-top: 5px;
  
  color: #3C3C3C;
}

@media (max-width: 768px) {
  .header {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .header a {
    font-size: 10px;
  }

  .item {
    /* flex-direction: column; */
  align-items: left;
  /* text-align: center; */
    
  }

  .icon {
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .header {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .header a {
    font-size: 10px;
  }

  .item {
    flex-direction: column;
  align-items: left;
  /* text-align: center; */
    
  }

  .icon {
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }
}