
.group-btn{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    width: 300px !important; 
    max-width: 300px !important;
    border-radius: 5px;
    border: 1px solid #E4151B !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #E4151B !important;
    padding: 7px 25px !important;
    background-color: #FFFFFF !important;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.imgDropDown{

}
.buttonContainerFluid{
    display: flex;
    justify-content: space-between;
    column-gap: 2em;
}
.buttonContainer{
    width: 100%; 
}
.group-btn.active{
    color: #FFFFFF !important;
    background-color: #E4151B !important;
}

.index-number {
    font-weight: 600;
    color: #363636;
    margin-right: 25px;
    font-size: 20px ;
}
.optionAlign{
cursor: pointer;
background-color: white;
}
.countryDropDown{
    position: absolute;
    background: #FFF;
    border: 1px solid #36363650;
    padding: 10px 10px;
    border-radius:12px;
    margin-top: 5px;
    min-width: 160px;
    z-index: 99;
}
.countryContainer{
    position: relative;
}
.dropDown-aligment{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    justify-content: start;
    width: 300px; 
    max-width: 300px;
    border-radius: 5px;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #E4151B !important;
    padding: 2px 25px !important;
    background-color: #FFFFFF !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: background-color 0.3s ease, color 0.3s ease;
    .dropdown-text{
        color: #363636;
        font-weight: 500;
        text-align: center;
        text-wrap: nowrap;
    }
    .dropdown-text:hover{
        color: #E4151B;
        font-weight: 500;
        text-align: center;
        text-wrap: nowrap;
    }
}