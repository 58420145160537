.post-container {
  /* background: #ffffff; */
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #3636361A;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #FAF5EFCC;
}

.header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 20px;

  .header-left {
    display: flex;
    align-items: center;
    gap: 1.2em;

    .profile_picture {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      box-shadow: 0px 6.02px 6.02px 0px #00000040;
    }

    .user_info .user_name {
      font-size: 18px;
      font-weight: 600;
      color: #E4151B;
      text-transform: capitalize;
      line-height: 1.2;
      cursor: pointer;
    }
    .user_info .user_role {
      font-size: 14px;
      font-weight: 600;
      color: #363636;
      line-height: 1.2;
    }
  }

  .header-right {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .triple-dot {
      cursor: pointer;
      /* img {
        width: 30px;
        height: 30px;
      } */
    }
    .cross-icon {
      cursor: pointer;
      position: relative;
      top: -10px;
      left: 10px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    .MoreBtn {
      background-color: rgba(250, 250, 250, 1);
      position: absolute;
      /* bottom: -110px; */
      top: 45px;
      right: 0px;
      box-shadow: 0px 2.34px 2.34px 0px rgba(0, 0, 0, 0.25);
      z-index: 10;
      border-radius: 12px;
      padding: 5px;
    }

    .MoreBtnItem {
      display: flex;
      align-items: center;
      color: rgba(60, 60, 60, 1);
      font-size: 15px;
      text-align: left;
      font-weight: 400;
      padding: 7px 30px 6px 10px;
      cursor: pointer;
    }
    .icon {
      margin-right: 8px;
    }

    .icon img {
      display: block;
      width: 18px;
      height: 18px;
    }
    .brokenLine {
      width: 100%;
      /* border: 2px solid rgba(23, 42, 102, 1); */
      border-bottom: 1px solid #36363650;
      border-radius: 12px;
    }
  }
}



.content {
  margin: 0 20px;

  .post_text {
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    color: #363636;
    p {
      margin: 0;
      font-size: 16px;
      display: inline;
    }
    strong {
      font-size: 16px;
    }
  }

  .post_text_data{
    cursor: pointer;
    text-decoration: none;
    color: #E4151B;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
  }
  .post_text_data:hover{
    text-decoration: underline;
    color: #E4151B;
  }
}

/* .post-image {
  img {
    width: 100%;
    max-height: 500px;
    padding: 30px 0px;
  }
} */
.actions-data {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 5px 10px;
  border-bottom: 0.25px solid #3c3c3c26;
  .image-icon {
    margin-left: -12px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  padding: 5px 0px;
  /* border-top: 0.25px solid #3C3C3C; */
  /* border-bottom: 0.25px solid #3c3c3c26; */

  .action-block{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .action-count{
    font-size: 18px;
    font-weight: 500;
    color: #E4151B;
    cursor: pointer;
  }

  .action_button {
    border: none;
    /* padding: 10px 10px; */
    cursor: pointer;
    background-color: #FAF5EFCC;
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    display: flex;
    gap: 0.5em;
    align-items: center;

    img{
      margin-right: 5px;
    }
  }

  .activeLikeKeyword {
    color: #363636;;
  }
  /* .action_button:hover {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 12px;
  } */
  .action_button:disabled {
    background-color: #FAF5EFCC !important;
    border-radius: 12px !important;
    padding: 10px 15px !important;
    border: none !important;
    padding: 10px 15px;
    color: #E4151B !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex !important;
    gap: 0.5em;
    pointer-events: none !important;
  }
}

.add-comment-container {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .user-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .input-field {
    padding: 8px;
    border: 1px solid #E4151B;
    border-radius: 12px;
    width: 250px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
  }

  .comment-btn {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 12px;
    background-color: #E4151B;
    padding: 6px 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
  }
  .comment-btn:disabled {
    font-weight: 600 !important;
    /* font-size: 20px !important; */
    color: #fff !important;
    border-radius: 12px !important;
    background-color: #E4151B !important;
    /* padding: 10px 30px !important; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border: none !important;
  }
}

.comments_section {
  .comments_title {
    display: flex;
    justify-content: space-between;

    .text {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
    }
    .view_more {
      font-size: 18px;
      font-weight: 500;
      text-align: right;
      cursor: pointer;
    }
  }

  .comments {
    display: flex;
    flex-direction: column;
    /* gap: 0.5em; */
  }

  .red-comment{
    border-radius: 0 0 15px 15px;
    background-color: rgba(242, 242, 242, 1);
    display: flex;
    /* align-items: center; */
    gap: 1.5em;
    padding: 10px;
    border: 1px solid #E4151B;

    .comment-image img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .comment_content {
      .comment_author {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
      }
      .comment_author:hover {
        color: #E4151B;
     }

      .comment_text {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }
    }
  }


  .comment {
    border-radius: 25px;
    background-color: rgba(242, 242, 242, 1);
    display: flex;
    /* align-items: center; */
    gap: 1.5em;
    padding: 10px;

    .comment-image img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .comment_content {
      .comment_author {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
      }
      .comment_author:hover {
        color: #E4151B;
     }

      .comment_text {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }
    }
  }
}

.view-more {
  border: 1px solid #E4151B;
  background: #E4151B;
  color: #ffffff;
  border-radius: 12px;
  padding: 7px 15px;
  font-size: 18px;
  margin-top: 10px;
}

.post-image {
  margin: 20px 0;
  background: #f2f2f2;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;

  .show-image {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .show-vedio {
    display: block;
  }

  .post-image-section-1 {
    .show-image {
      max-height: 450px;
    }
  }

  .post-image-section-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the first row */
    gap: 5px;
    .show-image,
    .show-vedio {
      aspect-ratio: 10/12;
    }
  }
  .post-image-section-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the first row */
    gap: 5px;

    .show-image {
      aspect-ratio: 16/9 !important;
    }
  }

  .more-image {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
}

.no-post-data {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
}

/* responsiveness */

@media (max-width: 600px) {
  .header {
    .header-left {
      .profile_picture {
        width: 60px;
        height: 60px;
      }

      .user_info .user_name {
        font-size: 20px;
      }
      .user_info .user_role {
        font-size: 14px;
      }
    }
  }

  /* .content .post_text {
    font-size: 16px;
  } */

  .actions {
    .action_button {
      font-size: 16px;
      gap: 0.2em;

      img {
        width: 20px;
      }
    }
  }

  .comments_section .comments_title .text {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .comments_section .comments_title .view_more {
    font-size: 16px;
  }

  .comments_section .comment {
    .comment-image img {
      width: 40px;
      height: 40px;
    }

    .comment_content {
      .comment_author {
        font-size: 16px;
      }

      .comment_text {
        font-size: 14px;
      }
    }
  }
}

/* for Medium devices like tab, tablets, 600px to 768px) */
@media (min-width: 601px) and (max-width: 768px) {
  .header {
    .header-left {
      .profile_picture {
        width: 65px;
        height: 65px;
      }

      .user_info .user_name {
        font-size: 20px;
      }
      .user_info .user_role {
        font-size: 15px;
      }
    }
  }

  /* .content .post_text {
    font-size: 14px;
  } */

  .actions .action_button {
    font-size: 16px;
    gap: 0.3em;
    img {
      width: 20px;
    }
  }

  .comments_section .comments_title .text {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .comments_section .comments_title .view_more {
    font-size: 17px;
  }

  .comments_section .comment {
    .comment-image img {
      width: 40px;
      height: 40px;
    }

    .comment_content {
      .comment_author {
        font-size: 16px;
      }

      .comment_text {
        font-size: 14px;
      }
    }
  }
}

/* for large devices  */
@media (min-width: 769px) {
  .header {
    .header-left {
      .profile_picture {
        width: 75px;
        height: 75px;
      }

      .user_info .user_name {
        font-size: 18px;
      }
      .user_info .user_role {
        font-size: 16px;
      }
    }
  }

  .actions .action_button {
    font-size: 16px;
    img {
      width: 20px;
    }
  }

  .comments_section .comments_title .text {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .comments_section .comments_title .view_more {
    font-size: 18px;
  }

  .comments_section .comment {
    .comment-image img {
      width: 40px;
      height: 40px;
    }

    .comment_content {
      .comment_author {
        font-size: 16px;
      }

      .comment_text {
        font-size: 14px;
      }
    }
  }
}

.view-more {
  border-radius: 8px;
  background: #E4151B;
  color: #fff;
  border: 1px solid #E4151B;
  font-size: 16px;
  padding: 5px 10px;
}

.count-text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  cursor: pointer;
}
.pointer-none {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  pointer-events: none;
  color: #E4151B;
}
.publisher-tag {
  font-size: 14px;
  font-weight: 600;
  /* color: #888888; */
}
.timing {
  color: #363636;
  font-size: 14px;
  font-weight: 600;
}
.tag-name {
  color: #E4151B;
  font-size: 16px;
  font-weight: 600;
}
.tag-item {
  margin: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}


.red-header {
  background: #E4151B;
  border-radius: 12px 12px 0 0;
  .text-font {
    font-size: 20px;
    font-weight: 600;
    color: #F2F2F2;
  }
  .ignor-btn{
    margin: 10px;
    border: none;
    background: transparent !important;
    border-radius: 12px;
    font-size: 16px;
    /* padding: 5px 15px; */
    color: #fff;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
  }
  .delete-btn{
    margin: 10px;
    border: none;
    background: transparent !important;
    border-radius: 12px;
    font-size: 16px;
    /* padding: 5px 15px; */
    color: #ffffff;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */

 }
}
.delete-btn:hover{
  text-decoration: underline;
}
.ignor-btn:hover{
  text-decoration: underline;
}

.red-content {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
}
.red-header-comment{
  background: #E4151B;
  border-radius: 15px 15px 0 0;
  .text-font {
    font-size: 20px;
    font-weight: 600;
    color: #F2F2F2;
  }
  .ignor-btn{
    margin: 10px;
    border: none;
    background: transparent;
    border-radius: 12px;
    font-size: 16px;
    padding: 5px 15px;
    color: #fff;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
  }
  .delete-btn{
    margin: 10px;
    border: none;
    background: transparent;
    border-radius: 12px;
    font-size: 16px;
    padding: 5px 15px;
    color: #ffffff;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */

 }
}

.post-container1 {
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0px 0px 12px 12px;
  padding: 15px;
  margin-bottom: 20px;
}


.commentActions {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}
.actionButton {
  margin-right: 15px;
  cursor: pointer;
  font-size: 13px;
}
.dotsIcon {
  width: 16px;
}
.moreBtn{
  border-radius: 12px 0px 12px 12px;
  border: 1px solid #36363620;
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  flex-direction: column;
  position: absolute;
  /* bottom: -66px; */
  top: 13px;
  right: 22px;
  /* box-shadow: 0px 2.34px 2.34px 0px rgba(0, 0, 0, 0.25); */
  z-index: 10;
  padding: 0px 5px 0px 5px;
}
.moreBtnItem{
  color:rgba(0, 0, 0, 1);
  font-size: 16px;
  text-align: right;
  text-wrap:nowrap;
  display: flex;
  font-weight: 500;
  margin: 5px;
  padding-right: 10px;
  /* padding: 7px 3px 6px 30px; */
  cursor: pointer;
}
.moreBtnParent{
  position: relative;
}
.span-margin{
  margin-bottom: 20px;
}

.repost-text{
  margin-bottom: 0;
    line-height: 0;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #E4151B;
}
.repost-nornal-text{
  font-weight: 400;
  color: #363636;
  font-size: 12px;
}   