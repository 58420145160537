/* .groupsContainer {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2.91px 2.91px 0px #00000040;

}

.header {
  background: linear-gradient(90deg, #172A66 0%, #6376B2 100%);
   
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px; 
  border-radius: 18px 18px 0 0; 
  box-shadow: 0px 2.91px 2.91px 0px #00000040;
}

.header h2 {
  margin: 0;
  font-size: 20px;
  color: white;
}

.viewMore {
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.groupItem {
  display: flex;
  align-items: center;
  margin:  8px;
  justify-content: space-between;
  border-bottom: 5px solid #213c92;
}


.groupItem:last-child {
  border-bottom: none;
}


.groupIcon {
  width: 40px;
  height: 40px;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 12px;
}

.groupContent {
  flex: 1;
}

.groupName {
font-size: 16px;
font-weight: 700;
text-align: left;
  color:  #213C92;
}

.groupMessage {
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  color: #3C3C3C;
}

.groupMembers {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.memberIcon {
  width: 25px;
  height: 25px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 4px;
}

.groupAction {
  background-color: #FFFFFF;
}

.addButton {

margin-bottom: 10px;
width: 28px;
height: 28px;
padding: 8.17px;
border-radius: 100px 0px 0px 0px;

  border-radius: 50%;
  border: 4px solid #213c92;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  position: relative; 
  font-size: 40px; 
  background-color: #FFFFFF;
  color: #213c92; 

}
.headerText{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  
} */

.groupsContainer {
  width: 100%;
  border-radius: 12px;
  background-color: #ffe4de;
  border: 1px solid #f7a6a4;
  box-shadow: 2px 2px 9px 0px #00000040;
  padding: 10px;
}

.headerText {
  font-size: 18px;
  font-weight: 600;
  color: #e4151b;
  margin-bottom: 15px;
}

.header{
  display: flex;
  justify-content: space-between;
}

.viewMore{
  font-size: 16px;
  font-weight: 600;
  color: #e4151b;
  margin-bottom: 15px;
  cursor: pointer;
}

.groupItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* gap: 10px; */
  padding: 10px;
  border-bottom: 1px solid #E4151B4D;
  margin-bottom: 10px;
}

.groupItem:last-child{
  border-bottom: none;
}

.groupIconContainer {
  /* min-width: 50px; */
  display: flex;
  gap: 10px;

  .group-profile-pic{
    height: 60px;
    box-shadow: 0px 6.02px 6.02px 0px #00000040;
    width: 60px;
    border-radius: 50%;
  }
}

.groupContent {
  flex: 1;
}

.groupName {
  font-size: 16px;
  font-weight: 600;
  color: #363636;
  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-break: anywhere;
}

.groupMessage {
  font-size: 14px;
  color: #363636;
  margin-top: 5px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-break: anywhere;
}

.groupMembers {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #363636;
}

.memberIcon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0px 6.02px 6.02px 0px #00000040;
  border: 2px solid #fff;
  /* box-shadow: 0px 0px 5px #00000029; */
}

.moreMembers {
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.groupAction {
  display: flex;
  align-items: center;
}

.addButton {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButtonIcon {
  height: 25px;
  width: 25px;
}