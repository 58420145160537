.container-fluid {
    .container {
        background: #ffffff;
        /* box-shadow: 0px 4px 4px 0px #00000040; */
        border-radius: 12px;
        padding: 15px 20px 0px 20px !important;
        height: max-content;

        .headerMange {
            display: flex;
            /* justify-content: space-between; */
            align-items: center;
            /* gap: 1em; */
        }

        .header-left-part {
            flex: 0 0 30%;
        }

        .header-right-part {
            display: flex;
            gap: 0.5em;
            justify-content: end;
            align-items: center;
            flex: 0 0 70%;
        }

        .connectionsText {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #E4151B;
        }

        .searchWithFilters {
            text-decoration: underline;
            /* color: rgba(99, 118, 178, 1); */
            color: #E4151B;
            font-size: 16px;
            margin: 0px 14px;
            /* width: 260px; */
            white-space: nowrap;
        }

        .brokenLine {
            width: 100%;
            /* border: 2px solid rgba(23, 42, 102, 1); */
            border: 1px solid #36363680;
            margin: 15px 0px;
        }

        .active_button {
            /* background-color: #213c92; */
            border-bottom: 2px solid #E4151B;
            color: #363636;
            font-size: 20px;
            border-radius: 12px 12px 0px 0px;
        }

        .search-bar {
            display: flex;
            /* border: 1px solid #172A66; */
            /* border-radius: 12px; */
            overflow: hidden;
            /* max-width: 300px; */
            width: 100%;
            /* width: 400px; */
            /* height: 50px; */
            /* margin-top: 10px; */
            position: relative;
            /* background-color: #F2F2F2; */
            max-width: 400px;
        }

        .search-bar input {
            /* border: none;
            border-radius: 12px; */
            width: 100%;
            font-size: 16px;
            padding: 10px 40px 10px 10px;
            /* padding: 6px 10px; */

        }

        .close-button {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
        
        .close-button:focus{
            box-shadow: none;
        }

        .search-bar img {
            width: 20px;
            height: 20px;
        }

        .search-bar:focus {
            /* box-shadow: none; */
            /* border-bottom: 2px solid #172A66; */
        }

        .search-bar button {
            border: none;
            border-radius: 12px;
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
        }

        .search-icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .user_navigation {
            /* padding: 0px 20x; */
            display: flex;
            /* flex-wrap: wrap; */
            overflow-y: auto;

            button {
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                background: none;
                color: black;
                cursor: pointer;
                border: none;
                padding: 5px 15px;
                margin: 2px;
                /* max-width: 130px; */
                /* width: 200px; */
            }

            button:hover {
                /* background-color: #213c92; */
                /* color: rgba(255, 255, 255, 1); */
                color: #363636;
                font-size: 20px;
                border-radius: 12px 12px 0px 0px;
            }

            .active_button {
                /* background-color: #213c92; */
                border-bottom: 2px solid #E4151B;
                color: #363636;

                /* color: white; */
                font-size: 20px;
                border-radius: 12px 12px 0px 0px;
            }
        }

        @media (max-width: 768px) {
            .connectionsText {
                font-size: 30px;
            }

            .search-bar {
                max-width: 100%;
            }

            .user_navigation button {
                font-size: 20px;
                /* max-width: 80px; */
            }
        }

        @media (max-width: 480px) {
            .connectionsText {
                font-size: 24px;
                text-align: center;
                width: 100%;
            }

            .search-bar {
                margin-top: 5px;
                height: 40px;
            }

            .user_navigation button {
                font-size: 20px;
                /* max-width: 60px; */
            }
        }
    }
}

.disabled-btn{
    pointer-events: none;
    background: transparent !important;
  }


  .brochure{
    width: 100%;
    border-radius: 12px;
    height: max-content;
    margin-top: 30px;
  }