.fee-modal-block {
    padding: 15px 20px;
    .fee-modal-header {
        /* background-color: #213C92; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #36363633;
        margin-bottom: 20px;

        .heading {
            font-size: 24px;
            font-weight: 700;
            line-height: 43.58px;
            letter-spacing: -0.23999999463558197px;
            /* color: #FFFFFF; */
            color: #E4151B;

        }

        .cross-icon {
            cursor: pointer;
        }
    }

    .fee-modal-body {
        /* padding: 20px; */

        .amount-field {
            position: relative;

            .amount {
                padding-right: 50px;
            }

            .currency {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(20%);
                pointer-events: none;
            }
        }

        .make-payment-button-block {
            text-align: center;

            .payment-btn {
                box-shadow: 0px 4px 4px 0px #00000040;
                /* border: 2px solid #213c92; */
                border-radius: 10px;
                padding: 7px 15px;
                font-size: 16px;
                font-weight: 500;
                /* letter-spacing: -0.23999999463558197px; */
            }
        }
    }
}