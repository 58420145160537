.main-application-section{
  display: flex;

}
.application-area{
  width: 80%;
  flex: 0 0 auto;
  position: relative;
  left: 20%;
}
.side-bar{
  width: 20%;
  flex: 0 0 auto;
  position: fixed;
}