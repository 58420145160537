

.setting-container {
  margin-top: 25px;
}

.setting-section {
 
}

.setting-left-section {
  background: #FFE4DE;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px;
  padding: 15px;

  .setting-left-content{
  margin-top: 10px;

  .alphaComp{
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    padding: 5px 20px;
    cursor: pointer;
  }

  .alphaComp:hover{
    color: #E4151B;
  }
  }
}

.mainContainer {
  background-color: rgb(214, 214, 214);
}
.backgroundColor {
  background-color: white;
}
.activeMenuBar {
  /* background-color: #213c92; */
  /* border-bottom: 2px solid #E4151B; */
  color: #E4151B;
  border-radius: 2px;
}
.borderForAll {
  border: 1px solid rgb(206, 202, 202);
}

