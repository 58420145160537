.container {
    .section-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 10px; */
        /* flex-wrap: wrap; */
        /* line-break: anywhere; */
    }

    .beta-section-1 {
        font-weight: 600;
        font-size: 24px;
        color: #E4151B;
    }


    .section-button {
        font-weight: 500;
        font-size: 16px;
        color: white;
        margin-top: 10px;
        border-radius: 12px;
        background-color: #E4151B;
        /* background-color: #E4151B; */
        padding: 7px 15px;
        white-space: nowrap;
        /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
        border: none;
    }
    .search-icon{
        height: 20px;
    }

    .section-2 {
        margin-top: 20px;
        /* border-radius: 12px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
        /* padding: 10px; */
    }

    .search-bar {
        display: flex;
        /* border-bottom: 2px solid #172A66; */
        /* border-radius: 12px; */
        overflow: hidden;
        width: 100%;
        /* height: 40px; */
        margin-top: 10px;
        position: relative;
        /* background-color: #F2F2F2; */
    }

    .search-bar input {
        /* border: none; */
        /* border-radius: 12px; */
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        /* padding: 10px; */
    }

    .search-bar:focus {
        box-shadow: none;
        border-bottom: 2px solid #172A66;
    }

    .search-bar button {
        border: none;
        /* border-radius: 12px; */
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
    }

    .section-3 {
        padding: 0;
        margin-top: 10px;
        /* display: flex;
        justify-content: center; */
        /* flex-wrap: wrap; */
    }

    .buttonCustom {
        font-size: 18px;
        /* border-radius: 12px; */
        background-color: transparent;
        /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
        border: none;
        margin-bottom: 5px;
        /* flex: 1 1 calc(50% - 10px); */
        /* height: 40px; */
        width: 100%;
        font-weight: 600;
        white-space: nowrap;
        padding: 0;
        text-align: center;
        color: #363636;
        margin-top: 5px;
        padding: 5px;
    }

    .activeButton {
        /* background-color: rgba(33, 60, 146, 1); */
        /* color: #E4151B; */
        /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
        border-bottom: 1px solid #E4151B;
    }

    .section-4 {
        width: 100%;
        border-radius: 12px;
        background-color: #ffe4de;
        border: 1px solid #f7a6a4;
        box-shadow: 2px 2px 9px 0px #00000040;
        padding: 10px;
        margin-top: 10px;
        height: 500px;
        overflow-y: auto;

    }
    .buttonRow{
        padding: 0px !important;
    }
    @media (min-width: 768px) {
        /* .beta-section-1 {
            font-size: 40px;
        } */

        .section-button {
            font-size: 16px;
        }

        /* .search-bar {
            height: 50px;
        } */

        /* .search-bar input {
            font-size: 20px;
        } */

        .buttonCustom {
            font-size: 18px;
            /* height: 45px; */
        }

        /* .section-3 {
            display: flex;
            flex-wrap: nowrap;
        } */

        .buttonCustom {
            flex: 1 1 162px;
        }
    }
}
.nosection{
    background-color: #ffff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        margin-top: 10px;
        border-radius: 12px;
        display: flex;
        /* flex-direction: column; */
        /* gap: 10px; */
        height: 137px;
        /* overflow-y: auto; */
        justify-content: center;
        align-items: center;
}
.red-back{
 border-bottom:1px solid #E4151B;
}

.remove-search-text{
width: 40px;
height: 40px;
padding: 0px 10px;
}


.close-button {
    position: absolute !important;
    top: 50% !important;
    right: 40px !important;
    transform: translateY(-50%);
  }

  .close-button:focus{
    box-shadow: none;
  }