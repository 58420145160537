
  .required{
    color: red;
  }
  .ModelContainer{
    padding: 10px;
    .headerContainer {
    border-bottom: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .corssIcon {
        /* height: 48px; */
        cursor: pointer;
    }
  }

}
.div-heigth{
  max-height: 500px;
  overflow-y: auto;
}



.header{
    font-size: 20px;
    font-weight: 600;
    color: #E4151B;
}
  