.data-section {

}

.eduacation-data-section{
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.add-more-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #E4151B;
  border-radius: 10px;
  border: 1px solid #E4151B;
  padding: 8px;
  img{
    padding-right: 2px;
  }
}
.ModelContainer {
  .headerContainer {
    border-bottom: 0 !important;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    .title {
      font-size: 20px !important;
      font-weight: 600;
      color: #363636;
    }
    .corssIcon {
      height: 48px;
      cursor: pointer;
    }
  }
  .iconSize {
    cursor: pointer;
  }
}


.ModelContainer, .mainContainer{
  margin: 10px 20px;
}

.addBtnAlgin {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.submit_btn{
  text-align: end;
  padding-right: 10px;
  .submit_btn_text{
    background-color: #E4151B
;
  }
  }

  .customTable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .customTable th {
    background-color: #E4151B
 !important;
    color: white !important;
    padding: 10px !important; 
    border-right: 1px solid #5169B5;
    border-left: 2px solid #5169B5; 
    font-weight: bold;
  }
  
  .customTable td {
    padding: 10px;
    border: 1px solid #ddd; 
    border-radius: 8px; 
    background-color: #f9f9f9;
    text-align: left;
    font-size: 16px !important;
  }
  
  .customTable tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .customTable tbody td {
    font-size: 14px; 
  }

  .customTable thead th:last-child {
    text-align: center;
    border-radius: 0px 0px 0px 0px;
  }
  .customTable tbody td:last-child {
    text-align: center;
    border-radius: 0px 0px 0px 0px;
  }
  
  .iconSize {
    cursor: pointer; 
    width: 20px; 
    height: 20px;
  }
  

 .option-default{
  display: none;
}




/* student list cards */

.formBackgroundEducationInfoMore {
  /* background-color: #f9f9f9; */
  /* padding: 20px; */
}

.educationCard {
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  /* padding: 15px; */
  /* background-color: #fff; */
  margin-bottom: 20px;
}

.educationCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textOverflow {
  font-size: 18px;
  font-weight: 600;
  color: #E4151B;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.actions {
  display: flex;
}

.iconHeightUpdate,
.iconHeightDelete {
  background: none;
  border: none;
  cursor: pointer;
}
.contentArea{
  padding: 10px;
}
.mainWrapper {
  margin-top: 10px;
  /* border: 2px solid #E4151B; */
  /* background: #FFFFFF; */
  background: #FBF7F2;
  border: 1px solid #3636361A;
  border-radius: 11px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.valueWrapper {
  margin-bottom: 10px;
  display: flex;
}

.headingLabel {
  font-weight: 600;
  white-space: nowrap;
  color: #363636;
  font-size: 14px;
}

.headingValue {
  font-weight: 400;
  margin-left: 10px;
  color: #363636;
  font-size: 14px;
}

.tableArea {
  margin-top: 10px;
}

.tableWrapper {
  overflow-x: auto;
}

.educationTable {
  width: 100%;
  border-collapse: collapse;
}

.tableHeading {
  /* background-color: #007bff; */
  background-color: #E4151B
;
  color: white;
  padding: 10px;
  border-right: 1px solid #c77e81;
  border-left: 2px solid #c77e81;
  font-weight: 400;
  font-size: 14px;
}

.tableData {
  padding: 10px;
  font-weight: 400;
  border: 1px solid #ddd;
  font-weight: 14px;
}
/* 
.tableData :first-child{
  border-right: 1px solid #5169B5;
 border-left: 2px solid #5169B5;
} */

.extrik{
  color: #E4151B;
}
.errorText{
  font-weight: 400;
  font-size: 12px;
  color: #E4151B;
}

.cancel-btn{
  border: none;
  background: transparent;
  color: #000;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;

}
.cancel-btn:hover{
  color: #E4151B;

}

.hint{
  color: #363636;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 5px;
}