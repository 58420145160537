.heading {
  font-size: 24px;
  font-weight: 600;
  color: #E4151B;
}
.header {
  /* background: #202020; */
  color: #E4151B;
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  p {
    margin-bottom: 0 !important;
  }
}
.tab-alignment {
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;

  .tab-text {
    color: #363636;
    font-family: 'Poppins';
    cursor: pointer;
    white-space: nowrap;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  .selected-text{
    /* color: #E4151B; */
    font-family: 'Poppins';
    cursor: pointer;
    white-space: nowrap;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #E4151B;
  }
  /* .tab-text:hover{
    color: #E4151B;
    font-family: 'Poppins';
    cursor: pointer;
    white-space: nowrap;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #E4151B;
  } */

}
.col-background {
  /* background: #E4151B; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.sub-heading-result {
  font-size: 20px;
  font-weight: 600;
  color: #E4151B;
  margin-bottom: 0;
}
.sub-heading {
font-size: 18px;
font-weight: 500;
color: #363636;
margin-bottom: 0;
}
.institue-image {
  width: 166px;
  height: 166px;
}
.col-white-background {
  background: #fff;
}
.save-btn{
  padding: 7px 15px;
  border-radius: 10px;
  color: #fff;
  background: #E4151B;
  border: 1px solid #E4151B;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
.outer-box{
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px;
}
.program-container{
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px 15px 0 0;
  margin-top: 20px;
}
.program-details{
  box-shadow: 0px -4px 6px 0px #3636361A;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #3636361A;
  padding: 20px;
}

.normal-program{
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px;
}