
.messages-container {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  /* height: 82vh; */
  background: #FAF5EF;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 18px;
}
.messages-container-no-data{
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  height: 82vh;
  background: #FAF5EF;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 18px;
}
.user-container {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  height: 82vh;
  background: #FAF5EF;
  margin-top: 18px;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.messages-section1 {
  /* padding: 10px; */
  /* border-bottom: 1px solid #ddd; */
  /* text-align: center; */
}

.messages-section1-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages-image-part {
  display: flex;
  align-items: center;
}

.messages-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
  /* cursor: pointer; */
}


.message-image-icon{
  height: 46px;
  width: 46px;
  border-radius: 50%;
}


.user-name-chat{
  font-size: 18px;
  font-weight: 600;
  color: #363636;
  margin-bottom: 0;
  
}
.user-chat{
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #363636;
  
}


.chat-messages {
  flex: 1; 
  /* overflow-y: auto; */
  padding: 10px;
  /* height: 64vh; */
}
.chat-messages {
  flex: 1;
  /* overflow-y: auto; */
  padding: 10px;
  /* height: 64vh; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}





.message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;
}



.sent {
  flex-direction: row-reverse;
  justify-content: end;
  text-align: right;
}

.sent .message-content {
  border-radius: 12px 12px 0 12px;
  background: #F1EDE9;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    box-shadow: 0px 1px 1px 0px #36363640;
    border-radius: 20px;
  position: relative;
}


.received {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.received .message-content {
  border-radius: 12px 12px 12px 0;
  background: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  box-shadow: 0px 1px 1px 0px #36363640;
  border-radius: 20px;
}

.message-content {
  padding: 10px;
  max-width: 70%;
  word-wrap: break-word;
}


.message-text {
  font-size: 15px;
  color: #363636;
  margin-bottom: 4px;
}


.chatUpdateTime {
  font-size: 12px;
  color: #999;
  display: block;
  text-align: right;
  margin-top: 4px;
}


.message-image-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}







.description-section textarea {
  width: 100%;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
}






.d-flex {
  display: flex;
  align-items: center;
}

.mt-2 {
  margin-top: 10px;
}

.message-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
  margin: 5px 0;
  position: relative;
}

.message-date::before, .message-date::after {
  content: "";
  flex: 1;
  border-top: 1px solid #ddd;
  margin: 0 10px;
}



.sticy-header1{
  position: sticky;
  top: 135px;
}
.rightContainer {
  display: flex;
  flex-direction: column; 
  padding: 10px;
  gap: 30px;
  max-height: 500px;
  overflow-y: auto;
  .marketing-brochure{
    border-radius: 12px;
  }
}


.add-more{
  /* font-size: 14px;
    font-weight: 600;
    color: #e4151b;
    padding: 10px;
    cursor: pointer;
    background-color: #3636361A;
    /* padding-bottom: 0px; */
    
}

.add-icon{
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 5px;
}

.no-data{
  text-align: center;
  margin-top: 100px;

  img{
    width: 80px;
    height: 80px;
  }
}
.red-span{
  font-size: 16px;
  font-weight: 700;
  color: #E4151B;
}
.backSpan{
  font-size: 16px;
  font-weight: 700;
  color: #363636;
}



.description-section {

  position: relative;
  bottom: 0px;

  textarea {
    background: #FFFFFF;
    height: 125px;
    width: 100%;
    border: none;
    border-radius: 17px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;

  }

  textarea:focus-visible {
    outline: none;
}
}




.left-section-div{
    background-color: #ffe4de;
    border: 1px solid #f7a6a4;
    border-radius: 12px;
    box-shadow: 2px 2px 9px 0 #00000040;
    /* padding: 10px; */
    width: 100%;
    height: 500px;
    overflow: auto;
    margin-top: 18px;
}

.user-msg-card{
  padding: 15px;
  cursor: pointer;
}

.user-msg-card:hover{
  background-color: #3636361A;
}

.first-active-chat{
  padding: 15px;
  cursor: pointer;
  background-color: #3636361A;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.active-chat{
  padding: 15px;
  cursor: pointer;
  background-color: #3636361A;
}

.header-changes{
  display: flex;
  gap: 5px;
  overflow: hidden;
  position: relative;
}

.messages-user-content{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.user-name{
  font-family: "Poppins";
font-size: 16px;
font-weight: 600;
margin-bottom: 0;
color: #E4151B;
-webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    cursor: pointer;
}
.chat-text{
  font-family: "Poppins";
font-size: 13px;
font-weight: 600;
margin-bottom: 0;
color: #363636;
padding-right: 27px;
-webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    word-break: break-all;
}

.unreadChat-count{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E4151B;
  height: 22px;
  width: 22px;
  align-self: end;
  font-size: 12px;
  color: white;
  font-weight: 600;
  position: absolute;
  bottom: 0px;
  right: 0;
}

.timer{
font-size: 11px;
font-weight: 600;
margin-bottom: 0;
color: #363636;
white-space: nowrap;
}
.divided-line{
  border:1px solid #E4151B4D;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}



.emoji_picker_wrapper {
  position: absolute;
  z-index: 999; 
  left: 0;
  bottom: 20px;
}
.close_icon_emojis {
  position: absolute;
    top: 4px;
    right: 6px;
    width: 24px;
    height: 24px;
    z-index: 99;
    cursor: pointer;
    background-color: #213c92;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.send-button{
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.edit-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
}


.ModelContainer{
  .title {
      font-size: 20px !important;
      font-weight: 600;
      color: #E4151B;
      /* text-align: center; */
      margin-bottom: 10px;
  }
  .headerContainer {
      padding: 10px 5px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  border-bottom: 0 !important;
  .corssIcon {
      /* height: 48px; */
      padding: 10px;
      cursor: pointer;
  }
}
}


.edit-box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-textarea {
  background: #FFFFFF;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 17px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
}
.edit-textarea:focus-visible {
  outline: none;
}
.edit-actions {
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-top: 5px;
}

.save-button, .cancel-button {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.save-button:hover,
.cancel-button:hover {
  /* opacity: 0.8; */
}

.loader{
  height: 40px;
}
.hidden {
  visibility: hidden;
  height: 20px;
}



.scroll-to-top {
  position: absolute;
  top: -51px;
  right: 20px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  padding: 8px;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top img {
  width: 24px;
  height: 24px;
}



.view-more{
  border: none;
  background: #E4151B;
  color: #ffffff;
  border-radius: 12px;
  padding: 7px 15px;
  margin-top: 10px;
  font-size: 16px;
}



.popup {
      position: absolute;
      top: 27px;
      right: 10px;
    display: flex;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 10px 0px 10px 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 2px 5px 4px 5px;
}
.popupOption {
  cursor: pointer;
}

.popupOption img {
  margin-right: 8px;
}
  
.input-div {
  position: relative;
  input{
    border-bottom: 2px solid #E4151B !important
  }
}

.search-icon{
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
  cursor: pointer;
  img{
    height: 20px;
    width: 20px;

  }

}
.normal-div{
  padding: 5px 10px;
  background: transparent;
  color: #363636;
  font-size: 14px;
  width: fit-content;
  border-radius: 10px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
}
.selected-div{
  background: #363636;
  color: #fff;
  font-size: 14px;
  width: fit-content;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
}

.normal-div:hover{
  background: #363636;
  color: #fff;
  font-size: 14px;
}
.header-text-new{
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
   color: #E4151B;
   margin-bottom: 0;
   margin-left: 5px;
}
.header-changes1{
  margin-top: 20px;
  display: flex;
  border-bottom: 1px solid  #36363633;
  padding-bottom: 20px;
}


@media screen and (max-width: 991px){
  .rightContainer{
    max-height: auto;
    overflow-y: visible;
  }
  .sticy-header1{
    position: relative;
    top: 20px;
  }
}