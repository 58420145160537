.card-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .card-header {
        background-color: #000000;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 8px 8px 20px 8px;

        .section-1 {
            display: flex;
            flex-direction: column;
            gap: 1em;

            .block-1 {
                display: flex;
                align-items: center;
                gap: 0.6em;

                .university-name {
                    font-size: 20px;
                    font-weight: 700;
                    color: #FFFFFF;
                }
                .university-icon{
                    width: 15%;
                }
            }

            .block-2 {
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                .program-name {
                    font-size: 20px;
                    font-weight: 700;
                    color: #FFFFFF;
                }

                .program-detail-text {
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 21.79px;
                    letter-spacing: -0.23999999463558197px;
                    padding-left: 8px;
                }
            }
        }
    }

    .card-body {
        background-color: #FFFFFF;
        padding: 0px 10px 50px 10px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .section-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5em;

            .program-detail-section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .header {
                    font-size: 12px;
                    font-weight: 700;
                    color: #3C3C3C;
                }

                .detail {
                    font-size: 12px;
                    font-weight: 400;
                    color: #3C3C3C;

                    ul {
                        margin: 0;
                    }
                }
            }
        }
    }

    .card-rank {
        position: absolute;
        top: 36px;
        right: 8px;

        .rank-text {
            font-size: 20px;
            font-weight: 700;
            color: #FFFFFF;
        }
    }

    .cross-icon-div {
        position: absolute;
        top: 2px;
        right: 4px;
        cursor: pointer;
    }

    .heart-icon-div {
        position: absolute;
        top: 70px;
        right: 8px;
        cursor: pointer;
    }

    .cross-icon {
        height: 40px;
    }

    .heart-icon {
        height: 25px;
    }
}