
.header{
    padding: 10px 0 0;
    margin-left: 20px;
    /* border-bottom: 2px solid #213C921A; */

}
.title{
    color: #e4151b !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 33px;
    margin-bottom: 1rem;
}
.description {
    display: block;
    font-size: 14px;
    font-weight: 600;

    color: #363636;
}
.description {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
  }
.card-img-top{
    width: 200px;
}
.card-header{
    margin-top: 7px; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
}
.custom-button{
    background: white;
    width: 390px;
    border-radius: 29px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    color: #000;
    text-align: left;
    padding: 0 30px;
    border: 1px solid #C4C4C4;
    /* width: 100%; */
}
.program-heading{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000;
}
.loadmore{
  background: #fff;
  border-radius: 7px;
  border: 1px solid #E4151B;
  color: #E4151B;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px;
}
.icon-size{
  height: 15px;
  width: 14px;
}
.instution-detail1{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    margin-top: 1rem !important;
    line-height: 27px !important; 
    color: #363636 !important;
}
.read-more{
    cursor: pointer;
    color: #E4151B;
}

.custom-button{
    display: flex;
    justify-content: space-between; 
}
.para-description{
    margin-top: 1rem !important;
}
.email-text{
    color: #E4151B;
}
.icon-text{
    display: flex;
    gap: 1rem;
    .map-text{
        box-shadow: 0px 4px 4px 0px #00000040;
       

    }
}

.instituteDetails_section6 {
    .instituteDetails_Programs_content {
      background-color: #fff;
      padding: 10px;
      border-radius: 12px;
  
      .program_title {
        font-size: 24px;
        font-weight: 600;
      }
  
      .program-cards {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 2em;
        padding: 20px 0px;
        max-height: 1000px;
              overflow-x: hidden;
              overflow-y: auto;
      }
    }
  }
  
  @media screen and (min-width: 601px) and (max-width: 1200px) {
    .instituteDetails_section6 {
      .instituteDetails_Programs_content {
        .program-cards {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    .instituteDetails_section6 {
      .instituteDetails_Programs_content {
        .program-cards {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
    }
  }
  