.group_header_container {
}

.group_header {
  /* background: #ffffff; */
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  padding: 0 !important;
  position: relative;
  /* border-radius: 12px; */

  .background_image_pic {
    width: 100%;
    object-fit: cover;
    /* border-radius: 12px; */
    height: 270px;
  }

  .icon {
    width: 28px;
    height: 28px;
    position: absolute;
    cursor: pointer;
  }

  .topLeft {
    top: 10px;
    left: 10px;
  }

  .topRight {
    top: 10px;
    right: 10px;
  }

  .bottomLeft {
    bottom: 10px;
    left: 10px;
  }

  .bottomRight {
    bottom: 10px;
    right: 10px;
  }
}
