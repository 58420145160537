.bck {
    float: left;
    padding-right: 5px;
    padding-top: 2px;
}

.mainTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #FF4C4C;
}


.prgdtls {
    width: 100%;
    /* background: #FFFFFF; */
    box-sizing: border-box;
    border-radius: 10px;
    /* background: #FFFFFFCC; */
    box-shadow: 0px 0px 10px 0px #E4151B1A;
    border: 2px solid #36363633;
    background-color: #FAF5EFCC !important;
}

.subInst1 {
    width: 65%;
    text-align: left;
    font-size: 0.75rem;
}

.subInst2 {
    width: 35%;
    text-align: left;
    font-size: 0.75rem;
}

.SubTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #363636
;
    text-align: left;
}

.InstSubTitle span {
    /* font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #E4151B;
    text-align: left; */
}

.InstSubText{
    font-size: 14px !important;
    font-weight: 500 !important;
}
.InstSubTextValue{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #363636 !important;
}

.redSubTitle{
    color: #E4151B !important;
}

.InstSubTitleStatus {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
    text-align: left;
}

.fz-10 {
    font-size: 10px;
}

.subInst3 {
    width: 50%;
    text-align: left;
}

.subInst4 {
    width: 50%;
    text-align: left;
}

p {
    margin-bottom: 0rem;
}

.box {
    padding: 15px 0px;
}

.box-title {
    background: #E6EBF3;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #192A3E;
    padding: 5px;
}

.prgTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 27px;
    color: #000000;
    
}

.prgLevel {
    font-family: Poppins;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 27px;
    color: #000000;
}

.prdbody{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: justify;
    color: #000000;
}

.prgli{
    font-size: 14px !important;
    line-height: 24px !important;
    font-weight: normal;
    color: #333333;
}

.dft {
    background: white !important;
    border-radius: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 37px;
    color: #E4151B;
    padding: 0px 30px;
    text-transform: lowercase;
    border: 1px solid #E4151B;
}

.dft:hover {
    color: #E4151B;
}

.apply {
    background: #E4151B;
    border-radius: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 37px;
    color: white;
    padding: 0px 30px;
    cursor: pointer;
    border: 1px solid #E4151B;
}

.prgbttm {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.redTxt {
    color: #E4151B !important;
    cursor: pointer;
}

.limitTextHeight {
    height: 70px;
    overflow: hidden;
}
.limitTextHeight p {
    margin-bottom: 20px;
  }
.table {
    margin-bottom: 0rem;
}

.table td {
    padding: 0rem;
}

.strike-center {
    position: relative;
    white-space: nowrap;
    font-size: 0.9rem;
}

.strike-center:after {
    border-top: 2px solid #000;
    position: absolute;
    content: "";
    right: 0;
    top: 45%;
    left: 0;
}

.contactBox {
    text-align: left;
    width: 100%;
    font-family: Poppins;

    .contactHeading {
        font-weight: 500;
        margin: 0;
        background: #E6EBF3;
        padding: 5px 15px;
        font-size: 14px;
        text-align: center;
    }

    ol {
        font-size: 12px;
        padding-left: 20px;
        padding-right: 10px;

        li {
            padding-left: 5px;

            h5 {
                font-size: 12px;
                font-weight: 500;
                margin: 0.5rem 0rem 0.3rem;
            }
        }
    }
}



.nocontact {
    margin-top: 1.5em;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.mailto-message {
    top: -4px;
    left: 50%;
    margin-bottom: -5px;
    transform: translate(-50%, -100%);
    position: absolute;
    display: none;
    width: auto;
    white-space: nowrap;
    font-size: 12px;
    background-color: black;
    color: white;
    padding: 2px 6px;
    border-radius: 2px;

    &:after,
    &:before {
        content: '';
    }

    &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #000000;
        border-width: 4px;
        margin-left: -4px;
    }
}

.mailto-link:hover .mailto-message,
.mailto-link:focus .mailto-message,
.mailto-link:focus-within .mailto-message {
    display: block;
}


.left {
    float: left !important;
}

.new-design .intcard {
    border: 0 !important;
}

.card-title {
    font-family: Poppins;
    font-weight: 600 !important;
    font-size: 1.063rem !important;
    padding: 10px 0px;
    color: #363636 !important;
}

.mat-button.btn,
.mat-raised-button.btn,
.mat-raised-button.btn:not([class*=mat-elevation-z]),
.btn {
    text-transform: none;
    border-radius: 0.5rem;
}

.commisionCapital {
    color: #E4151B !important;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
}

.commision {
    color: #34BF49;
    font-size: 18px;
}

.commisionNo {
    color: #34BF49;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
}

hr {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.font-weight-bold-university {
    font-size: 14px;
    font-weight: 800 !important;
    text-transform: capitalize;
    color: #E4151B !important;
    margin-bottom: 0px;
}
/* 
.prgtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 28px;
    color: #363636
;
    margin-bottom: 0;
} */

.prgtitle span {
    color: #E4151B;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}



.card .card-body {
    padding: 0;
}

.instName {
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 600 !important;
    color: #E4151B;
}

.subInst .subInst1 {
    width: 47%;
}

.subInst .subInst2 {
    width: 65%;
}

.card .card-body+.card-footer,
.card .card-footer {
    display: flex;
    justify-content: center !important;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
}


.bck {
    float: left;
    padding-left: 30px;
    padding-right: 5px;
    padding-top: 4px;
}

.strike-center:after {
    border-top: 2px solid #000;
    position: absolute;
    content: "";
    right: 0;
    top: 45%;
    left: 0;
}

@media (min-width:1366px) {

    .btn-agent-bor-red,
    .btn-agent-red {
        font-size: 0.75rem !important;
        padding: 4px 8px !important;
    }
}

@media (max-width: 767px) {
    .prgTitle {
        font-size: 14px;
    }

    .prgLevel {
        font-size: 10px;
    }

    span.additional_req {
        word-break: break-word;
        margin-bottom: 20px;
    }

}

.icon-position{
    margin-top: 0px !important;
    justify-content: space-between;
}

.fav-image{
    margin-top: -13px;
    margin-left: 10px;
}
.new-agent-design{
    overflow-x: hidden;
}


.btn-agent-blue {
    background: #E4151B;
    border-radius: 7px;
    border: 1px solid #E4151B;
    color: #fff;
    font-size: .75rem;
    font-weight: 500;
    padding: 6px 15px;
}

.btn-bor-blue {
    background: #fff;
    border-radius: 7px;
    border: 1px solid #E4151B;
    color: #E4151B;
    font-size: .75rem;
    font-weight: 500;
    padding: 6px 15px;
}

.loadmore{
    background: #fff;
    border-radius: 7px;
    border: 1px solid #E4151B;
    color: #E4151B;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 15px;
}


.crd {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    height: 95% !important;
    width: 100%;
    margin-bottom: 1.5rem;
}

.title-data{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 2px;
    /* padding-left: 12px; */
}


/* ********************* */

/* Niranjan CSS */

.crd {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    height: 95%;
    width: 100%;
    margin-bottom: 1.5rem;
    background: #FAF5EFCC !important;
    border: 2px solid #36363633 !important
}

.crd .position-absolute {
    right: -2px;
    width: 23%;
    top: 0px;
}

.institute-logo {
    width: 16%;
    margin-right: 10px;
}

.institute-name {
    width: 84%;
}

.institute-logo img {
    object-fit: cover;
    width: 100%;
    max-height: 75px;
}

.subName {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0rem;
}

.InstSubTitle {
    font-family: Poppins;
    font-weight: 400;
    font-size: 0.75rem;
    color: #363636
;
    margin-bottom: 0rem;
}

.Instsub {
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.75rem;
    color: #363636
;
    margin-bottom: 0rem;
}

.course {
    font-size: 0.7rem;
    font-family: 'Poppins';
}

.waiverPercentage {
    font-family: Poppins;
    background: #2B2273;
    border-radius: 5px;
    padding: 2px 10px;
    color: #ffffff;
    font-size: 1.175rem;
    font-weight: 800;
    width: fit-content;
}

.waiverPercentage2 {
    font-family: Poppins;
    color: #E4151B;
    border: 1px solid #E4151B;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 0.800rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.waiverPercentage3 {
    font-family: Poppins;
    color: #E4151B;
    border: 1px solid #E4151B;
    border-radius: 5px;
    padding: 1px 10px;
    font-size: 0.700rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.subInst .subInst1 .waiverPercentage,
.subInst .subInst2 .waiverPercentage {
    border-radius: 5px;
    padding: 2px 10px;
    color: #E4151B;
    font-weight: 600;
    width: fit-content;
    border: 1px solid #E4151B;
    background-color: #fff;
}

.subInst .subInst1 .waiverPercentage {
    font-size: 13px;
}

.subInst .subInst2 .waiverPercentage {
    font-size: 0.75rem;
}



.tittle{
    color: #e4151b !important;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 33px;
    margin-bottom: 0px;
    padding-left: 10px;
}

.text-red {
    color: #E4151B !important;
}

.card-header{
    padding: 0px !important;
    overflow: hidden;
    border-bottom: none !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
}
.card-header img{
    width: 100%;
    height: 100%;
    max-height: 120px;
    border-radius: 10px;
}

.hrline{
    margin-top: 0px;
}

.text-green {
    color: #34bf49;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.text-gray {
    color: #999 !important;
}