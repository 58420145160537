.network {
  width: 100%;
  border-radius: 12px;
  background-color: #ffe4de;
  border: 1px solid #f7a6a4;
  box-shadow: 2px 2px 9px 0px #00000040;
  padding: 10px;
}

.network-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  color: #E4151B;
}
.headerText {
  font-size: 18px;
  font-weight: 600;
  color: #e4151b;
  margin-bottom: 15px;
}

.header{
  display: flex;
  justify-content: space-between;
}

.viewMore{
  font-size: 16px;
  font-weight: 600;
  color: #e4151b;
  margin-bottom: 15px;
  cursor: pointer;
}

.network-tabs {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.network-tabs button {
  font-size: 12px;
  font-weight: 600;
  color: #363636;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 5px;
}

.network-tabs button:hover {
  font-size: 12px;
  color: #FFFFFF;
  background-color: #363636;
  border-radius: 20px;
}



.network-tabs .activeTab {
  color: #FFFFFF;
  background-color: #363636;
  border-radius: 20px;
}

.connections-list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.connection-card {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 10px;
  border-bottom: 1px solid #E4151B4D;
}

.connection-section-1 {
  display: flex;
  align-items: center;
  gap: 0.8em;
  position: relative;
}

.connection-info {
  flex: 1;
}

.menu-dots {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: 0;
}

.connection-section-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.connection-card:last-child {
  border-bottom: none;
}

.suggestions-list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.suggestion-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #E4151B4D;
}

.suggestion-section-1 {
  display: flex;
  align-items: center;
  gap: 0.8em;
}

.suggestion-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.suggestion-card:last-child {
  border-bottom: none;
}

.profile-image {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  box-shadow: 0px 6.02px 6.02px 0px #00000040;
}

.user-name {
  font-size: 15px;
  color: #E4151B;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.user-message {
  font-size: 14px;
  color: #363636;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-break: anywhere;
}

.accept-btn {
  border-radius: 10px;
  background: #E4151B;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  border: 0;
  padding: 5px 10px;
}

.invite-btn {
  border-radius: 10px;
  background: #E4151B;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  border: 0;
  padding: 5px 14px;
}

.decline-btn {
  font-size: 10px;
  color: #363636;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

/* .decline-btn:hover {
  text-decoration: underline;
  color: #E4151B;
} */

.popup {
  position: absolute;
  top: 20px;
  right: 0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 8px;
  z-index: 10;
  padding: 10px;
}

.popupOption {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 5px 0;
  cursor: pointer;
}

.popupOption img {
  height: 16px;
  width: 16px;
}

.chatContainer {
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  width: 100%;
  right: -10px;
}

.profilePicBottom {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px 0px 0px 5px;
  box-shadow: 0px 6.02px 6.02px 0px rgba(0, 0, 0, 0.25);
}

.forChatScreen {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}
.disconnectIsTrue {
  border: 2px solid red;
}

@media screen and (max-width: 1300px) and (min-width: 991px) {
  .network-tabs {
    flex-direction: column;
    margin: 10px;
    gap: 0.5em;
  }

  .suggestions-list {
  
    .suggestion-card {
      flex-direction: column;
      align-items: flex-start;
      gap: 1em;

      .suggestion-section-2{
        flex-direction: row;
        align-self: center;
      }
    }
  }
}

.No-data-found{
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
}