.filter_container{
    background-color: white;
    border-radius: 8px;
    /* box-shadow: 0px 2.91px 2.91px 0px #00000040; */
    box-shadow: 0px 2px 0px 0px #3636361A;

    margin-bottom: 20px;
}

.tabs_container{
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 0px 0px #3636361A;

    /* border-bottom: 2px solid #213C92; */
    overflow-x: auto;
    .tabs_value{
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: center;
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        color: #363636;
        padding-left: 20px;
        padding-right: 20px;
    }
    .tabs_value:hover{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 700;
        color: #363636;
        /* background: #213C92; */
        padding-left: 20px;
        padding-right: 20px;
    }
    .selected_tabs_value{
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: center;
        cursor: pointer;
        font-size: 22px;
        font-weight: 600;
        color: #363636;
        /* background: #213C92; */
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 2px solid #E4151B;

    }
   

   

}
.country-btn{
    /* border: 1px solid #213C92; */
    border: 1px solid #36363680;
    /* background: #FFF; */
    background: #FFFFFF80;

    font-size: 16px;
    font-weight: 500;
    border: none;
    /* border-radius: 12px; */
    color: #363636;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 44px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 160px;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;

}
.selected-country-btn{
    /* border: 1px solid #213C92; */
    /* border: 1px solid #36363680; */
    /* background: #213C92; */
    background: #FFFFFF80;
    border: none;

    font-size: 16px;
    font-weight: 500;
    /* color: #FFF; */
    color: #363636;

    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 44px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 160px;
    justify-content: space-between; 
    border-bottom: 2px solid #ccc;

    
}

.country-btn:focus {
        border-bottom: 2px solid #E4151B;
    }


.search_filter_container{
    margin-top: 20px;
}
.search-align{
    display: flex;
    gap: 10px;
    align-items: center;
}
.primary-button{
    min-height: 44px;
    font-size: 16px !important;
    font-weight: 500;
    padding: 0px 25px !important;
    margin-left: 10px;
    border-radius: 10px !important;
    box-shadow: none !important;
    border: none !important;
}
.input-serch{
    min-height: 40px;
    width: 100%;
    padding: 10px;
    /* border: 1px solid #213C92; */
    border: 1px solid #36363680;
    padding-right: 50px !important;
    border-radius: 12px;
     /* background-color: transparent; */
}



.input-serch::placeholder {
    color: #363636;
font-size: 16px;
font-weight: 500;
line-height: 24px;
  }
/* .custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 16px 16px;
  padding-right: 20px; 
} */

  .custom-option
  {
    color: #363636 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .custom-option:hover{
     background-color: #E4151B !important;
  }

.error-message {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    color: #e82529;
  }
  
.input-width{
    width: 100%;
    display: flex;
    position: relative;
}
:focus-visible {
    outline: #E4151B auto 1px;
}
.commom-btn{
background: #FFF;
color: #3C3C3C;
border-radius: 12px;
min-height: 40px;
/* border: 1px solid #213C92; */
border: 1px solid #ffffff;
font-size: 20px;
font-weight: 600;
display: flex;
align-items: center;
gap: 10px;

}

.all-filters-icon{
    height: 25px;
    width: 25px;
}

.search-align-filter{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
    position: relative;
}
.search-icon{
    position: absolute;
    right: 12px;
    height: 20px;
    width: 20px;
    top: 13px;
    z-index: 99;
    cursor: pointer;
}
.sortDropDown{
    position: absolute;
    background: #FFF;
    /* border: 1px solid #213C92;; */
    border: 1px solid #36363680;
    /* width: -webkit-fill-available; */
    right: 0px;
    padding: 10px;
    border-radius:12px;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 99;
}
.dropDown-aligment{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    width: 100% !important;

}
        .dropdown-text
        {
        font-family: 'Poppins';
        /* font-weight: 600; */
        cursor: pointer;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        line-height: 37.24px;
        position: relative;
        color: #3C3C3C;
        }
/* SearchPage.module.css */
.tooltip-container {
    /* position: relative;
    display: inline-block; */
  }
  
  .tooltip {
    /* visibility: visible;
    background-color: red;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 125%; 
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; */
  }
  
  .tooltip::after {
    content: '';
    position: absolute;
    top: 0%; /* Arrow at the top of the tooltip */
    left: 50%;
    transform: translateY(-100%);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
  }
.countryDropDown{
  
    position: absolute;
    background: #FFF;
    /* border: 1px solid #213C92;; */
    border: 1px solid #36363680;
    padding: 10px 20px;
    border-radius:12px;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    min-width: 160px;
    z-index: 100;
}
.searchInputValue{
    position: absolute;
    background: #FFF;
    /* border: 1px solid #213C92;; */
    border: 1px solid #36363680;
    padding: 10px 20px;
    border-radius:12px;
    margin-top: 50px;
    max-height: 300px;
    overflow-y: auto;
    width: -webkit-fill-available;
    z-index: 100;
}
.input-width-value{
    width: 100%;
}
.rotateImage{
    transform: rotate(180deg);
    transition:1s;
}
@media screen and (max-width:512px){
    .commom-btn{
        width: 100%;
        margin-top: 10px;
        justify-content: center;
    }
 
    .search-align-filter{
        display: block;
    }
  
}

@media screen and (max-width: 1280px) {
    .search-align,
    .search-align-filter {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
}
@media screen and (max-width: 1280px) {
    .search-align-filter {
        display: flex;
        justify-content: flex-end;
    }
}
@media screen and (max-width:1400px){
    .search-align{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .input-serch{
        min-height: 40px;
        width: 240px;
        padding: 10px;
        color: #363636;

        padding-right: 50px;
        /* border: 1px solid #213C92; */
        border: 1px solid #36363680;
        border-radius: 12px;
        --bs-form-select-bg-img: url(../../../../../Assets/Logo/drop-arrow.svg) !important;
    }
    /* Add these classes */
.input-search-width-270 {
    width: 285px;
}

.input-search-width-100 {
    width: 100%;
}
    
}
@media screen and (max-width:1000px){
    .search-align{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .input-serch{
        min-height: 40px;
        width: 100%;
        padding-right: 50px;
        padding: 10px;
        /* border: 1px solid #213C92; */
        border: 1px solid #36363680;
        border-radius: 12px;
    }
   
}
@media screen and (max-width:768px){
    .selected_tabs_value{
        height: 50px !important;
        font-size: 20px !important;
    }
    .tabs_value{
        height: 50px !important;
        font-size: 20px !important; 
    }
    .commom-btn{
        font-size: 18px !important;
    }
    .input-serch{
        padding-right: 50px;
    }
    .search-align{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
}
@media screen and (min-width:991px) and (max-width:1040px){
    .commom-btn{
        font-size: 18px !important;
    }
    .input-serch{
        padding-right: 50px;
    }
       
}

.all-filter-background{
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #00000040;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    .all-filter-section{
        position: fixed;
        right: 0;
        height: 100vh;
        background: #fff;
        width: 400px;
        z-index: 999;
        overflow-y: auto;
        max-height: 100vh;
        bottom: 0;
        padding: 30px;
    }
    .clear-btn-postion{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .clear-btn{
        /* border: 1px solid #213C92; */
        padding: 8px 20px;
        height: fit-content;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px;
        background: transparent;
        color: #E4151B;
        /* border: 1px solid #213c92; */
        border: 1px solid #E4151B;
    }
    .see-program-btn{
        /* border: 1px solid #213C92; */
        font-size: 16px;
        font-weight: 500;
        padding: 8px 20px;
        background-color: #E4151B;
        border: 1px solid #E4151B;
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }
}

.all-filter-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 5px;
    cursor: pointer;
}
.filter-sparate-border{
    /* border-bottom: 1px solid #213C92; */
    border-bottom: 1px solid #3636361A;
    padding: 4px;
}
.filter-heading{
font-size: 16px;
font-weight: 600;
}
.header-align{
    display: flex;
    align-items: center;
    gap: 5px;
}
.checkbox-alignment{
    display: flex;
    margin-bottom: 2px;
    gap: 6px;
    
}
.dropdown-background{
    background-color: #f8f9fa !important;
    padding-left: 20px;
    /* background-color: #FFE4DE !important; */
    max-height: 300px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    /* padding: 5px 0px ; */
}

.view-more{
    /* border: 1px solid #213c92; */
    border: 1px solid #36363680;
    background: #213c92;
    color: #ffffff;
    border-radius: 12px;
    padding: 7px 15px;
    font-size: 18px;
    text-align: center;
  }


  .option-default{
    display: none;
  }
  


  
.form-group select {
    /* width: 100%;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ccc;
    font-size: 16px;
    outline: none;
    border-radius: 0; */
    /* background-color: transparent; */
    --bs-form-select-bg-img: url(../../../../../Assets/Logo/drop-arrow.svg) !important;
  }
  .form-group .form-select:focus{
    /* outline: none;
    box-shadow: none;
    border-bottom: 2px solid #E4151B; */
    /* background-color: transparent; */
    --bs-form-select-bg-img: url(../../../../../Assets/Logo/drop-arrow.svg) !important;
  }
  .form-group .form-select{
    /* width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    border-radius: 0;
   */
    /* background-color: transparent; */
    --bs-form-select-bg-img: url(../../../../../Assets/Logo/drop-arrow.svg) !important;
  }


  .backInitial{
   font-size: 16px;
   font-weight: bold;
  }
  .nblack{
    font-size: 16px;
   font-weight: 400;
  }

  .bblack{
    font-size: 16px;
   font-weight: 900;
  }

  .backInitialRed{
    font-size: 16px;
    font-weight: 600;
    color: #E4151B;
    padding: 0px 5px;
  }

  .initial-state-img{
    border-bottom: 2.5px solid #213C921A;
    padding: 0px 250px;
    margin-bottom: 10px;
    margin-top: 60px;
  }

  .initial-state{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }