.btn-position{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.modal-body-data{
    max-height: 450px;
    overflow-y: auto;
    height: 450px;
    top: 0;
}
.cross-icon-position{
    text-align: end;
    margin-bottom: 10px;
    img{
        height: 40px;
        width: 40px;
        cursor: pointer;
    }
}