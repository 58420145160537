/* ProfileUpdateModal.module.css */

.modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
.myflag{
  border-bottom: 2px solid black !important;

}
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; /* Reduce if too much space */
  }
  
  .form-group label {
    font-size: 0.9rem; /* Adjust as needed */
    margin-bottom: 5px;
    color: #333;
  }
  
  
  .form-group select,
  .form-group input[type="text"],
  .form-group input[type="date"] {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      transition: border-color 0.2s ease; /* Smooth transition */
      box-sizing: border-box; /* Include padding and border in total width/height */
  
  }
  
  .form-group select:focus,
  .form-group input[type="text"]:focus,
  .form-group input[type="date"]:focus {
    outline: none;
    border-color: #007bff; /* Highlighted border on focus */
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);/* subtle shadow on focus */
  
  }
  
  .error-text {
    color: #dc3545; /* Red for error messages */
    font-size: 0.8rem; /* Adjust font size */
    margin-top: 3px;
  }
  
  
  
  /* Button styles */
  .heading-text{
    font-size: 25px;
    font-weight: 700;
    color: #E4151B;
  }
  .primary-button {
      padding: 10px 15px;
      background-color: #e82529; /* Button background color */
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.2s ease; /* Smooth transition for hover */
      display: inline-block;
      margin-top: 15px;
      align-self: flex-start; /* To keep it on left side */
  }
  .btn-position{
    display: flex ;
        align-items: center;
        justify-content: space-around;
    }
  .primary-button:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  
  
  .primary-button:disabled {
    background-color: #ccc; /* Grayed out background when disabled */
    color: #666; /* Grayed out text when disabled */
    cursor: not-allowed; /* Indicate disabled */
  }
  
  @media (max-width: 768px) { /* Responsive styles for smaller screens */
      .modal-body {
          padding: 15px;
          gap: 10px;
      }
      .form-group {
          margin-bottom: 8px;
      }
  
      .form-group label {
        font-size: 0.8rem;
      }
      .form-group select,
      .form-group input[type="text"],
      .form-group input[type="date"] {
      padding: 7px;
      font-size: 0.9rem;
  
      }
      .primary-button {
          font-size: 0.9rem;
          padding: 8px 12px;
      }
  }