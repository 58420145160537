
.setting-container {
  .setting-section {
    background: #fbf7f2;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    padding: 15px;

    .setting-title {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .setting-description {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
    }

    .next-icon{
      img{
       width: 30px;
       height: 30px;
       cursor: pointer;
      }
    }

    .form-check-input{
      cursor: pointer !important;
    }
  }
}


/* for changepassword and deactive */
.main-container{
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px;
  padding: 10px;

  .container-block{
    display: flex;
    align-items: center;
    background-color: #fbf7f2;

    .container-left-section-content{
      padding: 20px;
    }
  }

  .language-container-content{
    padding: 20px;
  }

  .prev-icon{
    img{
     width: 30px;
     height: 30px;
     cursor: pointer;
    }
  }
}


.main-container{
  .language-main-container{
    .language-container-content{
      background-color: #fbf7f2;
      display: flex;
      flex-direction: column;
      gap: 2em;
  

      .language-title {
        font-size: 24px;
        font-weight: 700;
        text-align: left;
      }
      
      .language-description {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
      
      .language-info {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        white-space: normal;
    }
    
    }
  }
}




.faPlayIconColor {
  color: blue;
}

.removeLinkDecoration {
  cursor: pointer;
  text-decoration: underline;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.btnColorCustom {
  background-color: #E4151B;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 7px 10px;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.changePasswordText {
  font-size: 28px;
  font-weight: 700;
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-container {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.eyeicon {
  font-size: 1em;
}

.error{
  color: red;
  font-size: 12px;
}

