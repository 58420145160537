.mainContainer {
  /* box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  background: #FAF5EFCC;
  border: 1px solid #3636361A;
  border-radius: 15px;
  margin-top: 20px; */
    .headingText {
      font-weight: 400;
      font-size: 18px;
      margin-top: 12px;
      margin-left: 20px;
      color: #363636;
      span{
        font-weight: 400;
      }
    }
    .container {
      /* background-color: #ffffff;
      box-shadow: 0px 4px 4px 0px #00000040;
      border: 0 0 2px 0;
      padding: 20px;
      margin: 10px; */
      /* background-color: #fff; */
      /* border: 1px solid #00000040; */
      /* margin: 10px; */
      /* padding: 20px; */

      box-shadow: 0px 4px 4px 0px #00000040;
      padding: 20px;
      background: #FAF5EFCC;
      border: 1px solid #3636361A;
      border-radius: 15px;
      margin-top: 20px;
    }
  }
  .file-input{
    display: none;
  }
  
  .save-btn{
    padding: 7px 15px;
    border-radius: 10px;
    color: #fff;
    background: #E4151B;
    border: 1px solid #E4151B;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 18px;
  }


  .errorText{
    font-weight: 400;
    font-size: 12px;
    color: #E4151B;
  }

  .mandotory{
    color: #E4151B;;
    /* font-size: 12px; */
    vertical-align: top;
    padding-left: 4px;
  }

  .forLabel {
    font-weight: 600 !important;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .cancel-btn{
    border: none;
    background: transparent;
    color: #000;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;

  }
  .cancel-btn:hover{
    color: #E4151B;
  }
  .titleLabel{
    font-weight: 500 !important;
    color: #8b8989;
margin-bottom: 5px;
margin-top: 0;
    /* margin-bottom: 10px; */
    white-space: nowrap;
    
  }