.chatContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  z-index: 1000;
}

.chatWindow {
  width: 430px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.minimized {
  height: 48px;
}

.maximized{
  height: 530px;
}
.edit-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.chatHeader {
  background-color: #FFE4DE;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 700;
  font-size: 24px;
}


.chatHeader-part {
  display: flex;
  align-items: center;
}

.chatHeader-image{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}


.chatHeader-name{
  font-size: 18px;
  font-weight: 600;
  color: #363636;
  margin-bottom: 0;
  
}


.message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;
}



.sent {
  flex-direction: row-reverse;
  justify-content: end;
  text-align: right;
}

.sent .message-content {
  background-color: #FFE4DE; 
  border-radius: 12px 12px 0 12px;
  position: relative;
}


.received {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.received .message-content {
  background-color: #f1f0f0; 
  border-radius: 12px 12px 12px 0;
}

.message-content {
  padding: 10px;
  max-width: 70%;
  word-wrap: break-word;
}


.message-text {
  font-size: 16px;
  color: #363636;
  margin-bottom: 4px;
}


.chatUpdateTime {
  font-size: 12px;
  color: #999;
  display: block;
  text-align: right;
  margin-top: 4px;
}


.message-image-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}




.emoji_picker_wrapper {
  position: absolute;
  z-index: 999; 
  left: 0;
  bottom: 20px;
}
.close_icon_emojis {
  position: absolute;
    top: 4px;
    right: 6px;
    width: 24px;
    height: 24px;
    z-index: 99;
    cursor: pointer;
    background-color: #213c92;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.message-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
  margin: 5px 0;
  position: relative;
}

.message-date::before, .message-date::after {
  content: "";
  flex: 1;
  border-top: 1px solid #ddd;
  margin: 0 10px;
}




.loader{
  height: 40px;
}
















.chatHeader .actions {
  display: flex;
  gap: 8px;
}

.chatHeader .actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}

.chatHeader .actions button:hover {
  color: #333;
}

.chatBody {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #ffffff;
}

.minimizedBtn {
  padding-right: 10px;
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.chatMessage {
  padding: 8px;
  font-size: 16px;
  /* background-color: rgba(33, 60, 146, 1); */
  background-color: #FFE4DE;

  /* box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  color: #363636;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  max-width: 292px;
}
.chatMessageOpposite {
  padding: 8px;
  font-size: 16px;
  /* background-color: rgba(136, 136, 136, 1); */
  background-color: #ffffff;

  /* box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  width: 292px;
  color: #000000;
  border-radius: 10px;
  max-width: 80%;
}

.chatUpdateTime {
  margin: 0px 5px 0px 10px;
  font-size: 12px;
  font-weight: 200;
  color: rgba(60, 60, 60, 1);
}

.chatMessageReceived {
  margin-bottom: 10px;
  padding: 8px;
  background-color: rgba(136, 136, 136, 1);
  color: white;
  border-radius: 10px;
  max-width: 80%;
  font-size: 14px;
}

.chatMessage.self {
  background-color: #d1ffd5;
  align-self: flex-end;
}

.chatInput {
  display: flex;
  /* flex-wrap: wrap; */
  padding: 5px;
  border-top: 1px solid #ddd;
  background-color: #FFE4DE;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chatInput input {
  width: 350px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-right: 10px;
}
  
  .chatInput input :focus-visible {
    outline: none;
}


.chatInput button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chatInput button:hover {
  background-color: #0056b3;
}

.minimizedChat {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.minimizedChat img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.icons img {
  margin: 10px;
  cursor: pointer;
}

.minimizedChat .name {
  font-size: 14px;
  color: #333;
}

.minimizedChat:hover {
  background-color: #ececec;
}

.profilePic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 6.02px 6.02px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

@media screen and (min-width:750px) and (max-width:1300px){
  .chatInput {
    display: flex;
    /* flex-wrap: wrap; */
    padding: 5px;
    border-top: 1px solid #ddd;
    background-color: #FFE4DE;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.chatWindow {
  width: 460px;
  /* height: 600px; */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed; 
  bottom: 20px; 
  right: 90px;
  z-index: 9999; 
}

.popup {
  position: absolute;
  top: 27px;
  right: 10px;
display: flex;
border: 1px solid #ddd;
background-color: white;
border-radius: 10px 0px 10px 10px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
z-index: 1000;
padding: 2px 5px 4px 5px;
}
.popupOption {
cursor: pointer;
}

.popupOption img {
margin-right: 8px;
}