.common-section {
  margin-top: 20px;
  .common-section-css {
    padding: 10px;
    /* border-radius: 12px; */
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    background: #fbf7f2;
    
    .imageLineHeight {
      width: 100%;
    } 
        .dropDownContainer{
      margin-left: 20px;
    }
    .icon-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    .icon-alignment1{
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 2px solid #213C921A;
      margin-bottom: 12px;
    }
    .educationIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
      cursor: pointer;
    }
    .grey-text {
      font-size: 16px;
      font-weight: 500;
      color: #888888;
    }
    .coin-count {
      font-size: 14px;
      font-weight: 600;
      color: #ffcc4d;
      margin-left: 5px;
    }
    .coin-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
    .edit-icon {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      cursor: pointer;
    }

    .add-icon{
      height: 24px;
      width: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
    .heading-text {
      font-size: 20px;
      font-weight: 700;
      color: #E4151B;
      
    }
    .image-size {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
    .heading-align {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .testscore-card{
      display: flex;
      flex-direction: column;
      gap: 1.5em;
    }

    .testscore-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .testscore-body{
      display: grid;
      grid-template-columns: 2fr 1fr;
      /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
      font-size: 16px;
      font-weight: 500;
      color: #363636;
    }

    .body-section-1{
      display: flex;
      align-items: center;
      gap: 7em;
      margin-bottom: 10px;

      .exam-type{
        font-weight: 700;
      }

      .exam-date{
        background-color: #363636;
        color: #FFFFFF;
        padding: 4px 10px;
        border-radius: 20px;
      }
    }

    .body-section-2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .exam-scores{
        display: flex;
        align-items: center;
        gap: 3em;

        .score{
          font-weight: 600;
        }
      }

      .overall-score{
        display: flex;
        align-items: center;
        gap: 1em;
        color: #E4151B;

        .score{
          font-weight: 600;
        }
      }
    }

    .body-section-3{
      display: flex;
      align-items: center;
      gap: 1em;

      .document-file{
        color: #E4151B;
      }
    }

    .testscore-divider{
      border: 1px solid #888888;
    }

    @media screen and (min-width:1150px) and (max-width:1300px){
      .testscore-body{
        grid-template-columns: 3fr 1fr;
      }
    }

    @media screen and (max-width:1149px){
      .testscore-body{
        display: block;
      }
    }
  }
}
.common-section {
  margin-top: 20px;
  .common-section-css {
    padding: 10px;
    border: 1px solid #213C921A;
    /* border-radius: 12px; */
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    /* background: #fff; */
    background: #fbf7f2;
    ;

    .heading-text {
      font-size: 20px;
      font-weight: 700;
      color: #E4151B;
      

    }
    .heading-align {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .image-size {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
    .coin-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }
    .edit-icon {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      cursor: pointer;
    }
    .over-view-icon {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }
    .languageIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .educationIcon-image-size {
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }
    .workIcon-image-size{
      height: 15px;
      width: 15px;
      margin-left: 5px;
      margin-right: 8px;
    }
    .coin-count {
      font-size: 14px;
      font-weight: 600;
      color: #ffcc4d;
      margin-left: 5px;
    }
    .blank-content-text {
      font-size: 16px;
      font-weight: 700;
      color: #363636;
    }
    .about-section {
      .about-section-text {
        font-size: 16px;
        font-weight: 600;
        color: #363636;
        word-wrap: break-word;
      }
    }
    .star-mark {
      color: #e82529;
      font-size: 16px;
      font-weight: 600;
    }
    .icon-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    .black_text {
      font-size: 16px;
      font-weight: 600;
      color: #363636;
    }
    .grey-text {
      font-size: 16px;
      font-weight: 500;
      color: #363636;
    }

    .added-content {
      display: flex;
      margin-bottom: 10px;
      gap: 0.5em;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .added-content-text {
      padding: 5px 15px;
      width: fit-content;
      margin-right: 20px;
      background: #213c92;
      box-shadow: 0px 4px 4px 0px #00000040;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      border-radius: 12px;
    }
    .corss-icon{
        height: 30px;
        position: absolute;
        top: -12px;
        right: 10px;
        cursor: pointer;
    }
    .div_position{
        position: relative;

    }
    .imageLineHeight{
      width: 100%;
    }
    .flex-container {
      display: flex;
      align-items: center;
    }
  }
}
.modal-container {
  .heading-text-modal{
  font-size: 25px;
  font-weight: 700;
  padding-left: 20px;
  color: #E4151B;
}
.modal-body{
  max-height: 450px;
  overflow-y: auto;
  padding: 20px;
  .forLabel{
    font-weight: 700 !important;
    margin-bottom: 10px;
    white-space: nowrap;

  }
  .titleLabel{
    font-size: 12px;
    font-weight: 500 !important;
    color: #8b8989;
margin-bottom: 5px;
margin-top: 0;
    /* margin-bottom: 10px; */
    white-space: nowrap;
    
  }
}
}
.modal-footer{
  display: flex !important;
  justify-content: center !important;
}
.update-button{
  cursor: pointer;
}

.errorText{
  font-weight: 700;
  font-size: 14px;
  color: #e82529;
}
.cross-icon img{
  width: 20px;
  cursor: pointer;
}
.required-asterisk {
  color: red; 
  margin-left: 2px;
}

.passport-modal{
  padding: 20px;
  margin: 0;
.passport-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
.passport-title{
    font-size: 24px;
    font-weight: 700;
    color: #E4151B;
}
.passport-crose-icon{
  cursor: pointer;
  .crose-icon-size{
    height: 40px;
    width: 40px;
    }
}
}
.passport-body{ 
.PassportNoLabel{
margin: 0 0 10px 5px;
font-size: 15px;
font-weight: 600 !important;
}
.CitizenshipLabel{
  margin: 5px 0px 10px 5px;
  font-size: 15px;
  font-weight: 600 !important;
}


.primary-button {
  height: 35px;
  margin-top: 10px;
  padding: 5px 15px;
  font-size: 14px;
}
.errorText{
  font-weight: 400;
  font-size: 14px;
  color: #e82529;
}
.PassportExpiryDateLabel{
margin: 0 0 10px 5px;
font-size: 15px;
font-weight: 600 !important;
}
}
}
.dropDownContainer{
  margin-left: 20px !important;
}


.option-default{
  display: none;
}
