

.post-item {
  /* background: #FAF5EFCC;
  border: 1px solid #36363633;
  border-radius: 8px; */
  margin-bottom: 15px;
  padding: 15px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); */
  width: 100%;
}

.post-title {
  font-size: 20px;
  font-weight: 600;
  /* color: #E4151B; */
}
.add-discussion-button{
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.post-description {
  font-size: 0.9rem;
  color: #363636;
}

.post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.post-comments {
  position: absolute;
  left:20px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 13px;
}

.post-comments img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.post-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 13px;
}

.post-share img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.add-discussion-container{
  padding: 10px ;
}
.submitButton{
  display: flex;
  justify-content: end;
}
.titleText{
  font-weight: 600;
  font-size: 16px;
}
.croseIconContainer{
  display: flex;
  justify-content: space-between;
}
.titleContainer{
    font-weight: 600;
    padding: 5px 0px 0px 0px;
    font-size: 24px;
    text-align: center;
    color: #E4151B;
  }


.croseIcon{    
  cursor: pointer;
}
.titleInputFelid{
  width: 100%;
  padding: 0px 10px 10px 10px;
  border: none !important;
  border-bottom: 2px solid #ccc  !important;
  font-size: 16px;
  outline: none;
  border-radius: 0;
}
.titleInputFelid:focus{
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #E4151B;
}

.pointer{
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}