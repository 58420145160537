.dashboardContainer {
    display: flex; 
  }
  
  .leftContainer {
    display: flex;
    flex-direction: column; 
    padding: 10px;
    gap: 30px;
    max-height: 80vh;
    overscroll-behavior: contain;
    overflow-y: auto;


    .marketing-brochure{
      border-radius: 12px;
    }
  }
  .sticy-header{
    position: sticky;
    top: 135px;
    z-index: 999;
  }
  .sticy-header1{
    position: sticky;
    top: 135px;
  }
  .rightContainer {
    display: flex;
    flex-direction: column; 
    padding: 10px;
    gap: 30px;
    max-height: 80vh;
    overscroll-behavior: contain;
    overflow-y: auto;
    .marketing-brochure{
      border-radius: 12px;
    }
  }
  .result-text{
font-size: 24px;
font-weight: 700;
color: #000000;

  }

  .sub-heading-text{
font-size: 20px;
font-weight: 600;
  }

  /* @media screen and (max-width:991px) {
    .oreder-chnages-middle{
      order: 1 !important;
    }
    .oreder-chnages-left{
      order: 2 !important;
    }
    .oreder-chnages-right{
      order: 3 !important;
    }
    
  } */

  @media screen and (max-width:991px) {
    .sticy-header{
      position: relative;
      top: unset;
      z-index: 9;
    }
    .leftContainer{
      max-height: 100%;
    }
    .rightContainer{
        max-height: 100%;
    }
    
  }