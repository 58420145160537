.header {
    /* border-bottom: 0 !important; */
    justify-content: space-between !important;
    position: relative;
    padding: 5px 15px !important;

    .title {
        font-size: 28px !important;
        font-weight: 800;
        color: #000000;
    }
    .heading-text-modal{    
        font-size: 25px;
        font-weight: 700;
        color: #E4151B;
      }
    .iconSizeForAboutModel{
        width: 50px !important;
        height: 50px !important;
    }
    .corss-icon {
        height: 20px !important;
        width: 20px !important;
        cursor: pointer;
    }
}

.body {
    padding: 20px;
    text-align: center;
}

.subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #333;
}

.features-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px;
}

.features-point {
    display: flex;
    align-items: center;
    gap: 1em;
}

.red-dot {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 60px;
}