.application-submit-container {
    padding: 20px 10px;

    .application-submit-section {
        display: flex;
        flex-direction: column;
        gap: 3em;

        .section-1 {
            display: flex;
            justify-content: space-between;

            .application-submit-heading {
                font-size: 24px;
                font-weight: 600;
                /* line-height: 32.68px;
                letter-spacing: -0.23999999463558197px; */
                color: #E82529;
            }

            .application-list-button {
                .back-to-appList {
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    padding: 7px 15px;
                    font-size: 16px;
                    font-weight: 500;
                    /* letter-spacing: -0.23999999463558197px; */
                }
            }
        }

        .section-2 {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            .submit-section {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .student-detail-section {
                    .student-name {
                        font-size: 22px;
                        font-weight: 800;
                        line-height: 32.68px;
                        letter-spacing: -0.23999999463558197px;
                        color: #363636;
                        margin-bottom: 10px;
                    }

                    .student-personal-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .student-data {
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: -0.23999999463558197px;
                            color: #363636;
                        }

                        .detail-block {
                            display: flex;
                            align-items: center;
                            gap: 1em;
                        }
                    }
                }

                .application-submit-button {
                    align-self: flex-end;
                    justify-self: flex-end;

                    .submit-btn {
                        box-shadow: 0px 4px 4px 0px #00000040;
                        border-radius: 10px;
                        padding: 7px 15px;
                        font-size: 18px;
                        font-weight: 500;
                        /* letter-spacing: -0.23999999463558197px; */
                    }

                    .submit-btn:disabled {
                        padding: 7px 15px !important;
                        border-radius: 10px !important;
                        font-size: 18px !important;
                        font-weight: 500 !important;
                        /* letter-spacing: -0.23999999463558197px; */
                    }
                }
            }

            .alert-text {
                font-size: 16px;
                font-weight: 700;
                color: #E82529;
                letter-spacing: -0.23999999463558197px;
                text-align: end;
            }
        }

        .section-3 {

            .application-detail-heading {
                font-family: Poppins;
                font-size: 22px;
                font-weight: 800;
                line-height: 32.68px;
                letter-spacing: -0.23999999463558197px;
                color: #363636;
                margin-bottom: 10px;
            }

            .application-detail-table {
                /* position: relative; */

                .customTable {
                    width: 100%;
                    border-collapse: separate; 
                    border-spacing: 0;
                    font-size: 16px;
                    text-align: left;
                    border-radius: 12px;
                    overflow: hidden; 
                    border: 1px solid #3636361A;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background: #FAF5EFCC;
            
            
                }
                
                .customTable thead th {
                    background: #F2E7DE;
                    color: #363636;
                    font-size: 14px;
                    font-weight: 600;
                    border-right: 1px solid #36363633;
                    border-left: 1px solid #36363633;
            
                }
                
                .customTable thead th:first-child {
                    border-top-left-radius: 12px; 
                }
                
                .customTable thead th:last-child {
                    border-top-right-radius: 12px;
                }
                
                .customTable tbody tr:last-child td:first-child {
                    border-bottom-left-radius: 12px; 
                }
                
                .customTable tbody tr:last-child td:last-child {
                    border-bottom-right-radius: 12px; 
                }
                
                .customTable tbody tr {
                    border-bottom: 1px solid #dddddd;
                }
                
                .customTable tbody tr:nth-of-type(even) {
                    background: #FAF5EFCC;
            
                }
                
                .customTable tbody tr:last-of-type {
                    border-bottom: 2px solid #36363633;
                }
                
                .customTable tbody td {
                    border-left: 1px solid #36363633;
                    text-align: center;
                    font-size: 16px;
                    vertical-align: middle;
                    background: #FAF5EFCC;
                    font-weight: 400;
            
                }
                
                .customTable tbody tr:hover {
                    background: #FAF5EFCC;
            
                }
                
                .customTable tbody td a:hover {
                    text-decoration: underline;
                }
                
                
                .customTable tbody td:last-of-type {
                    border-right: none;
                }
                .customTable th {
                    text-align: center; 
                    vertical-align: middle; 
                }
                
                .thAndIconContainer {
                    display: flex;
                    justify-content: center; 
                    align-items: center; 
                }
                
                .columnText {
                    margin-right: 8px; 
                    white-space: noWrap;
                }

                .action-block {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;

                    .payment-modal-btn {
                        box-shadow: 0px 4px 4px 0px #00000040;
                        border-radius: 10px;
                        padding: 7px 15px;
                        font-size: 16px;
                        font-weight: 500;
                        /* letter-spacing: 0.76px; */
                    }

                    .fee-breakup-drop-down {
                        font-size: 16px;
                        font-weight: 700;
                        letter-spacing: -0.23999999463558197px;
                        position: relative;
                    }

                    .pending-alert {
                        font-size: 16px;
                        font-weight: 600;
                        color: #E82529;
                        letter-spacing: -0.23999999463558197px;
                    }

                    .pending-success-alert {
                        font-size: 16px;
                        font-weight: 600;
                        color: #34bf49;
                        letter-spacing: -0.23999999463558197px;
                    }

                    .upload-modal-btn {
                        box-shadow: 0px 4px 4px 0px #00000040;
                        border-radius: 10px;
                        padding: 7px 15px;
                        font-size: 16px;
                        font-weight: 500;
                        /* letter-spacing: 0.76px; */
                    }

                    .application-fee-alert {
                        font-size: 16px;
                        font-weight: 700;
                        color: #E82529;
                        letter-spacing: -0.23999999463558197px;
                    }
                }

                .fee-breakup-card-wrapper {
                    position: absolute;
                    z-index: 9999;
                    visibility: visible;
                }

                .fee-breakup-card {
                    width: 100%;
                    max-width: 400px;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    border: 0;
                    border-radius: 8px;

                    .fee-breakup-card-header {
                        background-color: #E82529;
                        padding: 5px 10px;
                        font-size: 20px;
                        font-weight: 600;
                        color: #FFFFFF;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    .fee-breakup-card-body {
                        padding: 8px;

                        .fee-breakup-sections {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 1em;

                            .fee-breakup-title {
                                font-size: 16px;
                                font-weight: 500;
                                color: #363636;
                            }

                            .fee-breakup-subtitle {
                                font-size: 16px;
                                font-weight: 400;
                                color: #363636;
                            }
                        }

                        .fee-breakup-divider {
                            margin: 10px 0;
                            border: 1px solid #E82529;
                            opacity: 1;
                        }
                    }
                }

                /* @media (min-width: 768px) {
                    .fee-breakup-card {
                        width: 90%;
                        left: 5%;
                    }
                } */
            }
        }
    }
}