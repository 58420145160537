.composePost_main_container {
  background: #FFFFFF;
  border-bottom: 2px solid #3636361A;
}

.composePost_block {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 15px 0px;
}

.post_input {
  padding: 15px 25px;
  border-radius: 22px;
  font-size: 18px;
  font-weight: 600;
  color: #363636;
  text-align: left;
  background: #FF7C8480;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.post_input:focus {
  border-color: blue; 
  outline: none;
}

.button_container {
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 0 20px;

  .media-btn-block {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5em;
  }

  .media-button {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: #E4151B;
    padding: 0
  }

  .media-button:hover {
    color: #E4151B;
  }

  .media-button:active {
    color: #E4151B;
  }
}

.post_button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 15px;
  background-color: #213c92;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.post_button:hover {
  background-color: #213c92;
}

.post_button img {
  width: 24px; 
  height: 24px;
}

@media (max-width: 600px) {
  .composePost_block {
    padding: 10px;
  }

  .post_input {
    font-size: 18px;
    padding: 8px;
  }

  .button_container {
    gap: 10px;
  }

  .post_button {
    width: 100%;
    font-size: 20px;
    padding: 8px 15px;
  }
}

@media screen and (max-width:570px) {
  .post_button{
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .button_container{
    display: block;
  }
  
}