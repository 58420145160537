.container {
    width: 100%;
    /* box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    background: #FAF5EFCC;
    border: 1px solid #3636361A;
    border-radius: 15px; */
    margin-top: 20px;
  }
  .ModelContainer{
    .title {
        font-size: 20px !important;
        font-weight: 600;
        color: #E4151B;
        /* text-align: center; */
        margin-bottom: 10px;
    }
    .headerContainer {
        padding: 10px 5px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    border-bottom: 0 !important;
    .corssIcon {
        /* height: 48px; */
        padding: 10px;
        cursor: pointer;
    }
  }
  .btnContainer{
    display: flex;
    padding: 25px;
    justify-content: center;
    gap: 2em;
    .btnUpload{
        border-radius: 12px;
        border: 1px solid #E4151B;
        color: #F2F2F2;
        background-color: #E4151B;
      }
      .btnUpload:hover{
        background: #E4151B;
        color: #F2F2F2;
    }
    button{
        /* box-shadow: 0px 4px 4px 0px #00000040; */
        padding: 7px 15px;
    }
}
  .uploadBoxContainer{
    border: 1px solid black;
    padding: 20px;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    border-radius: 12px;
  }
  }
  .customTable {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0;
    font-size: 16px;
    text-align: left;
    border-radius: 12px;
    overflow: hidden; 
    border: 1px solid #3636361A;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FAF5EFCC;


}

.customTable thead th {
    background: #F2E7DE;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    border-right: 1px solid #36363633;
    border-left: 1px solid #36363633;

}

.customTable thead th:first-child {
    border-top-left-radius: 12px; 
}

.customTable thead th:last-child {
    border-top-right-radius: 12px;
}

.customTable tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px; 
}

.customTable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px; 
}

.customTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.customTable tbody tr:nth-of-type(even) {
    background: #FAF5EFCC;

}

.customTable tbody tr:last-of-type {
    border-bottom: 2px solid #36363633;
}

.customTable tbody td {
    border-left: 1px solid #36363633;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    background: #FAF5EFCC;
    font-weight: 400;

}

.customTable tbody tr:hover {
    background: #FAF5EFCC;

}

.customTable tbody td a:hover {
    text-decoration: underline;
}


.customTable tbody td:last-of-type {
    border-right: none;
}
.customTable th {
    text-align: center; 
    vertical-align: middle; 
}

.thAndIconContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.columnText {
    margin-right: 8px; 
    white-space: noWrap;
}
  .actionBtn {
    color: #E4151B;
    cursor: pointer;
    margin-right: 10px;
  }
  .centerIcon{
    vertical-align: middle;
    text-align: center;
  }
  .uploadBtn{
    background-color:#E4151B;
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px !important;
    color: white;
  }
.positionRelative{
  position: absolute;
  right: 0;
}
.popContainer{
    position: absolute;
    padding: 10px;
    right: 53px;
    bottom: -60px;
    border: 1px solid #36363620;
    border-radius: 12px;
    background: #FFFFFF;
    display: grid;
    gap: 1em;
}
.customTable tbody tr:last-child td:last-child .popContainer {
    bottom: -8px ;
    border-radius: 12px;
}
.btnText{
    text-wrap: nowrap;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px;
}
.IConAndText{
    display: flex;
    cursor: pointer;
}
.icon{
  cursor: pointer;
  height: 30px;
}
.actionICon{
    height: 30px; 
}
.noData{
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #363636;
}