.container {
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    background: #FAF5EFCC;
    border: 1px solid #3636361A;
    border-radius: 15px;
    margin-top: 20px;
}

.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
    .secondaryBtn {
        background-color: #E4151B;
        color: #F2F2F2;
        border: 0px;
        border: none;
        border-radius: 8px;
    }
    
    .secondaryBtn:hover {
        background-color: #E4151B;
        color: #fff;
        border: none;
    }
}

.customTable {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0;
    font-size: 16px;
    text-align: left;
    border-radius: 12px;
    overflow: hidden; 
    border: 1px solid #3636361A;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FAF5EFCC;


}

.customTable thead th {
    background: #F2E7DE;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    border-right: 1px solid #36363633;
    border-left: 1px solid #36363633;

}

.customTable thead th:first-child {
    border-top-left-radius: 12px; 
}

.customTable thead th:last-child {
    border-top-right-radius: 12px;
}

.customTable tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px; 
}

.customTable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px; 
}

.customTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.customTable tbody tr:nth-of-type(even) {
    background: #FAF5EFCC;

}

.customTable tbody tr:last-of-type {
    border-bottom: 2px solid #36363633;
}

.customTable tbody td {
    border-left: 1px solid #36363633;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    background: #FAF5EFCC;
    font-weight: 400;

}

.customTable tbody tr:hover {
    background: #FAF5EFCC;

}

.customTable tbody td a:hover {
    text-decoration: underline;
}


.customTable tbody td:last-of-type {
    border-right: none;
}
.customTable th {
    text-align: center; 
    vertical-align: middle; 
}

.thAndIconContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.columnText {
    margin-right: 8px; 
    white-space: noWrap;
}

.forTextAreaContainer {
    box-shadow: 0px 4px 4px 0px #00000040;
}

.note-text-heading{
    color: #E4151B;
    font-size: 18px;
    font-weight: 600;
}