.container {
    margin: 10px 0px;
    .profileImgContainer {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .profileImgDesign {
        border: 3px solid #E4151B;
        border-radius: 50%;
        background-color: #D9D9D9;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        overflow: hidden;
    }

    .backgroundImgContainer {
        background-color: #D9D9D9;
   
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
            border-radius: 12px 12px 0px 0px;
            width: 100%;
            height: 250px;
        /* height: auto; */
        border: 4px solid transparent;
        border-top-color: #E4151B;
        border-bottom-color: #E4151B;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .textSizeAndColor {
        color: black;
        margin-top: 10px;
        font-weight: 600;
    }

    .circleICon {
        margin: 35px 0px 0px 0px;
    }

    .boxIcon {
        margin: 0px 50px 0px 0px;
    }

    .backgroundImgMiniContainer {
        margin: 40px;
    }

    .extraTextSizeAndColor {
        margin-top: 10px;
        font-size: 20px;
    }

    .buttonSubmit {
        margin: 20px;
        padding: 5px 30px;
    }

    .tempProfileImage{
        width: 100%;
        height: 250px;
        border-radius: 50%;
        background-color: #D9D9D9;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .backgroundImgMiniContainerImage {
        cursor: pointer;
    }
    .tooltip {
        visibility: hidden; 
        font-size: 13px;
        width: 5px; 
        color:black;
        text-align: center; 
        /* border-radius: 5px;  */
        /* padding: 5px; */
        position: absolute;
        z-index: 1;
        right: 50%;
        top: 30%;
        transform: translateY(-100%);
        opacity: 0;
        transition: opacity 0.3s;
        background: none; /* Remove background */
        border: none; /* Remove border */
        padding: 0; /* Remove padding */
      }
    
    
      .tooltip-container{
        text-align: center;
        cursor: pointer;
      }
      .tooltip-container:hover .tooltip {
        visibility: visible; 
        opacity: 1; 
      }
}