.middleContainer {
  position: sticky;
  top: 117px;
  /* z-index: 100; */
  overflow: visible;

  z-index: 99;
}
.postsContainer {
  max-height: 100vh;
  overflow-y: auto;
}
  
  @media (max-width: 768px) {
    .postsContainer {
      padding: 15px; 
    }
  
  }
  
  @media (max-width: 480px) {
  
    .postsContainer {
      padding: 10px; 
    }
  }