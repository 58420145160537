
*{
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins';
}
body{
  background: #fff !important;
}
.panel-box {
  .main-content {
    min-height: calc(100vh - 120px);
  }
}
.main-page{
  padding: 10px;
  background: #FFFFFF;
}
.primary-button {
  border-radius: 10px;
  /* background: #213c92; */
  background: #E4151B;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: 0;
  padding: 7px 15px;
}

.secondary-button {
  border-radius: 10px;
  border: 1px solid #E4151B;
  font-size: 14px;
  font-weight: 600;
  color: #E4151B;
  padding: 7px 15px;
}

button:disabled {
  border-radius: 10px !important;
  background: #888888 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  padding: 7px 15px !important;
  border: 1px solid #888888 !important;
}
.row{
  width: 100%;
  margin-left: 0 !important;
  /* margin-right: 0 !important; */
}
.form-group input:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #E4151B;
  background-color: transparent;

  
}
.form-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  outline: none;
  border-radius: 0;
  background-color: transparent;
}

.form-group select {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  outline: none;
  border-radius: 0;
  /* background-color: transparent; */
  --bs-form-select-bg-img: url(../Assets/Logo/drop-arrow.svg) !important;
  text-overflow: ellipsis !important;
  padding-right: 30px;
}

.form-group select:disabled{
  background-color: #e9ecef;
}

.form-group .form-select:focus{
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #E4151B;
  /* background-color: transparent; */
  --bs-form-select-bg-img: url(../Assets/Logo/drop-arrow.svg) !important;
}
.form-group .form-select{
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font-size: 16px;
  outline: none;
  border-radius: 0;
  text-overflow: ellipsis !important;
  padding-right: 30px !important;

  /* background-color: transparent; */
  --bs-form-select-bg-img: url(../Assets/Logo/drop-arrow.svg) !important;
}
.form-control:focus {
  box-shadow: none !important;
  /* background-color: transparent; */
  border-color: #E4151B !important;
}

.error-alert{
  color: #E4151B;
  text-align: left;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0px;
}

.required{
  color: #E4151B;
}

.sticky-header{
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}
.filter-section .accordion-header {  /* Replace with your library's class */
  display: flex !important;  /* !important might be needed to override */
  justify-content: space-between !important;
  align-items: center !important;
}
/* Accordian Classes Starts here for the Search Component*/
.filter-section .accordion-button {
  background-color: #FFE4DE !important;
  color: #E4151B !important;
  font-weight: bold !important;
}

.filter-section .accordion-button:focus {
box-shadow: none !important;
}

.filter-section .accordion-button:not(.collapsed) {
  background-color: #FFE4DE!important;
  color: #E4151B !important;
}

.filter-section .accordion-button {
 /* display: none !important; */
 --bs-accordion-btn-icon: url(../Assets/Logo/ic-down-arrow 3.svg) !important;
 --bs-accordion-btn-icon-width: 1.50rem !important;
}

.filter-section .accordion-button {
 /* display: none !important; */
 --bs-accordion-btn-active-icon: url(../Assets/Logo/ic-down-arrow 3.svg) !important;
 --bs-accordion-btn-icon-width: 1.50rem !important;
}

.filter-section .accordion-body {
  padding: 0.5rem 1rem !important;
  background-color: #FFE4DE!important;
  max-height: 300px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.filter-section .form-check {
  margin-bottom: 0.5rem !important;
}

.filter-section .form-check-input {
  margin-right: 0.5rem !important;
}

.filter-section .form-check-label {
  color: #363636 !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.filter-section .accordion-item {
  border: none !important;
}


.filter-section .accordion * {
  box-sizing: border-box;
}

.filter-section .accordion-header {
  border-bottom: 1px solid #E4151B4D !important;
  display: flex !important;  /* !important might be needed to override */
  justify-content: space-between !important;
  align-items: center !important;

}
/* Accordian Classes ends here for the Search Component*/

.filter-section{
  box-shadow: 2px 2px 9px 0px #00000040;
  border: 1px solid #F7A6A4;
  border-radius: 12px;
  background: #FFE4DE;
  overflow: hidden;

}
/* Modal Class for the Search Component*/
.search-modal{
  padding: 0 !important;
}
/* Modal Class for the Search Component*/


/* Checkbox Class starts here for the ComparePrograms Component*/
.compare-program-checkbox {
  .form-check-input[type=checkbox]{
    background-color: #F2F2F2;
    border: 2px solid #E4151B;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  /* .form-check-input:checked[type=checkbox]{
    background-image: url("../Assets/Logo/checbox-blue-tick.svg");
    --bs-form-check-bg-image: url("../Assets/Logo/checbox-blue-tick.svg");
  } */
}
/* Checkbox Class ends here for the ComparePrograms Component*/

::-webkit-scrollbar{
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track{
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb{
  background-color: #D9D9D9;
  border: 3px solid #FFFFFF;
  border-radius: 12px;
}

.dashboard-section{
  ::-webkit-scrollbar{
    width: 12px;
    height: 12px;
    visibility:hidden !important;
  }
  
  ::-webkit-scrollbar-track{
    background-color: transparent !important;
    visibility: hidden !important;
  }
  
  ::-webkit-scrollbar-thumb{
    background-color: #D9D9D9;
    border: 3px solid #FFFFFF;
    border-radius: 12px;
    visibility: hidden !important;
  }
}
.ql-editor{
  min-height: 90px !important;
  padding-top: 20px !important;
}
.ql-snow{
    border: none !important;
  }
.ql-editor:focus-visible {
    outline: none !important;
}
/* Target the placeholder text */
.ql-editor.ql-blank::before {
  font-weight: 700 !important;  /* Set font weight to 700 */
  color: #363636 !important;
  font-style: normal !important;       /* Optional: Adjust color of the placeholder */
}
.ql-snow.ql-toolbar .ql-picker-label:hover{
  color: #E4151B !important;
}
.ql-snow.ql-toolbar button:focus .ql-fill{
fill: #E4151B !important;
}
.ql-snow.ql-toolbar button:focus .ql-fill:hover{
  fill: #E4151B !important;
  }
  .ql-snow.ql-toolbar button:hover{
    color: #E4151B !important;
    svg{
      fill: #E4151B !important;
    }

  }
  .ql-snow.ql-toolbar button:focus{
    color: #E4151B !important;
  }


.css-1p3m7a8-multiValue{
  background-color: #E4151B !important;
  border-radius: 5px !important;
  align-items: center;

}    

.css-v7duua{
  height: 20px !important;
  width: 20px !important;
  border-radius: 50% !important;
  svg{
    fill: #fff;
    stroke: #fff;
  }
}
.css-9jq23d{
  color: #fff !important;
}
.css-v7duua:hover {
  background-color: #fff !important;
  color: #E4151B !important;
  svg{
    fill: #E4151B;
    stroke: #E4151B;
  }
}
.css-15lsz6c-indicatorContainer{
  cursor: pointer;
}
/* Select Field CSS */
.country-flag-block .css-13cymwt-control{
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
  border-radius: 0 !important;
}



/*pagination css*/

.page-link:focus {
  color:#E4151B !important
}
.active>.page-link, .page-link.active{
  background-color: #E4151B !important;
  border-color:#E4151B!important;
  color: #ffff !important;
}

.page-link {
  color:#E4151B !important
}
.inputnumber-filed {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield; 
  }
}
.form-check-input:checked{
  background-color: #E4151B !important;
  border-color: #E4151B !important;
}

.text-danger{
  color: #E4151B !important; 
}

input[type="radio"] {
  accent-color:#E4151B !important;
}

label{
  color: #363636 !important;
  font-weight: 500 !important;
}

.epr_x558go:focus{
  border: 1px solid #E4151B !important;
}

.alert-warning{
  background: #F2E7DE !important;
  border: 1px solid #F2E7DE !important;
  color: #363636 !important;
}

.drop-down-icon{
  .dropdown-toggle::after{
    display: none;
  }

  .btn.show{
    background-color: #FF7C8480;
    border: none;
    box-shadow: none;
  }
}

.css-wkxf5w-control{
  background-color: transparent !important;

}
.aichat-position{
  position: fixed;
  bottom: 24px;
  right: 30px;
  z-index: 9999;
  width: 400px;
}

.dropdown-toggle::after {
  display: inline-block !important;
  margin-left: 0 !important;
  vertical-align: 0 !important;
  content: "" !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}
.drop-menu{
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  width: fit-content !important;
}
.dropdown-menu.show{
  z-index: 99 !important;
}
.dropdown-item:active {
  background-color:transparent !important;
  color: #E4151B !important;
  text-decoration: none !important;
}
.dropdown-item:hover {
  background-color:transparent !important;
  color: #E4151B !important;
  text-decoration: none !important;
}
.dropdown-item:focus{
  background-color:transparent !important;
}

.tooltip-inner{
  background-color:#F2F2F2 !important;
  box-shadow: 0px 2px 2px 0px #00000040 !important;
    border: 1px solid #3636361a !important;
}
.bs-tooltip-end .tooltip-arrow::before{
  border-right-color: #E4151B !important;
}
.bs-tooltip-top .tooltip-arrow::before{
  border-top-color: #E4151B !important;
}

.bs-tooltip-start .tooltip-arrow::before{
  border-left-color: #E4151B !important;
}
.bs-tooltip-bottom .tooltip-arrow::before{
  border-bottom-color: #E4151B !important;
}