.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;  */
    /* position: fixed; */
    /* left: 0;
    right: 0;
    top: 0; */
    z-index: 9999;
    /* background: #635555bf; */
 
  }
  .spinner-border {
    --bs-spinner-width: 2rem !important;
    --bs-spinner-height: 2rem !important;
    --bs-spinner-vertical-align: -0.125em !important;
    --bs-spinner-border-width: 0.3em !important;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
     border: var(--bs-spinner-border-width) solid #E4151B !important;
    border-right-color: transparent !important;
}