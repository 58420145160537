.activeMenuBar {
  color: #E4151B;
  border-radius: 2px;
}
.alphaComp {
  font-size: 20px;
  cursor: pointer;
}


.connection-container {
  margin: 25px 20px;
}
.disabled-btn{
  pointer-events: none;
  background: transparent;
}
.connection-section {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em;
}

.connection-left-section {
  border-radius: 12px;
  padding: 15px 0px;
  box-shadow: 2px 2px 9px 0px #00000040;
  border: 1px solid #F7A6A4;
  background: #FFE4DE;

  .connection-left-content{
  margin-top: 10px;

  .alphaComp{
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    padding: 5px 30px;
    cursor: pointer;
    
  }
  .alphaComp:hover{
    color: #E4151B;
  }

  }
}

.connection-right-section {
  padding-right: 0 !important;
}

.search-bar-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;

  .search-bar{
    position: relative;
    width: 40%;
  }

  .input-serch {
    min-height: 40px;
    width: 100%;
    background: #FF7C8480;
    border: none;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 40px 10px 10px;
    box-sizing: border-box;
    color: #363636;
  }

  .input-serch:focus-visible {
    outline: #F7A6A4 auto 1px;
  }

  .close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .close-button:focus{
    box-shadow: none;
  }

  .search-value {
    position: absolute;
    background: #FFF;
    border: 1px solid #213C92;
    padding: 10px 20px;
    border-radius: 12px;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    width: -webkit-fill-available;
    z-index: 99;
  }

  .serch-button:hover{
    background-color: #213C92;
    border-color: #213C92;
  }
}
