.container{
    margin: 0;
    padding: 0 10px;
    .heading{
        font-size: 24px;
        font-weight: 600;
       color: #E4151B;
    }
    .backButton{
        cursor: pointer;
        img{
            padding-right: 10px;
        }
    }
    .customTable {
        width: 100%;
        border-collapse: separate; 
        border-spacing: 0;
        margin: 20px 0;
        font-size: 16px;
        text-align: left;
        border-radius: 12px;
        overflow: hidden; 
        border: 1px solid #3636361A;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #FAF5EFCC;


    }
    
    .customTable thead th {
        background: #F2E7DE;
        color: #363636;
        font-size: 14px;
        font-weight: 600;
        border-right: 1px solid #36363633;
        border-left: 1px solid #36363633;

    }
    
    .customTable thead th:first-child {
        border-top-left-radius: 12px; 
    }
    
    .customTable thead th:last-child {
        border-top-right-radius: 12px;
    }
    
    .customTable tbody tr:last-child td:first-child {
        border-bottom-left-radius: 12px; 
    }
    
    .customTable tbody tr:last-child td:last-child {
        border-bottom-right-radius: 12px; 
    }
    
    .customTable tbody tr {
        border-bottom: 1px solid #36363633;
    }
    
    .customTable tbody tr:nth-of-type(even) {
        background: #FAF5EFCC;

    }
    
    .customTable tbody tr:last-of-type {
        border-bottom: 2px solid #36363633;
    }
    
    .customTable tbody td {
        border-left: 1px solid #36363633;
        text-align: center;
        font-size: 16px;
        vertical-align: middle;
        background: #FAF5EFCC;
        font-weight: 400;

    }
    
    .customTable tbody tr:hover {
        background: #FAF5EFCC;

    }

    .customTable tbody td a:hover {
        text-decoration: underline;
    }
    
    
    .customTable tbody td:last-of-type {
        border-right: none;
    }
    .customTable th {
        text-align: center; 
        vertical-align: middle; 
    }
    
    .thAndIconContainer {
        display: flex;
        justify-content: center; 
        align-items: center; 
    }
    
    .columnText {
        margin-right: 8px; 
        white-space: noWrap;
    }
    .icon{
        cursor: pointer;
    }
    .actionBtn{
        cursor: pointer;
        text-decoration: underline;
        color: #6376B2;
    
    }
}
.ModelContainer{
    .title {
        font-size: 20px !important;
        font-weight: 600;
        color: #3C3C3C;
        text-align: center;
    }
    .headerContainer {
    border-bottom: 0 !important;
    text-align: right;
    .corssIcon {
        /* height: 48px; */
        cursor: pointer;
        padding: 10px;
    }
}
.btnContainer{
    display: flex;
    padding: 25px;
    justify-content: center;
    gap: 2em;
    .btnNo{
        border-radius: 12px;
        background: #F2F2F2;
        border: 1px solid #213C92;
        color: #213C92;
    }
    .btnYes{
        border-radius: 12px;
        background: #213C92;
        color: #FFFFFF;
        ;
    }
    button{
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 10px 30px;
    }
}
}