.main-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  .row_hieght {
    height: 100%;
    .col_img {
      display: flex;
      align-items: center;
      background: #e82529;
      .left-img {
        width: 100%;
      }
    }
    .right_col {
      display: flex;
      justify-content: center;
      align-items: center;
      background:#FFFFFF;
    }
  }

  .sign_up-box {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 3em;
    width: 100%;
    .header {
      align-self: center;
      font-size: 32px;
      font-weight: 700;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }

    .form-section {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .label {
        font-size: 14px;
        font-weight: 700 !important;
        margin: 0;
      }

      .form-field {
        display: flex;
        column-gap: 0.5em;
      }

      .form-content {
        display: flex !important;
        flex-direction: column;
        gap: 1em;
      }
    }

    .form-section .submit-btn {
      width: 100%;
      border: 1px solid rgba(0, 90, 190, 1);
      color: #fff;
      border-radius: 5px;
      padding: 7px;
      font-size: 14px;
      font-weight: 600;
      background: rgba(0, 90, 190, 1);
    }

    .signin-section {
      align-self: center;
      font-size: 15px;
      font-weight: 400;
      color: black;

      a {
        text-decoration: none !important;
      }

      .signin-text {
        font-size: 15px !important;
        font-weight: 700 !important;
        color: rgba(0, 90, 190, 1) !important;
      }
    }
    .resetPasswordText{
      font-weight: 700;
      text-wrap: nowrap;
    }
    .google-signin-section {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .google-signin-section-text {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 12px;

        hr {
          width: 32%;
        }
      }

      .google-signin-section-btn {
        .google-btn {
          width: 100%;
          border: 1px solid rgba(0, 90, 190, 1);
          color: #fff;
          border-radius: 5px;
          padding: 7px;
          font-size: 14px;
          font-weight: 600;
          background: rgba(0, 90, 190, 1);

          .icon_G {
            background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .terms-and-policy {
      font-size: 14px;
      text-align: center;
      padding: 0 30px;
    }

    .input-with-icon {
      position: relative;

      .input-with-icon input {
        padding-right: 30px;
      }

      .icon-container {
        position: absolute;
        font-size: 17px;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        cursor: pointer;

        .eyeicon {
          font-size: 15px;
        }
      }
    }
    .otp_section {
      text-align: center;

      .otp_input {
        display: flex;
        justify-content: center;
      }
      .timer {
        font-size: 16px;
        font-weight: 700;
        color: #202020;
        margin-top: 30px;
      }
      .change_auth {
        font-size: 16px;
        font-weight: 400;
        color: #202020;
        margin-top: 20px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.submit-btn:disabled {
  background-color: rgba(39, 39, 42, 0.9);
  color: #fff;
}
.backToSignIn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #363636;
}

.backToSignIn:hover{
  color: #e82529;
}
.backToSignInParent {
  text-decoration: none;
}
.button_common {
  background-color: olivedrab;
  color: white;
  padding: 15px 30px;
  border: none;
  font-size: 22px;
  border-radius: 15px;
  margin: 1rem;
  width: 90%;
}
.error {
  color: red;
  text-align: left;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0px;
}

.password-validation {
  margin-top: 30px;
  .validation-heading,
  span {
    font-weight: 600;
  }
}

.authorization-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .authorization-content {
    .authorization-option {
      display: flex;
      align-items: center;
      gap: 0.5em;
      margin-bottom: 10px;

      .radioInput {
        margin: 0;
        padding: 0;
      }
      input[type="radio"] {
        height: 22px;
        width: 22px;
        margin-right: 5px;
        accent-color: #213c92;
      }
    }

    .authorization-option label {
      display: grid;
      gap: 5px;
      grid-template-columns: auto 1fr;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  .verify-message {
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
  }
}

.primary_button {
  width: 100%;
}
.heading {
  font-size: 20px;
  font-weight: 700;
  color: #202020;
}

.radioInput:checked {
  background-color: #213c92 !important;
  border-color: #213c92 !important;
}
.otp_Input {
  height: 100px !important;
}

@media screen and (max-width: 767px) {
  .col_img {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .col_img {
    width: 51% !important;
  }
  .right_col {
    width: 49% !important;
  }
}
