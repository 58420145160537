.data-section {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  
  .add-more-btn {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background: #E4151B;
    border-radius: 12px;
    border: 1px solid #E4151B;
    padding: 7px;
  }
  .ModelContainer {
    .headerContainer {
      /* border-bottom: 0 !important; */
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;
      padding-bottom: 25px;
      border-bottom: 1px solid #36363633;
      margin-bottom: 20px;
      .title span{
        font-size: 24px;
        font-weight: 700;
        /* padding-left: 20px; */
        color: #E4151B;
      }
      .corssIcon {
        height: 20px;
        cursor: pointer;
      }
    }
    .iconSize {
      cursor: pointer;
    }
  }
  
  
  .ModelContainer, .mainContainer{
    margin: 10px 20px;
  }
  
  .addBtnAlgin {
    display: flex;
    justify-content: center;
    align-items: center !important;
  }
  
  .submit_btn{
    text-align: center;
    padding-right: 10px;
    .submit_btn_text{
      background-color: #E4151B;
      border: none;
    }
    .submit_btn_text:hover{
      background-color: #E4151B;
      border: none;
    }

    }
  
    .customTable {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
    }
    
    .customTable th {
      background-color: #E4151B !important;
      color: white !important;
      padding: 10px !important; 
      border-right: 1px solid #c77e81;
      border-left: 2px solid #c77e81; 
      font-weight: bold;
    }
    
    .customTable td {
      padding: 10px;
      border: 1px solid #ddd; 
      border-radius: 8px; 
      background-color: #f9f9f9;
      text-align: left;
      font-size: 16px !important;
    }
    
    .customTable tbody tr:hover {
      background-color: #f1f1f1;
    }
    
    .customTable tbody td {
      font-size: 14px; 
    }
  
    .customTable thead th:last-child {
      text-align: center;
      border-radius: 0px 0px 0px 0px;
    }
    .customTable tbody td:last-child {
      text-align: center;
      border-radius: 0px 0px 0px 0px;
    }
    
    .iconSize {
      cursor: pointer; 
      width: 20px; 
      height: 20px;
    }
    
  
   .option-default{
    display: none;
  }
  
  
  
  
  /* student list cards */
  
  .formBackgroundEducationInfoMore {
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .educationCard {
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    /* padding: 15px; */
    /* background-color: #fff; */
    margin-bottom: 20px;
  }
  
  .educationCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .textOverflow {
    font-size: 18px;
    font-weight: 600;
    color: #E4151B;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .actions {
    display: flex;
  }
  
  .iconHeightUpdate,
  .iconHeightDelete {
    background: none;
    border: none;
    cursor: pointer;
  }
  .contentArea{
    padding: 10px;
  }
  .mainWrapper {
    margin-top: 10px;
    border: 2px solid #E4151B;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #00000040;
  
  }
  
  .valueWrapper {
    margin-bottom: 10px;
    display: flex;
  }
  
  .headingLabel {
    font-weight: bold;
    white-space: nowrap;
  }
  
  .headingValue {
    margin-left: 10px;
    color: #E4151B;
    font-weight: 600;
  }
  
  .tableArea {
    margin-top: 10px;
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .educationTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableHeading {
    /* background-color: #007bff; */
    background-color: #E4151B;
    color: white;
    padding: 10px;
    border-right: 1px solid #5169B5;
    border-left: 2px solid #5169B5;
  }
  
  .tableData {
    padding: 10px;
    border: 1px solid #ddd;
  }
  /* 
  .tableData :first-child{
    border-right: 1px solid #5169B5;
   border-left: 2px solid #5169B5;
  } */
  .form-select:focus {
    box-shadow: none;

  }
  .select-truncate {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .form-select{
    position: relative;
    border-bottom: 1px solid !important;
    outline: 0;
    border: 0;
    padding-right: 30px;
    border-radius: 0;
  }

  .errorText{
    font-weight: 400;
    font-size: 12px;
    color: #E4151B;
  }

  .mandotory{
    color: #E4151B;;
    /* font-size: 12px; */
    vertical-align: top;
    padding-left: 4px;
  }
  .forLabel{
    font-weight: 700 !important;
  }