.btn-position {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.modal-custom {
  width: 100%;
  /* height: auto; */
  padding: 5px 10px;
}
.modal-body-data {
  overflow-y: auto;
  height: 90%;
  top: 0;
}
.cross-icon-position {
  display: flex;
  justify-content: space-between;
  text-align: end;
  /* margin-bottom: 10px; */

  .heading {
    color: #E4151B;
    font-size: 20px;
    font-weight: 600;
  }

  img {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
}

.main_container {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    /* padding: 20px; */
    width: 100%; /* Adjust as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }


  .share-section{
    text-align:right;
    .share-btn{
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      border-radius: 12px;
      background-color: #E4151B;
      padding: 6px 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
    }
  }

  .form-section {
    /* background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    margin-bottom: 20px; */
    padding: 10px;

    .heading {
      color: #E4151B;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .invite-section {
    display: flex;
    flex-direction: column;
    gap: 0.7em;

    .search_input_content {
      position: relative;
      width: 100%;

      .search-icon {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 20px;
        height: 20px;
      
      }

      .search-input {
         /* padding: 6px 10px;
          border: 1px solid #213c92;
          border-radius: 12px;
          width: 250px;
          margin-right: 10px; */
          font-size: 16px;
          font-weight: 600;
          width: 100%;
      }
    }
  }

  .list-content {
    .invite-list,
    .moderator-list {
      height: 196px;
      overflow-y: auto;
      /* border: 2px solid #213c92; */
      border-radius: 12px;
      padding: 10px;
      /* background: #f2f2f2; */
      margin: 0;

      .name-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0px;

        .name-info {
          display: flex;
          align-items: center;
          gap: 0.5em;
          font-size: 20px;
          font-weight: 600;

          .name-text{
            font-size: 16px;
            font-weight: 500;
          }
        }

        .name-image {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          box-shadow: 0px 6.02px 6.02px 0px #00000040;
        }

        .checkbox-container {
          display: flex;
          align-items: center;

          .checkbox-input {
            border-radius: 50% !important;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
