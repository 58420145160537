.cross-icon-position{
    text-align: right;
    cursor: pointer;
    img{
        width: 20px;
        height: 20px;
    }
}

.modal-body-data{
    max-height: 400px;
    overflow-y: auto;

}
.name{
    font-size: 16px;
    font-weight: 700;
    color: #E4151B;
}
.user-section{
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #36363630;
    margin-bottom: 10px;
    align-items: center;
    padding-bottom: 10px;
    .img-size{
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .description{
        font-size: 14px;
        font-weight: 400;
        color: #363636;
        margin-bottom: 0;
    }
    .user-name{
        font-size: 16px;
        font-weight: 600;
        color: #363636;
        margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
    }
}
.count-number{
    font-size: 16px;
    font-weight: 600;
     color: #E4151B;
}
.header-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.share-icon{
    height: 20px;
    width: 30px;
}