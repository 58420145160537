.container {
    width: 100%;
    /* box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    background: #FAF5EFCC;
    border: 1px solid #3636361A;
    border-radius: 15px;*/
    margin-top: 20px; 
  }
  .customTable {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0;
    font-size: 16px;
    text-align: left;
    border-radius: 12px;
    overflow: hidden; 
    border: 1px solid #3636361A;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FAF5EFCC;


}

.customTable thead th {
    background: #F2E7DE;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    border-right: 1px solid #36363633;
    border-left: 1px solid #36363633;

}

.customTable thead th:first-child {
    border-top-left-radius: 12px; 
}

.customTable thead th:last-child {
    border-top-right-radius: 12px;
}

.customTable tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px; 
}

.customTable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px; 
}

.customTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.customTable tbody tr:nth-of-type(even) {
    background: #FAF5EFCC;

}

.customTable tbody tr:last-of-type {
    border-bottom: 2px solid #36363633;
}

.customTable tbody td {
    border-left: 1px solid #36363633;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    background: #FAF5EFCC;
    font-weight: 400;

}

.customTable tbody tr:hover {
    background: #FAF5EFCC;

}

.customTable tbody td a:hover {
    text-decoration: underline;
}


.customTable tbody td:last-of-type {
    border-right: none;
}
.customTable th {
    text-align: center; 
    vertical-align: middle; 
}

.thAndIconContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.columnText {
    margin-right: 8px; 
    white-space: noWrap;
}
  
  .actionBtn {
    color: #E4151B;
    cursor: pointer;
    margin-right: 10px;
  }
  .centerIcon{
    vertical-align: middle;
    text-align: center;
  }
  .uploadBtn{
    background-color:#E4151B;
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px !important;
    color: white;
  }
.positionRelative{
    position: relative;
}
.popContainer{
    position: absolute;
    padding: 10px;
    right: 53px;
    bottom: -116px;
    border: 1px solid #36363620;
    border-radius: 12px;
    background: #FFFFFF;
    display: grid;
    gap: 1em;
}
.customTable tbody tr:last-child td:last-child .popContainer {
    bottom: 15px ;
    border-radius: 12px;
}
.btnText{
    text-wrap: nowrap;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px;
}
.IConAndText{
    display: flex;
    cursor: pointer;
}
.icon{
  cursor: pointer;
}
.noData{
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #363636;
}