.group-btn{
    border-radius: 5px !important;
    border: 1px solid #E4151B !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #E4151B !important;
    padding: 7px 50px !important;
    background-color: #FFFFFF !important;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.group-btn:hover{
    color: #FFFFFF !important;
    background-color: #E4151B !important;
}

.group-btn.active{
    color: #FFFFFF !important;
    background-color: #E4151B !important;
}