.zig-zag-section {
    display: flex;
    justify-content: center;
    align-items: center;
    .zig-zag {
      transform: rotate(45deg);
      .zig-zag-box {
        height: 20px;
        width: 20px;
        background: #D9D9D9;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 5px;
      }
      .selected-zig-zag-box{
          box-shadow: 0px 4px 4px 0px #00000040;
          height: 20px;
          width: 20px;
          background: #E4151B;
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: 5px;
  
      }
    }
  }
  