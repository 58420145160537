.mainContainer {
    .container {
      box-shadow: 0px 4px 4px 0px #00000040;
      padding: 20px;
      background: #FAF5EFCC;
      border: 1px solid #3636361A;
      border-radius: 15px;
      margin-top: 20px;
    }
  }
  .cancel-btn{
    border: none;
    background: transparent;
    color: #000;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;

  }
  .cancel-btn:hover{
    color: #E4151B;
  }
  .required{
    color: #E4151B;
  }

  .inputnumber-filed{
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield; 
    }
  }