.success-modal-block {
    .success-modal-header {
        padding: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 0;

        .cross-icon {
            cursor: pointer;
            padding: 10px;
        }
    }

    .success-modal-body {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .success-modal-body-icon{
            border: 4px solid #202020;
            border-radius: 50%;
            padding: 15px;

            .tick-icon{
                height: 40px;
            }
        }

        .success-modal-hint{
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.23999999463558197px;
            color: #213C92;
            margin-top: 5px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .success-modal-footer{
        border: 0;
        justify-content: center;

        .close-button{
            box-shadow: 0px 4px 4px 0px #00000040;
            font-size: 14px;
            font-weight: 600;
            padding: 7px 40px;
            border-radius: 10px;
        }
    }
}