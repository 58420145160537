.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.progress-container {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
  width: 100%;
  /* max-width: 500px; */
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  background-color: #ffffff;
}
.width {
  width: 100%;
}
.progress-text {
  font-size: 14px;
  color: #E4151B;
  font-weight: 600;
}

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress-text {
  font-size: 14px;
  color: #E4151B;
  font-weight: 600;
}

.file-upload-area {
  border: 2px dashed #c1c1c1;
  padding: 20px;
  text-align: center;
  background: #FAF5EFCC;
  width: 100%;
  height: 100%;
  /* max-width: 500px; */
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.upload-box p {
  margin-top: 10px;
}

.upload-icon {
  /* background: url('/path-to-upload-icon.svg') no-repeat center; */
  width: 50px;
  height: 50px;
}
.browse-btn {
  background-color: #ffffff;
  color: #E4151B;
  font-weight: 700;

  border: #E4151B 2px solid;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.file-info {
  color: #8b8989;
  font-size: 14px;
  text-align: left;
  position: relative;
  font-weight: 600;
  /* left: -220px; */
}

.cross-icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.progress-wrapper {
  width: 100%;
  /* max-width: 500px; */
}
.progress-details {
  margin-top: 5px;
  font-size: 14px;
  color: #E4151B;
}
.progress-bar {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 100%; /* Full width of the container */
  height: 20px;
  border-radius: 20px;
  background-color: #f0f0f0; /* Light grey background for the progress bar */
  margin-top: 10px;
}
.progress-bar-bg {
  display: flex;
  justify-content: space-between;
  right: 0;
}

.progress {
  background-color: #E4151B;
  height: 100%;
  width: 0;
  border-radius: 20px;
  transition: width 0.5s;
}
/* .combine{
    display: flex;
    justify-content: space-between;
  } */
