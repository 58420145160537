.main-container {
    display: flex;
    flex-direction: column;
}

    .compare-program-header {
        padding: 8px 10px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 24px;
            font-weight: 700;
            color: #E4151B;
            letter-spacing: -0.36px;
            padding: 0px 10px;
        }

        .cross-icon-div {
            cursor: pointer;

            /* .cross-icon{
                height: 30px;
            } */
        }
        .cross-icon{
            width: 20px;
        }
    }

    .compare-program-body-section-1 {
        padding: 15px 35px;
        display: flex;
        flex-direction: column;
        gap: 2em;

    }

        .compare-section {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

        }

            .section-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;

            }

                .count-hint {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: -0.36176472902297974px;
                    color: #E4151B;
                }


            .section-2 {
                overflow-y: scroll;
                max-height: 430px;

                .compare-program-block {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;

                    .compare-program-tile {
                        display: flex;
                        align-items: center;
                        gap: 1em;

                        .program-tiles {
                            flex: 1;
                            background: #FBF7F2;
                            border: 1px solid #FBF7F2;
                            border-radius: 12px;
                            padding: 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .unversity-details {
                                display: flex;
                                align-items: center;
                                gap: 0.5em;
                            }
                                

                                .university-icon {
                                    img {
                                        border-radius: 100%;
                                        height: 73px;
                                        width: 73px;
                                        box-shadow: 0px 4px 4px 0px #00000040;
                                    }
                                }

                                .unversity-name {
                                    font-size: 16px;
                                    font-weight: 700;
                                    letter-spacing: -0.36176472902297974px;
                                    color: #363636;
                                }

                                .unversity-address {
                                    font-size: 14px;
                                    font-weight: 400;
                                    letter-spacing: -0.36176472902297974px;
                                    color: #363636;
                                    line-height: 1.1;
                                }
                            

                            .program-name {
                                font-size: 20px;
                                font-weight: 700;
                                letter-spacing: -0.36176472902297974px;
                                color: #363636;
                            }

                            .rank-section {
                                display: flex;
                                align-items: center;
                                gap: 0.5em;

                                .program-rank {
                                    font-size: 20px;
                                    font-weight: 700;
                                    letter-spacing: -0.36176472902297974px;
                                    color: #363636;
                                }

                                .liked-icon {
                                    flex: 1;
                                }
                            }
                        }
                    }
                }
            }






        .buttons-section {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.5em;

        }

            .cancel-btn {
               padding: 8px 20px;
                height: fit-content;
                font-size: 16px;
                font-weight: 500;
                border-radius: 8px;
                background: transparent;
                color: #E4151B;
                border: 1px solid #E4151B;
            }

            .compare-btn {
                font-size: 16px;
                font-weight: 500;
                padding: 8px 20px;
                background-color: #E4151B;
                border: 1px solid #E4151B;
                color: #fff;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }



    .compare-program-body-section-2{
        margin: 5px;
        overflow-y: scroll;
        max-height: 560px;

    }

        .compare-table {
            width: 100%;
            border-collapse: collapse;
            text-align: center;
        }
        
        .compare-table th,
        .compare-table td {
            border: 1px solid #ddd;
            padding: 8px;
        }
        
        /* .compare-table th {
            
        } */

        .compare-table-image{
            border-radius: 100%;
            height: 75px;
            width: 75px;
            box-shadow: 0px 4px 4px 0px #00000040;
        }
        .Instname{
            font-size: 18px;
            margin-top: 15px;
            font-weight: 700;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ProgramName{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .button-section{
            display: flex;
            flex-direction: column;
            gap: 0.5em;

           
        }

        .apply-btn {
            padding: 4px 40px;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0px 4px 4px 0px #00000040;

        }

        .see-more{
            text-decoration: none;
            color: #363636;
        }
    
