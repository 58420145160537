.card {
  position: relative;
  border: 1px solid #FBF7F2;
  background: #FBF7F2;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
}

.coverImage {
  position: relative;
}

.backImage img {
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 60px;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);

  .profile-photo{
    height: 74px;
    width: 74px;
    box-shadow: 0px 6.02px 6.02px 0px #00000040;
    border-radius: 50%;
  }
}

.content {
  margin-top: 25px;
  text-align: center;
}
.no-data{
  text-align: center;
  padding: 40px;
  border-radius: 12px;
  background: #fbf7f2;
  /* border-radius: 12px; */
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  p{
    font-size: 22px;
    font-weight: 500;
    color: #3C3C3C;
  }
}
.content_info {
  margin: 10px 0px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 0px auto;
  margin-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 121px;

  .name-text{
    cursor: pointer;
  }

  .name-text:hover{
    color:  #E4151B;
  }
}

.description {
  color: #363636;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.connectionCount {
  text-decoration: underline;
  margin-bottom: 5px;
  color: #E4151B;
}

.mutual-connections-profiles{
  display: flex;
  justify-content: center;
  align-items: center;

  .connectionImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 -4px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-top: 0;
}

.acceptButton {
  background-color: #E4151B;
  color: #fff;
  border: none;
  border-radius: 4px;
  gap: 0px;
  opacity: 0px;

  cursor: pointer;
}
.dismissButton {
  border: none;
  padding: 8px 16px;
  text-decoration: underline;
  cursor: pointer;
}
.withdrawButton {
  border: none;
  text-decoration: underline;
  padding-top: 10px;
  cursor: pointer;
}


.popupOption{
  cursor: pointer;
}

.view-more{
  border: none;
  background: #E4151B;
  color: #ffffff;
  border-radius: 12px;
  padding: 7px 15px;
  margin-top: 10px;
  font-size: 16px;
}

.containers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.plus{
  cursor: pointer;
}
@media screen and (min-width: 1101px) and (max-width: 1450px) {
  .containers{
    grid-template-columns: repeat(3, 1fr);
  }
  
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  .containers{
    grid-template-columns: repeat(3, 1fr) ;
  }
}
@media screen and (min-width: 501px) and (max-width: 900px)  {
  .containers{
    grid-template-columns: repeat(2, 1fr) ;
  }
}

@media screen and (max-width: 500px){
  .containers{
    grid-template-columns: repeat(1, 1fr);
  }
}

.remove-suggestion{
  position: absolute;
  top: 5px;
  right: 5px;

  .remove-icon{
    height: 25px;
    cursor: pointer;
  }
}


.acceptBtn{
  border-radius: 10px;
    /* background: #213c92; */
    background: #E4151B;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    padding: 7px 15px;
}