.card {
    /* border: 1px solid #ddd;
    border-radius: 8px;
    /* margin: 10px; */
    /* background-color: #fff;  */
    display: flex;
    justify-content: space-between;
    position: relative;
    /* background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040; */
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    align-items: center;
    background: #FAF5EFCC;
    border: 1px solid #36363633;
  }
  
  
  .card_content {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .card_coverImage {
    border: 4px solid #E82529;
    border-radius: 50%;
  }
  
  .card_info {
    .name {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 5px;
      cursor: pointer;
  
      img{
        position: relative;
        left: 10px;
        bottom: 4px;
      }
    }
    .nameLink {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
    .name:hover{
      text-decoration: underline #213c92;
    }
  
    .connectionUser-name{
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
    }
    .connectionUser-name:hover{
     color: #E4151B;
    }

    .imgCrown{
      padding-left: 5px;
    }
  
    /* .description {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
    } */
    .description {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px; /* Adjust the width as needed */
      cursor: pointer;
    }
    
    .description:hover {
      /* white-space: normal;
      overflow: visible;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      padding: 5px;
      border: 1px solid #ddd;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      max-width: none; */
    }
  }
  
  .profileImage {
    height: 70px;
     width: 70px;
     border-radius: 50%; 
     box-shadow: 0px 6.02px 6.02px 0px #00000040;
     /* cursor: pointer; */
    /* position: absolute;
    bottom: 3px;
    left: -4%;
    transform: translateX(-50%);
    border: 4px solid #fff; */ 
  }
  
  .content {
    padding: 18px 0px 10px 0px;
    text-align: center;
  }
  
  .connectionCount {
    text-decoration: underline;
  }
  
  .connectionImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 -4px;
  }
  
  .buttons {
    /* margin-top: 6px; */
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .moreBtn {
    /* padding: 35px 10px 0px 10px; */
    cursor: pointer;
    position: relative;
  }
  
  .messageBtn {
    cursor: pointer;
    /* padding: 25px 10px 0px 10px; */
  }
  
  .popup {
    position: absolute;
    top: 20px;
    right: 10px;
    background-color: white;
    border: 1px solid #ddd;
    background-color: white;
    border: 1.5px solid #36363620;
    border-radius: 10px 0px 10px 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 2px 5px 4px 5px;
    min-width: 180px;
  }
  .popupOption {
    padding: 2px 0px 0px 11px;
    cursor: pointer;
  }
  
  .popupOption img {
    margin-right: 8px;
  }
  
  .popupOption:hover {
    background-color: #f0f0f0;
    border-radius: 10px 0px 10px 10px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8em;
  }
  .groupMembersContainer{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.8em;
  }
  
  .userConnection-profile-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.8em;
  }
  
  .disconnectIsTrue {
    border: 2px solid red;
  }
  
  @media(max-width: 1024px) 
  {
    .container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 0.8em;
    }
  }
  .no-data{
    text-align: center;
    padding: 40px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px #00000040;
    p{
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
    }
  }
  .menu-icon{
    position: relative;
  }
  .view-more{
    border: 1px solid #213c92;
    background: #213c92;
    color: #ffffff;
    border-radius: 12px;
    padding: 7px 15px;
    font-size: 18px;
  }
  .chatContainer {
    position: fixed;
    bottom: 15px;
    width: 100%;
    right: 0;
  }
  .recentlyAdded {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
  }
  .profilePicBottom {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px 0px 0px 5px; 
    box-shadow: 0px 6.02px 6.02px 0px rgba(0, 0, 0, 0.25);
  }
  .forChatScreen{
    display: flex;
      /* justify-content: center; */
      align-items: flex-end;
      justify-content: end;
  
  }
  @media screen and (max-width:1125px) {
  
    .card_info {
    .name {
      font-size: 16px;
    }
  }
  }


  .textBlock {
    background-color: red;
    color: white;
    padding: 3px;
    margin-left: 3px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    /* position: absolute;
    right: 5px; */
    font-size: 12px;
    top: 62px;
  
}




.red-header {
  background: #E4151B;
  border-radius: 12px 12px 0 0;
  padding: 0 10px;
  .text-font {
    font-size: 20px;
    font-weight: 600;
    color: #F2F2F2;
  }
  .ignor-btn{
    margin: 10px;
    border: none;
    background: transparent;
    border-radius: 12px;
    font-size: 16px;
    /* padding: 5px 15px; */
    color: #F2F2F2;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */
  }
  .delete-btn{
    margin: 10px;
    border: none;
    background: transparent;
    border-radius: 12px;
    font-size: 16px;
    /* padding: 5px 15px; */
    color: #ffffff;
    font-weight: 500;
    /* box-shadow: 0px 4px 4px 0px #00000040; */

 }
}
.red-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delete-btn:hover{
  text-decoration: underline;
}
.ignor-btn:hover{
  text-decoration: underline;
}