.instituteDetails_main_container {
  background-color: #fff;
}

/* Left Section */
.instituteDetails_left_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Section 1 */
.instituteDetails_section1 {
  padding: 10px 0;

  .profileCard {
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 12px;
  }

  .card_coverImage {
    /* width: 80px; */
    /* height: 80px; */
    border-radius: 12px;
    img{
      width: 160px;
      height: 80px;
      /* width: 100%; */
    }
  }

  .card_info .name {
    font-size: 22px;
    font-weight: 700;
    text-align: left;
  }

  .card_info .description {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
  }
}

/* Section 3 */
.instituteDetails_section3 {
  .main-row {
    height: 400px;
    background-color: #FBF7F2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customCol8_content {
    background-color: #fff;
    height: 384px;
  }

  .customCol2 {
    padding-left: 0;
  }

  .innerDiv {
    background: #fff;
    height: 120px;
    margin-bottom: 10px;
  }
}

/* Section 4 and Section 5 */
.instituteDetails_section4 {
  .program_description_content {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .program_title {
      font-size: 24px;
      font-weight: 600;
    }

    .program_description {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.instituteDetails_section5 {
  background-color: #fff;
  padding: 10px;
  .program_description_content {
    display: flex;
    gap: 1em;
    
    .search_input_content {
      position: relative;
      width: 100%;

      .search-icon {
        position: absolute;
        top: 16px;
        right: 20px;
        width: 20px;
        height: 20px;
      }
    }

    .search-input {
      padding: 10px;
      border: 1px solid #E4151B;
      border-radius: 12px;
      width: 250px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 600;
      width: 100%;
    }

    .search-button {
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      border-radius: 12px;
      background-color: #E4151B;
      padding: 10px 30px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
    }
  }
}

/* Section 6 */
.instituteDetails_section6 {
  .instituteDetails_Programs_content {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;

    .program_title {
      font-size: 24px;
      font-weight: 600;
    }

    .program-cards {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 2em;
      padding: 20px 0px;
      max-height: 1000px;
            overflow-x: hidden;
            overflow-y: auto;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .instituteDetails_section6 {
    .instituteDetails_Programs_content {
      .program-cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .instituteDetails_section6 {
    .instituteDetails_Programs_content {
      .program-cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
}
