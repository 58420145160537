.mainContainer {
  .headingText {
    span{
      font-size: 18px;
      font-weight: 400;
      margin-top: 10px;
      color: #363636;
    }
    .actionBtn {
      color: #E4151B;
      cursor: pointer;
      margin-right: 10px;
    }
    .centerIcon {
      /* vertical-align: middle;
      text-align: center; */
      box-shadow: 0px 4px 4px 0px #00000040;

    }
    .uploadBtn {
      background-color: #E4151B;
      border-radius: 10px;
      padding: 7px 15px;
      cursor: pointer;
      margin-top: 10px !important;
      color: white;
    }
    .uploadPassport{
        font-weight: 500;
        font-size: 14px;
        vertical-align: middle;
        text-align: center; 
        color: #fff;
    }
  }

  .mandotory{
    color: #E4151B;;
    /* font-size: 12px; */
    vertical-align: top;
    padding-left: 4px;
  }
  .container {
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    background: #FAF5EFCC;
    border: 1px solid #3636361A;
    border-radius: 15px;
    margin-top: 20px;
  }
}

.errorText{
  font-weight: 400;
  font-size: 12px;
  color: #E4151B;
}
.inputnumber-filed {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield; 
  }
}
.hint-msg{
  color: #363636;
  font-size: 12px;
  font-weight: 400;
}
.error-msg{
  color: #E4151B !important;
  font-size: 12px !important;
  font-weight: 400;
}
.address-text{
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
  padding-left: 10px;
  color: #363636;
}

